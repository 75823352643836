import {
  reducer as MainReducer,
  FormState,
} from 'redux-form';
import {
  FORM_CUSTOMER_SEARCH,
  DATA_ORDER_CLEAR,
  FORM_POSITIONS_AND_ADDITIONAL_COSTS,
  FORM_CUSTOMER_SELECT,
  FORM_ORDER_DATA,
  DATA_RESET_DETAILS,
  FORM_MATERIAL
} from '../data/constants';
import { RootAction } from '../store/types';
import { Reducer } from 'redux';
import { SEARCH_IN_ORDER_SUBMIT } from '../data/constants';

const Plugins: { [index: string]: Reducer } = {};

export const positionsFieldNames: { [index: string]: string } = {
  cost_center: 'cost_center',
  division: 'division',
  material_code: 'material',
  weight: 'weight',
  unit_price: 'unit_price',
  position_price: 'position_price',
  notes: 'notes',
  tax_class: 'tax_class',
  storage_location_code: 'storage_location_code',
  weight_unit: 'weight_unit',
}

export const additionalCostsFieldNames: { [index: string]: string } = {
  cost_code: 'cost_code',
  amount: 'amount',
  costs: 'costs',
}

export const orderFieldNames: { [index: string]: string } = {
  customer_id: 'customer_id',
  order_no: 'order_no',
  terms_of_payment: 'terms_of_payment',
  vat_transaction_indicator: 'vat_transaction_indicator',
  address: 'address',
  representative_no: 'representative_no',
  representative_name: 'representative_name',
  value_date: 'value_date',
  notes: 'order_notes',
  internal_notes: 'order_internal_notes',
  order_date: 'order_date',
  billing_attachment_text: 'billing_attachment_text',
  primary_name: 'primary_name',
  secondary_name: 'secondary_name',
  order_type: 'order_type',
  delivery_note: 'delivery_note',
  incoterm: 'incoterm',
  incoterm2: 'incoterm2',
  shipping_terms: 'shipping_terms',
  street: 'street',
  house_number: 'house_number',
  post_code: 'post_code',
  country: 'country',
  city: 'city',
  weight_account_partner_sap_id: 'weight_account_partner_sap_id',
  delivery_partner_sap_id: 'delivery_partner_sap_id',
  relevant_for_sap: 'relevant_for_sap',
  invoice_id: 'invoice_id',
  delivery_note_id: 'delivery_note_id',
  currency_code: 'currency_code',
}

export const billingFieldNames: { [index: string]: string } = {
  invoice_date: 'invoice_date',
}

export const shippingAddressFieldNames: { [index: string]: string } = {
  shipping_address_partner: 'shipping_address_partner',
  shipping_address_primary_name: 'shipping_address_primary_name',
  shipping_address_secondary_name: 'shipping_address_secondary_name',
  shipping_address_street: 'shipping_address_street',
  shipping_address_house_number: 'shipping_address_house_number',
  shipping_address_post_code: 'shipping_address_post_code',
  shipping_address_city: 'shipping_address_city',
  shipping_address_country: 'shipping_address_country',
}

export const materialFieldNames: { [index: string]: string } = {
  material_code: 'material_code',
  name: 'name',
  multiplier: 'multiplier',
  price_unit: 'price_unit',
  weight_unit: 'weight_unit',
  material_number: 'material_number',
  color: 'color',
}

const ClearPlugin = (state: FormState, action: RootAction) => {
  switch (action.type) {
    case DATA_ORDER_CLEAR:
    case DATA_RESET_DETAILS:
      return undefined;
    default:
      return state;
  }
};

const ClearSelectPligin = (state: FormState, action: RootAction) => {
  switch (action.type) {
    case SEARCH_IN_ORDER_SUBMIT:
      return undefined;
    default:
      return ClearPlugin(state, action);
  }
}

Plugins[FORM_ORDER_DATA] = ClearPlugin;
Plugins[FORM_CUSTOMER_SEARCH] = ClearPlugin;
Plugins[FORM_CUSTOMER_SELECT] = ClearSelectPligin;
Plugins[FORM_POSITIONS_AND_ADDITIONAL_COSTS] = ClearPlugin;
Plugins[FORM_MATERIAL] = ClearPlugin;

export const FormReducer = MainReducer.plugin(Plugins);

export default FormReducer;
