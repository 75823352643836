import * as trading from '../actions/trading';
import { ActionType } from 'typesafe-actions';
import {
    TRADING_PURCHASE_UNLOCK,
    TRADING_PURCHASE_LOCK,
    TRADING_SALE_UNLOCK,
    TRADING_SALE_LOCK,
    TRADING_RESET_ALL,
    TRADING_SALE_CANCEL_LOCK,
    TRADING_PURCHASE_CANCEL_LOCK,
    SAP_RESET_ITEMS,
    SAP_SET_ITEMS,
    SAP_SELECT_ITEM,
    SAP_SET_ACTIVE_TABLE,
} from '../data/constants';

export type TradingAction = ActionType<typeof trading>;

export type TradingState = {
    selectedPurchase: string[],
    selectedSale: string[],
    selectedForSAP: string[],
    activeSapTable: string,
};

export const SapTables = {
    purchase: "purchase_orders",
    sale: "sale_orders"
}

export const defaultTradingState: TradingState = {
    selectedPurchase: [],
    selectedSale: [],
    selectedForSAP: [],
    activeSapTable: SapTables.purchase,
};

export function buildDefaultState(data: {
}): TradingState {
    return {
        ...defaultTradingState,
        ...data,
    };
}

export const TradingMainReducer = (
    state: TradingState = defaultTradingState,
    action: TradingAction
) => {
    switch (action.type) {
        case TRADING_PURCHASE_LOCK:
            const selectedPurchase = [...state.selectedPurchase];
            selectedPurchase.push(action.payload.id);
            return {
                ...state,
                selectedPurchase
            };
        case TRADING_PURCHASE_CANCEL_LOCK:
        case TRADING_PURCHASE_UNLOCK:
            const selectedPurchaseOld = state.selectedPurchase;
            return { ...state, selectedPurchase: selectedPurchaseOld.filter(el => el != action.payload.id) };

        case TRADING_SALE_LOCK:
            const selectedSale = [...state.selectedSale];
            selectedSale.push(action.payload.id);
            return {
                ...state,
                selectedSale
            };
        case TRADING_SALE_CANCEL_LOCK:
        case TRADING_SALE_UNLOCK:
            const selectedSaleOld = state.selectedSale;
            return { ...state, selectedSale: selectedSaleOld.filter(el => el != action.payload.id) };

        // SELECTING WITHOUT LOCK
        // case TRADING_PURCHASE_CANCEL_LOCK:
        // case TRADING_PURCHASE_SELECT:
        //     const selectedPurchase = [...state.selectedPurchase];
        //     if (selectedPurchase && selectedPurchase.includes(action.payload.id)) {
        //         return {
        //             ...state,
        //             selectedPurchase: selectedPurchase.filter(el => el != action.payload.id)
        //         };
        //     }
        //     selectedPurchase.push(action.payload.id);
        //     return {
        //         ...state,
        //         selectedPurchase
        //     };

        // case TRADING_SALE_CANCEL_LOCK:
        // case TRADING_SALE_SELECT:
        //     const selectedSale = [...state.selectedSale];
        //     if (selectedSale && selectedSale.includes(action.payload.id)) {
        //         return {
        //             ...state,
        //             selectedSale: selectedSale.filter(el => el != action.payload.id)
        //         };
        //     }
        //     selectedSale.push(action.payload.id);
        //     return {
        //         ...state,
        //         selectedSale
        //     };


        case TRADING_RESET_ALL:
            return { ...state, selectedSale: [], selectedPurchase: [] };

        case SAP_SELECT_ITEM:
            const item = action.payload.item;
            if (state.selectedForSAP && state.selectedForSAP.includes(item)) {
                return { ...state, selectedForSAP: state.selectedForSAP.filter(el => el != item) };
            }
            else {
                const selectedForSAP = [...state.selectedForSAP];
                selectedForSAP.push(item);
                return { ...state, selectedForSAP };
            }
        case SAP_SET_ITEMS:
            return { ...state, selectedForSAP: action.payload.items };
        case SAP_RESET_ITEMS:
            return { ...state, selectedForSAP: [] };
        case SAP_SET_ACTIVE_TABLE:
            return { ...state, activeSapTable: action.payload.name }
        default:
            return state;
    }
};

export const TradingReducer = {
    reducer: TradingMainReducer,
    defaultSate: defaultTradingState,
    stateBuilder: buildDefaultState,
};

export default TradingReducer;