import * as React from 'react';
import StorageLocal from "./services/storage";
import { LanguageService } from './services/language';

import DE from './assets/locale/de'
import EN from './assets/locale/en'

const languages = { en: { translations: EN }, de: { translations: DE } };

export const i18n = LanguageService.init(languages, StorageLocal.get('Language', 'HuM_') || 'en');

export type I18n = typeof i18n;

export const i18nContext = React.createContext(i18n);