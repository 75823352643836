import * as React from 'react';
import { Navs } from '../../utils/navs';
import { i18nContext } from '../../../language';
import { default as PurchaseBar } from './billing/purchase';
import { default as SalesBar } from './billing/sales';
import { getActiceSapTable } from '../../../selectors';
import { RootState, RootAction } from '../../../store/types';
import { setActiveSapTable, resetSapItems } from '../../../actions/trading';
import { connect } from 'react-redux';
import { SapTables } from '../../../reducers/trading';
import { getBillingTableHeaders } from '../tables/tabtable-components';
import BillingDataForm from './billing/billing-data';

const Textes = {
    purchase: "Purchase",
    sale: "Sale",
};

interface BillingBarProps {
    activeTable?: string;
    onTabSelect?: (eventKey: string, e: React.SyntheticEvent<{}>) => void;
}

class BillingBar extends React.PureComponent<BillingBarProps, {}> {
    render() {
        return (
            <i18nContext.Consumer>
                {i18n =>
                    <div className="mt-2 billing-table">
                        <BillingDataForm
                            className="col-4 p-0 tab-form"
                            readonly={false}
                        />
                        <Navs
                            contents={[{
                                key: SapTables.purchase,
                                name: i18n.t(Textes.purchase),
                                content: (<PurchaseBar />)
                            }, {
                                key: SapTables.sale,
                                name: i18n.t(Textes.sale),
                                content: (<SalesBar />)
                            }]}
                            activeKey={this.props.activeTable}
                            onSelect={this.props.onTabSelect}
                        />
                    </div>
                }
            </i18nContext.Consumer>
        )
    }
}

const mapStateToProps = (
    state: RootState,
    ownProps: BillingBarProps
) => {
    return {
        activeTable: getActiceSapTable(state),
    }
};
const mapDispatchToProps = (
    dispatch: (action: RootAction) => void,
    ownProps: BillingBarProps
) => {
    return {
        onTabSelect(eventKey: string, e: React.SyntheticEvent<{}>) {
            dispatch(setActiveSapTable(eventKey));
            dispatch(resetSapItems());
        },
    };
};
export const BillingBarContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(BillingBar);
export default BillingBarContainer;