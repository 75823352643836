import { Epic, combineEpics } from 'redux-observable';
import {
  filter,
  map,
  mergeMap
} from 'rxjs/operators';
import { isOfType } from 'typesafe-actions';
import * as DataAction from '../actions/data';
import { RootAction } from '../store/types';
import {
  DATA_CREATE_PURCHASE,
  DATA_CREATE_SALES,
  SEARCH_IN_ORDER_SUBMIT,
  DATA_ORDER_CREATE_POSITION_ITEM,
  DATA_ORDER_CREATE_ADDITIONAL_COST_ITEM,
  FORM_POSITIONS_AND_ADDITIONAL_COSTS,
  DATA_GET_PURCHASE_DETAILS,
  DATA_GET_SALES_DETAILS,
  DATA_DELETE_PURCHASE_ORDER,
  DATA_DELETE_SALES_ORDER,
  DATA_SET_DETAILS,
  DATA_CHANGE_SALES_ORDER,
  DATA_CHANGE_PURCHASE_ORDER,
  SAP_SENT_TO_SAP,
  FORM_ORDER_DATA,
  FORM_SHIPPING_ADDRESS,
  FORM_CUSTOMER_SELECT,
  DATA_ORDER_DELETE_POSITION_ITEM,
  DATA_ORDER_DELETE_ADDITIONAL_COST_ITEM,
  SAP_BILLING_TO_SAP,
  SAP_DELIVERING_TO_SAP,
  DATA_ORDER_EDIT_LOCK,
  FORM_HISTORY_USER,
  DATA_LOAD_USER_HISTORY,
  DATA_LOAD_HISTORY,
  DATA_ADD_MATERIAL,
  DATA_EDIT_MATERIAL,
  FORM_CUSTOMER_SEARCH,
  DATA_DELETE_PURCHASE_DELIVERY,
  DATA_DELETE_SALES_DELIVERY,
  DATA_DELETE_PURCHASE_INVOICE,
  DATA_DELETE_SALES_INVOICE,
} from '../data/constants';
import { forkJoin } from 'rxjs';
import {
  prepareData,
  getCustomerSearchFormValues,
  getCustomerSelectFormValues,
  getCreateOrderData,
  getOrderPositionData,
  getOrderAdditionalCostData,
  getShippingAddressData,
  getPriceMultiplier,
  getOptions,
  getPositionsAndAdditionalCostsFormValues,
  getSelectedSapItems,
  getActiceSapTable,
  getOrderDetailsData,
  getOrderPosiotionItems,
  getOrderCustomerShippingPartners,
  getOrderShippingAddressPartner,
  getRepresentatives,
  getOrderRepresentativeNumber,
  getSelectedHistoryUser,
  getMaterialFormData,
  getSessionUser,
  getInvoiceDate,
} from '../selectors';
import { DataServiceClass, DataResponseParams } from '../services/data';
import * as SearchAction from '../actions/search';
import { setErrorMessage, } from '../actions/app';
import { FormAction, actionTypes as FormActions } from 'redux-form';
import { FormBuilder } from '../components/utils/form/form';
import { positionsFieldNames, additionalCostsFieldNames, orderFieldNames, shippingAddressFieldNames } from '../reducers/form';
import { TradingServiceClass, TradingResponseParams } from '../services/trading';
import { generateId } from '../helpers/helper';
import { getReports, getPurchaseRawData, getSalesRawData, getSearchedCustomers } from '../selectors/data';
import { DATA_DOWNLOAD_REPORT, DATA_ORDER_SET_CUSTOMER_PRESELECT, defaultCustomerSearch } from '../data/constants';
import { defaultCurrency, defaultMaterialCode, defaultWeightUnit } from '../components/homepage/popup/order/data';
import { getActiveSearchType, getSelectedCustomerSelectFormValues, getOrderDate, getOrderAdditionalCostItems, getShowAdditionalSalesFields } from '../selectors/order';
import { format } from 'date-fns'
import { i18n } from '../language';
import { Data, CustomerPartner } from '../reducers/data';

const positionAndAdditionalCostFormControlls = FormBuilder.formActions(FORM_POSITIONS_AND_ADDITIONAL_COSTS);
const orderFormCotnrolls = FormBuilder.formActions(FORM_ORDER_DATA);
const shippingAddressFormControlls = FormBuilder.formActions(FORM_SHIPPING_ADDRESS);
const customerSearchFormControlls = FormBuilder.formActions(FORM_CUSTOMER_SEARCH);

const DataService = new DataServiceClass();
const TradingService = new TradingServiceClass();

type Responce = Array<DataResponseParams>;
type TradingResponce = Array<TradingResponseParams>;

const setOrderDetails = (response: Responce, type: string): RootAction => {
  const res = response.pop();
  if (res.status !== "OK") {
    return setErrorMessage(res.message);
  }
  return DataAction.setOrderDetails(res.data as any, type);
}

const checkOrderResponce = (
  response: Responce | TradingResponce
): RootAction[] => {
  const res = response.pop();
  if (res.status !== "OK") {
    return [
      setErrorMessage(res.message),
      DataAction.createRequestStatusUpdate("ERROR")
    ];
  }
  return [DataAction.createRequestStatusUpdate("OK")];
}

const checkOrderResponceAndDownloadFile = (
  response: Responce | TradingResponce
): RootAction[] => {
  const res = response.pop();
  if (res.status !== "OK") {
    return (res.data && res.data.filename) ? [
      setErrorMessage(res.message),
      DataAction.createRequestStatusUpdate("OK"),
      DataAction.downloadReport(String(res.data.filename))
    ] : [
      setErrorMessage(res.message),
      DataAction.createRequestStatusUpdate("ERROR")
    ];
  }
  return [
    DataAction.createRequestStatusUpdate("OK"),
    DataAction.downloadReport(String(res.data.filename))
  ];
}

const handleCreateOrderOperations: Epic<RootAction> = (
  action$,
  store
) =>
  action$.pipe(
    filter(action =>
      (isOfType(DATA_CREATE_PURCHASE, action)) ||
      (isOfType(DATA_CREATE_SALES, action)) ||
      (isOfType(DATA_CHANGE_SALES_ORDER, action)) ||
      (isOfType(DATA_CHANGE_PURCHASE_ORDER, action))
    ),
    mergeMap(action => {
      const order_data = getCreateOrderData(store.value);
      const positions = getOrderPositionData(store.value);
      const additional_costs = getOrderAdditionalCostData(store.value);
      const shippingAddress = getShippingAddressData(store.value);

      if (!order_data[orderFieldNames.order_date]) {
        order_data[orderFieldNames.order_date] = format(new Date(), "YYYY-MM-DD");
      }

      const positionNumberFields = ['weight', 'price', 'unit_price', 'position_price'];

      positions && positions.map(position => {
        Object.keys(positionsFieldNames).map(field => {
          if (positionNumberFields.includes(field)) {
            position[field] = i18n.clearFormating(position[field]);
          }
        })
      });

      const additionalCostNumberFields = ['amount', 'costs'];

      additional_costs && additional_costs.map(additionalCost => {
        Object.keys(additionalCostsFieldNames).map(field => {
          if (additionalCostNumberFields.includes(field)) {
            additionalCost[field] = i18n.clearFormating(additionalCost[field]);
          }
        })
      });

      let queue = null;

      switch (action.type) {
        case DATA_CREATE_PURCHASE:
          queue = DataService.createPurchase({
            ...order_data,
            positions,
            additional_costs,
            shipping_address: shippingAddress,
          });
          break;
        case DATA_CREATE_SALES:
          queue = DataService.creatSales({
            ...order_data,
            positions,
            additional_costs,
            shipping_address: shippingAddress,
          });
          break;
        case DATA_CHANGE_PURCHASE_ORDER:
          queue = DataService.changePurchaseOrder({
            ...order_data,
            positions,
            additional_costs,
            shipping_address: shippingAddress,
            order_id: action.payload.id
          });
          break;
        case DATA_CHANGE_SALES_ORDER:
          queue = DataService.changeSalesOrder({
            ...order_data,
            positions,
            additional_costs,
            shipping_address: shippingAddress,
            order_id: action.payload.id
          });
          break;
      }

      return queue && forkJoin(queue).pipe(
        mergeMap((response: Responce) => checkOrderResponce(response))
      ) || [];
    })
  );

const handleCustomerSearchSubmit: Epic<RootAction> = (
  action$,
  store
) =>
  action$.pipe(
    filter(action =>
      (isOfType(SEARCH_IN_ORDER_SUBMIT, action) && getCustomerSearchFormValues(store.value))
    ),
    mergeMap(action => {
      const queue = DataService.getCustomers({ needle: getCustomerSearchFormValues(store.value) });
      return forkJoin(queue).pipe(
        mergeMap((response: Responce) => {
          const res = response.pop();
          if (res.status !== "OK") {
            return [
              setErrorMessage(res.message),
              DataAction.createRequestStatusUpdate("ERROR")
            ];
          }
          return [
            DataAction.createRequestStatusUpdate("SEARCHED"),
            SearchAction.setSerachedInOrder(prepareData(res.data))
          ];
        })
      );
    })
  );

const handleCreatePositionItem: Epic<FormAction> = (
  action$,
  store
) =>
  action$.pipe(
    filter(action =>
      (isOfType(DATA_ORDER_CREATE_POSITION_ITEM, action))
    ),
    mergeMap(action => {
      const id = generateId();
      const options = getOptions(store.value);

      const customers = getSearchedCustomers(store.value);
      const customer_id = getSelectedCustomerSelectFormValues(store.value);
      const customer = customers[customer_id];

      const default_cost_center = customer.default_cost_center &&
        options.cost_centers.map(cost_center => cost_center.value).includes(String(customer.default_cost_center)) &&
        String(customer.default_cost_center);

      const default_division = customer.default_division &&
        options.divisions.map(division => division.value).includes(String(customer.default_division)) &&
        String(customer.default_division);

      return [
        DataAction.addPositionItem(id),
        positionAndAdditionalCostFormControlls.setValue(`${positionsFieldNames.cost_center}-${id}`, default_cost_center || options.cost_centers[0].value),
        positionAndAdditionalCostFormControlls.setValue(`${positionsFieldNames.division}-${id}`, default_division || options.divisions[0].value),
        positionAndAdditionalCostFormControlls.setValue(`${positionsFieldNames.material_code}-${id}`, defaultMaterialCode),
        positionAndAdditionalCostFormControlls.setValue(`${positionsFieldNames.weight_unit}-${id}`, defaultWeightUnit),
        // positionFormCotnrolls.setValue(`${positionsFieldNames.storage_location_code}-${id}`, options.warehouses[0].value),
      ];
    })
  );

const handleCreateAdditionalCostItem: Epic<FormAction> = (
  action$,
  store
) =>
  action$.pipe(
    filter(action =>
      (isOfType(DATA_ORDER_CREATE_ADDITIONAL_COST_ITEM, action))
    ),
    mergeMap(action => {
      const id = generateId();
      const options = getOptions(store.value);
      return [
        DataAction.addAdditionalCostItem(id),
        positionAndAdditionalCostFormControlls.setValue(`${additionalCostsFieldNames.cost_code}-${id}`, options.additional_costs[0].value),
      ];
    })
  );

const handlePositionItemPriceChange: Epic<FormAction> = (
  action$,
  store
) =>
  action$.pipe(
    filter(action =>
    ((action.type === FormActions.CHANGE &&
      action.meta &&
      action.meta.form === FORM_POSITIONS_AND_ADDITIONAL_COSTS &&
      action.meta.field &&
      action.meta.touch !== undefined))
    ),
    mergeMap(action => {
      const matchPrice = (new RegExp(`^${positionsFieldNames.unit_price}-(.*)`)).exec(action.meta.field);
      const matchPositionPrice = (new RegExp(`^${positionsFieldNames.position_price}-(.*)`)).exec(action.meta.field);
      const matchPositionMaterial = (new RegExp(`^${positionsFieldNames.material_code}-(.*)`)).exec(action.meta.field);
      const matchPositionWeight = (new RegExp(`^${positionsFieldNames.weight}-(.*)`)).exec(action.meta.field);
      const matchPositionWeightUnit = (new RegExp(`^${positionsFieldNames.weight_unit}-(.*)`)).exec(action.meta.field);
      if (matchPrice || matchPositionPrice || matchPositionMaterial || matchPositionWeight || matchPositionWeightUnit) {
        const id = matchPrice && matchPrice[1] ||
          matchPositionPrice && matchPositionPrice[1] ||
          matchPositionMaterial && matchPositionMaterial[1] ||
          matchPositionWeight && matchPositionWeight[1] ||
          matchPositionWeightUnit && matchPositionWeightUnit[1];

        const values = getPositionsAndAdditionalCostsFormValues(store.value);
        const multiplier = getPriceMultiplier(store.value);

        const weight = i18n.clearFormating(matchPositionWeight ? action.payload : values[`${positionsFieldNames.weight}-${id}`]);
        if (!id || !weight || !multiplier || (multiplier && !multiplier[id])) {
          if (matchPrice) {
            return [
              positionAndAdditionalCostFormControlls.setValue(`main_price-${id}`, positionsFieldNames.unit_price),
            ]
          }
          if (matchPositionPrice) {
            return [
              positionAndAdditionalCostFormControlls.setValue(`main_price-${id}`, positionsFieldNames.position_price),
            ]
          }
          return [];
        }

        const mainPrice = values[`main_price-${id}`] || positionsFieldNames.unit_price;
        const price = i18n.clearFormating((matchPositionMaterial || matchPositionWeight || matchPositionWeightUnit) && mainPrice ? values[`${mainPrice}-${id}`] : action.payload);

        if (matchPrice || ((matchPositionMaterial || matchPositionWeight || matchPositionWeightUnit) && mainPrice === positionsFieldNames.unit_price)) {
          const value = Math.round(weight * price / Number(multiplier[id]) * 100) / 100 || 0;
          if (values[`${positionsFieldNames.position_price}-${id}`] !== value) {
            return [
              positionAndAdditionalCostFormControlls.setValue(`${positionsFieldNames.position_price}-${id}`, i18n.formatPrice(Number(value))),
              positionAndAdditionalCostFormControlls.setValue(`main_price-${id}`, positionsFieldNames.unit_price),
            ];
          }
        }

        if (matchPositionPrice || ((matchPositionMaterial || matchPositionWeight || matchPositionWeightUnit) && mainPrice === positionsFieldNames.position_price)) {
          const value = Math.round(Number(multiplier[id]) * price / weight * 100) / 100 || 0;
          if (values[`${positionsFieldNames.unit_price}-${id}`] !== value) {
            return [
              positionAndAdditionalCostFormControlls.setValue(`${positionsFieldNames.unit_price}-${id}`, i18n.formatPrice(Number(value))),
              positionAndAdditionalCostFormControlls.setValue(`main_price-${id}`, positionsFieldNames.position_price),
            ];
          }
        }
      }
      return [];
    })
  );

const handleDataFieldsChange: Epic<FormAction> = (
  action$,
  store
) =>
  action$.pipe(
    filter(action =>
    ((action.type === FormActions.CHANGE &&
      action.meta &&
      action.meta.form === FORM_ORDER_DATA &&
      action.meta.field === orderFieldNames.order_date &&
      (!action.payload || !getOrderDate(store.value)))
    )
    ),
    map(action => {
      return orderFormCotnrolls.setValue(orderFieldNames.order_date, format(new Date(), "YYYY-MM-DD"));
    })
  );

const handlePurchaseOrderDetails: Epic<RootAction> = (
  action$,
  store
) =>
  action$.pipe(
    filter(action =>
      (isOfType(DATA_GET_PURCHASE_DETAILS, action))
    ),
    mergeMap(action => {
      const queue = DataService.getPurchaseOrderDetails({ position_id: action.payload.id });
      return forkJoin(queue).pipe(
        map((response: Responce) => setOrderDetails(response, 'purchase'))
      );
    })
  );

const handleSalesOrderDetails: Epic<RootAction> = (
  action$,
  store
) =>
  action$.pipe(
    filter(action =>
      (isOfType(DATA_GET_SALES_DETAILS, action))
    ),
    mergeMap(action => {
      const queue = DataService.getSalesOrderDetails({ position_id: action.payload.id });
      return forkJoin(queue).pipe(
        map((response: Responce) => setOrderDetails(response, 'sale'))
      );
    })
  );

const handleOrderDetailsSet: Epic<RootAction> = (
  action$,
  store
) =>
  action$.pipe(
    filter(action =>
      (isOfType(DATA_SET_DETAILS, action))
    ),
    mergeMap(action => {
      const position_ids = getOrderPosiotionItems(store.value);
      const additional_cost_ids = getOrderAdditionalCostItems(store.value);

      const positionPriceFields = ['price', 'unit_price', 'position_price'];
      const positionWeightFields = ['weight'];
      const additionalCostPriceFields = ['costs'];

      const order_details = action.payload.data && action.payload.data.order_details;
      const delivery_partner = action.payload.data && action.payload.data.delivery_partner;
      const weight_partner = action.payload.data && action.payload.data.weight_partner;
      const positions = action.payload.data && action.payload.data.positions;
      const additional_costs = action.payload.data && action.payload.data.additional_costs;
      const shipping_partners = action.payload.data && action.payload.data.shipping_partners;
      const metal_account_partners = action.payload.data && action.payload.data.metal_account_partners;
      const id = order_details.id;
      const posActions: RootAction[] = [];
      const additionalCostActions: RootAction[] = [];

      Object.keys(positions).map(pos => {
        const position_id = String(positions[pos].id);
        !position_ids.includes(String(position_id)) && posActions.push(DataAction.addPositionItem(position_id));
        Object.keys(positionsFieldNames).map(field => {
          if (positionPriceFields.includes(field)) {
            const value = i18n.formatPrice(Number(positions[pos][field]));
            posActions.push(positionAndAdditionalCostFormControlls.setValue(`${positionsFieldNames[field]}-${position_id}`, value))
          } else if (positionWeightFields.includes(field)) {
            const value = i18n.formatNumber(Number(positions[pos][field]));
            posActions.push(positionAndAdditionalCostFormControlls.setValue(`${positionsFieldNames[field]}-${position_id}`, value))
          } else {
            posActions.push(positionAndAdditionalCostFormControlls.setValue(`${positionsFieldNames[field]}-${position_id}`, positions[pos][field]))
          }
        })
      });

      Object.keys(additional_costs).map(additional_cost => {
        const additional_cost_id = String(additional_costs[additional_cost].id);
        !additional_cost_ids.includes(String(additional_cost_id)) && additionalCostActions.push(DataAction.addAdditionalCostItem(additional_cost_id));
        Object.keys(additionalCostsFieldNames).map(field => {
          if (additionalCostPriceFields.includes(field)) {
            const value = i18n.formatPrice(Number(additional_costs[additional_cost][field]));
            additionalCostActions.push(positionAndAdditionalCostFormControlls.setValue(
              `${additionalCostsFieldNames[field]}-${additional_cost_id}`,
              value));
          } else {
            additionalCostActions.push(positionAndAdditionalCostFormControlls.setValue(
              `${additionalCostsFieldNames[field]}-${additional_cost_id}`,
              additional_costs[additional_cost][field]
            ));
          }
        })
      });

      const partners = {
        shippingPartners: shipping_partners as CustomerPartner[],
        metalAccountPartners: metal_account_partners as CustomerPartner[],
      };

      return [
        ...Object.keys(orderFieldNames).map(field => orderFormCotnrolls.setValue(orderFieldNames[field], order_details[field])),
        ...Object.keys(shippingAddressFieldNames).map(field => shippingAddressFormControlls.setValue(shippingAddressFieldNames[field], order_details[field])),
        DataAction.setCustomerPartners(partners),
        orderFormCotnrolls.setValue(`customer_preview-delivery_partner_sap_id`, delivery_partner && String(delivery_partner.name) || ''),
        orderFormCotnrolls.setValue(`customer_preview-weight_account_partner_sap_id`, weight_partner && String(weight_partner.name) || ''),
        ...posActions,
        ...additionalCostActions,
      ];
    })
  );

const handlePurchaseOrderDelete: Epic<RootAction> = (
  action$,
  store
) =>
  action$.pipe(
    filter(action =>
      (isOfType(DATA_DELETE_PURCHASE_ORDER, action))
    ),
    mergeMap(action => {
      const queue = DataService.deleteOrderPurchase({ order_id: action.payload.id });
      return forkJoin(queue).pipe(
        mergeMap((response: Responce) => {
          const res = response.pop();
          if (res.status !== "OK") {
            return [setErrorMessage(res.message)];
          }
          return [];
        })
      );
    })
  );

const handleSalesOrderDelete: Epic<RootAction> = (
  action$,
  store
) =>
  action$.pipe(
    filter(action =>
      (isOfType(DATA_DELETE_SALES_ORDER, action))
    ),
    mergeMap(action => {
      const queue = DataService.deleteOrderSales({ order_id: action.payload.id });
      return forkJoin(queue).pipe(
        mergeMap((response: Responce) => {
          const res = response.pop();
          if (res.status !== "OK") {
            return [setErrorMessage(res.message)];
          }
          return [];
        })
      );
    })
  );

const handlePurchaseDeliveryDelete: Epic<RootAction> = (
  action$,
  store
) =>
  action$.pipe(
    filter(action =>
      (isOfType(DATA_DELETE_PURCHASE_DELIVERY, action))
    ),
    mergeMap(action => {
      const queue = DataService.deleteDeliveryPurchase({ order_id: action.payload.id });
      return forkJoin(queue).pipe(
        mergeMap((response: Responce) => {
          const res = response.pop();
          if (res.status !== "OK") {
            return [setErrorMessage(res.message)];
          }
          return [];
        })
      );
    })
  );

const handleSalesDeliveryDelete: Epic<RootAction> = (
  action$,
  store
) =>
  action$.pipe(
    filter(action =>
      (isOfType(DATA_DELETE_SALES_DELIVERY, action))
    ),
    mergeMap(action => {
      const queue = DataService.deleteDeliverySales({ order_id: action.payload.id });
      return forkJoin(queue).pipe(
        mergeMap((response: Responce) => {
          const res = response.pop();
          if (res.status !== "OK") {
            return [setErrorMessage(res.message)];
          }
          return [];
        })
      );
    })
  );

const handlePurchaseInvoiceDelete: Epic<RootAction> = (
  action$,
  store
) =>
  action$.pipe(
    filter(action =>
      (isOfType(DATA_DELETE_PURCHASE_INVOICE, action))
    ),
    mergeMap(action => {
      const queue = DataService.deleteInvoicePurchase({ order_id: action.payload.id });
      return forkJoin(queue).pipe(
        mergeMap((response: Responce) => {
          const res = response.pop();
          if (res.status !== "OK") {
            return [setErrorMessage(res.message)];
          }
          return [];
        })
      );
    })
  );

const handleSalesInvoiceDelete: Epic<RootAction> = (
  action$,
  store
) =>
  action$.pipe(
    filter(action =>
      (isOfType(DATA_DELETE_SALES_INVOICE, action))
    ),
    mergeMap(action => {
      const queue = DataService.deleteInvoiceSales({ order_id: action.payload.id });
      return forkJoin(queue).pipe(
        mergeMap((response: Responce) => {
          const res = response.pop();
          if (res.status !== "OK") {
            return [setErrorMessage(res.message)];
          }
          return [];
        })
      );
    })
  );

const handleCustomerSelected: Epic<RootAction> = (
  action$,
  store
) =>
  action$.pipe(
    filter(action => (
      action.meta &&
      action.meta.form === FORM_CUSTOMER_SELECT &&
      action.type === FormActions.CHANGE
    )),
    mergeMap(action => {
      const activeSearch = getActiveSearchType(store.value);
      const customer_id = getCustomerSelectFormValues(store.value);
      const customers = getSearchedCustomers(store.value);
      const customer = customers[customer_id];

      if (activeSearch === defaultCustomerSearch) {
        return [
          orderFormCotnrolls.setValue(orderFieldNames.customer_id, customer_id),
          DataAction.setCustomerPreselect(),
          orderFormCotnrolls.setValue(`customer_preview-${activeSearch}`, customer && String(customer.name))
        ];
      }
      return [
        customerSearchFormControlls.setValue('additional_sale_customer_dropdown_select', customer_id),
        orderFormCotnrolls.setValue(orderFieldNames[activeSearch], customer_id),
        orderFormCotnrolls.setValue(`customer_preview-${activeSearch}`, customer && String(customer.name)),
      ];
    })
  );

const handleAdditionalSaleCustomerDropdownSelected: Epic<RootAction> = (
  action$,
  store
) =>
  action$.pipe(
    filter(action => (
      action.meta &&
      action.meta.form === FORM_CUSTOMER_SEARCH &&
      action.meta.field === 'additional_sale_customer_dropdown_select' &&
      action.type === FormActions.CHANGE
    )),
    mergeMap(action => {
      const showAdditionalField = getShowAdditionalSalesFields(store.value);
      const field = showAdditionalField ? orderFieldNames.delivery_partner_sap_id : orderFieldNames.weight_account_partner_sap_id;

      return [
        orderFormCotnrolls.setValue(orderFieldNames[field], action.payload),
      ];
    })
  );

const handlePrefillingCustomerData: Epic<RootAction> = (
  action$,
  store
) => action$.pipe(
  filter(action => (
    isOfType(DATA_ORDER_SET_CUSTOMER_PRESELECT, action)
  )),
  mergeMap(action => {
    const customers = getSearchedCustomers(store.value);
    const customer_id = getSelectedCustomerSelectFormValues(store.value);
    const customer = customers[customer_id];

    const partners = {
      shippingPartners: customer && customer.shipping_partners as CustomerPartner[],
      metalAccountPartners: customer && customer.metal_account_partners as CustomerPartner[],
    };

    customer.weight_account_partner_sap_id = '';
    customer.delivery_partner_sap_id = '';

    if (Array.isArray(customer.metal_account_partners) && customer.metal_account_partners.length === 1) {
      customer.weight_account_partner_sap_id = (customer.metal_account_partners[0] as CustomerPartner).sap_id;
    }

    if (Array.isArray(customer.shipping_partners) && customer.shipping_partners.length === 1) {
      customer.delivery_partner_sap_id = (customer.shipping_partners[0] as CustomerPartner).sap_id;
    }

    const fieldsToFill = [
      'terms_of_payment',
      'primary_name',
      'secondary_name',
      'city',
      'street',
      'post_code',
      'house_number',
      'representative_no',
      'representative_name',
      'incoterm',
      'incoterm2',
      'country',
      'weight_account_partner_sap_id',
      'delivery_partner_sap_id',
    ];

    const mainActions = [
      DataAction.deleteAllPositionItems(),
      DataAction.deleteAllAdditionalCostItems(),
      DataAction.createPositionItem(),
      DataAction.setCustomerPartners(partners),
      DataAction.setSelectedCustomerIsBlocked(Boolean(customer.blocked)),
      customerSearchFormControlls.setValue('additional_sale_customer_dropdown_select', ''),
      orderFormCotnrolls.setValue(orderFieldNames.relevant_for_sap, !customer.internal),
      orderFormCotnrolls.setValue(orderFieldNames.notes, customer.comments && String(customer.comments)),
      orderFormCotnrolls.setValue(orderFieldNames.currency_code, defaultCurrency),
    ];

    if (customer.internal) {
      const actions: RootAction[] = [];

      fieldsToFill.forEach((field: string) => {
        if (customer && (customer[field] || customer[field] === '')) {
          actions.push(orderFormCotnrolls.setValue(orderFieldNames[field], String(customer[field])));
        }
      });

      return [
        ...mainActions,
        ...actions
      ];
    }

    const queue = DataService.getCustomerRepresentative({ customer_id });

    return forkJoin(queue).pipe(
      mergeMap((response: Responce) => {
        const actions: RootAction[] = [];

        const representativeFieldsToFill = [
          'representative_no',
          'representative_name',
        ];

        const res = response.pop();

        if (res.status !== "OK") {
          return [
            setErrorMessage(res.message),
            DataAction.createRequestStatusUpdate("ERROR")
          ];
        }

        const representative = res.data;

        fieldsToFill.forEach((field: string) => {
          if (representativeFieldsToFill.includes(field)) {
            actions.push(orderFormCotnrolls.setValue(orderFieldNames[field], String(representative[field])));
            return;
          }

          if (customer && (customer[field] || customer[field] === '')) {
            actions.push(orderFormCotnrolls.setValue(orderFieldNames[field], String(customer[field])));
          }
        });

        return [
          DataAction.createRequestStatusUpdate("SEARCHED"),
          ...mainActions,
          ...actions,
        ];
      })
    );
  })
);

const handleDeletePositionItem: Epic<RootAction> = (
  action$,
  store
) => action$.pipe(
  filter(action => (
    isOfType(DATA_ORDER_DELETE_POSITION_ITEM, action)
  )),
  mergeMap(action => {
    return [
      ...((!getOrderPosiotionItems(store.value) || getOrderPosiotionItems(store.value).length === 0) ? [DataAction.createPositionItem()] : []),
    ];
  })
);

const handleCustomerShippingPartnerSelected: Epic<RootAction> = (
  action$,
  store
) =>
  action$.pipe(
    filter(action => (
      action.meta &&
      action.meta.form === FORM_ORDER_DATA &&
      action.type === FormActions.CHANGE &&
      action.meta.field === orderFieldNames.delivery_partner_sap_id
    )),
    mergeMap(action => {
      const orderDetails = getOrderDetailsData(store.value);
      const orderDetailsFields = orderDetails && orderDetails.order_details;
      const shippingPartners = getOrderCustomerShippingPartners(store.value);
      const shippingPartnerId = getOrderShippingAddressPartner(store.value);

      const getShippingPartner = () => {
        const shippingPartner = shippingPartners.find(shippingPartner => shippingPartner.sap_id === Number(shippingPartnerId));

        if (shippingPartner) {
          return shippingPartner;
        }

        const activeSearch = getActiveSearchType(store.value);

        if (activeSearch === defaultCustomerSearch) {
          return null;
        }

        const customer_id = getCustomerSelectFormValues(store.value);
        const customers = getSearchedCustomers(store.value);
        const customer = customers && customers[customer_id];

        if (customer) {
          return {
            'shipping_address_primary_name': customer.primary_name,
            'shipping_address_secondary_name': customer.secondary_name,
            'shipping_address_street': customer.street,
            'shipping_address_house_number': customer.house_number,
            'shipping_address_post_code': customer.post_code,
            'shipping_address_city': customer.city,
            'shipping_address_country': customer.country
          } as CustomerPartner;
        }

        return null;
      };

      const shippingPartner = getShippingPartner();

      const fieldsToFill = [
        'shipping_address_primary_name',
        'shipping_address_secondary_name',
        'shipping_address_street',
        'shipping_address_house_number',
        'shipping_address_post_code',
        'shipping_address_city',
        'shipping_address_country',
      ];

      const actions: RootAction[] = [];

      if (shippingPartner) {
        fieldsToFill.forEach((field: string) => {
          if ((shippingPartner[field] || shippingPartner[field] === '')) {
            actions.push(shippingAddressFormControlls.setValue(shippingAddressFieldNames[field], String(shippingPartner[field])));
          }
        });

        const shippingPartnerIsBlocked = Boolean(shippingPartner.blocked);
        actions.push(DataAction.setSelectedCustomerShippingPartnerIsBlocked(shippingPartnerIsBlocked));
      } else {
        fieldsToFill.forEach((field: string) => {
          actions.push(shippingAddressFormControlls.setValue(
            shippingAddressFieldNames[field],
            (orderDetailsFields && orderDetailsFields[field] && String(orderDetailsFields[field])) || ''
          ));
        });

        actions.push(DataAction.setSelectedCustomerShippingPartnerIsBlocked(false));
      }

      return [
        ...actions
      ];
    })
  );

const handleCustomerRepresentativeSelected: Epic<RootAction> = (
  action$,
  store
) =>
  action$.pipe(
    filter(action => (
      action.meta &&
      action.meta.form === FORM_ORDER_DATA &&
      action.type === FormActions.CHANGE &&
      action.meta.field === orderFieldNames.representative_no
    )),
    map(() => {
      const orderDetails = getOrderDetailsData(store.value);
      const orderDetailsFields = orderDetails && orderDetails.order_details;
      const representatives = getRepresentatives(store.value);
      const representativeNumber = getOrderRepresentativeNumber(store.value);
      const representative = Object.values(representatives).find(representative => {
        return representative.code === representativeNumber;
      });

      if (orderDetailsFields && representativeNumber && orderDetailsFields['representative_no'] === representativeNumber) {
        return orderFormCotnrolls.setValue(orderFieldNames['representative_name'], (orderDetailsFields['representative_name'] && String(orderDetailsFields['representative_name'])) || '');
      }

      return representative ?
        orderFormCotnrolls.setValue(orderFieldNames['representative_name'], String(representative['text'])) :
        orderFormCotnrolls.setValue(orderFieldNames['representative_name'], '');
    })
  );

const handleEditPositionLock: Epic<RootAction> = (
  action$,
  store
) =>
  action$.pipe(
    filter(action => (isOfType(DATA_ORDER_EDIT_LOCK, action))
    ),
    mergeMap(action => {
      const queue = TradingService.lockAll({ ...action.payload });
      return forkJoin(queue).pipe(
        mergeMap((response: TradingResponce) => {
          const res = response.pop();
          if (res.status !== "OK") {
            const user = getSessionUser(store.value);
            let failedLock: boolean = false;
            if (action.payload.purchase_positions && action.payload.purchase_positions.length > 0) {
              const purchase = getPurchaseRawData(store.value);
              action.payload.purchase_positions.map((pos: string) => {
                if (purchase[pos].locked_by && purchase[pos].locked_by !== user) {
                  failedLock = true;
                }
              });
            } else {
              const sales = getSalesRawData(store.value);
              action.payload.sale_positions.map((pos: string) => {
                if (sales[pos].locked_by && sales[pos].locked_by !== user) {
                  failedLock = true;
                }
              });
            }
            if (failedLock) {
              return [
                setErrorMessage(res.message),
                DataAction.createRequestStatusUpdate("ERROR")
              ];
            }
          }
          return [DataAction.createRequestStatusUpdate("EDITABLE")];
        })
      );
    })
  );

const handleSentToSap: Epic<RootAction> = (
  action$,
  store
) =>
  action$.pipe(
    filter(action => (isOfType(SAP_SENT_TO_SAP, action))
    ),
    mergeMap(action => {
      const table = getActiceSapTable(store.value);
      const orders = getSelectedSapItems(store.value);
      const queue = TradingService.sentToSap({ [table]: orders });
      return forkJoin(queue).pipe(
        mergeMap((response: TradingResponce) => checkOrderResponce(response)) //checkOrderResponceAndDownloadFile(response))
      );
    })
  );


const handleReportDownload: Epic<RootAction> = (
  action$,
  store
) =>
  action$.pipe(
    filter(action =>
      (isOfType(DATA_DOWNLOAD_REPORT, action))
    ),
    mergeMap(action => {
      let filename: string;
      if (action.payload.filename) {
        filename = action.payload.filename;
      } else if (action.payload.id) {
        const reports = getReports(store.value);
        filename = reports[action.payload.id] && String(reports[action.payload.id].filename);
      }
      if (!filename) {
        return [];
      }
      const queue = DataService.downloadReport(filename);
      return forkJoin(queue).pipe(
        mergeMap((response: Responce) => [])
      );
    })
  );

const handleBillingToSap: Epic<RootAction> = (
  action$,
  store
) =>
  action$.pipe(
    filter(action => (isOfType(SAP_BILLING_TO_SAP, action))
    ),
    mergeMap(action => {
      const table = getActiceSapTable(store.value);
      const orders = getSelectedSapItems(store.value);
      const invoiceDate = getInvoiceDate(store.value);
      const queue = TradingService.sapBilling({
        [table]: orders,
        language: action.payload.lng,
        invoice_date: invoiceDate,
      });
      return forkJoin(queue).pipe(
        mergeMap((response: TradingResponce) => checkOrderResponceAndDownloadFile(response))
      );
    })
  );

const handleDeliveringToSap: Epic<RootAction> = (
  action$,
  store
) =>
  action$.pipe(
    filter(action => (isOfType(SAP_DELIVERING_TO_SAP, action))
    ),
    mergeMap(action => {
      const table = getActiceSapTable(store.value);
      const orders = getSelectedSapItems(store.value);
      const queue = TradingService.sapDelivering({
        [table]: orders,
        language: action.payload.lng,
      });
      return forkJoin(queue).pipe(
        mergeMap((response: TradingResponce) => checkOrderResponceAndDownloadFile(response))
      );
    })
  );

const handleHistoryLoad: Epic<RootAction> = (
  action$,
  store
) =>
  action$.pipe(
    filter(action =>
      (isOfType(DATA_LOAD_HISTORY, action))
    ),
    mergeMap(action => {
      const queue = TradingService.getHisory(action.payload);
      return forkJoin(queue).pipe(
        map((response: TradingResponce) => {
          const res = response.pop();
          if (res.status !== "OK") {
            return setErrorMessage(res.message);
          }
          return DataAction.updateHistory(res.data as { [index: string]: Data });
        })
      );
    })
  );

const handleUserHistoryLoad: Epic<RootAction> = (
  action$,
  store
) =>
  action$.pipe(
    filter(action =>
      (action.type === FormActions.CHANGE &&
        action.meta &&
        action.meta.form === FORM_HISTORY_USER) ||
      (isOfType(DATA_LOAD_USER_HISTORY, action))
    ),
    mergeMap(action => {
      const user = getSelectedHistoryUser(store.value);
      const queue = TradingService.getHisory({ user });
      return forkJoin(queue).pipe(
        map((response: TradingResponce) => {
          const res = response.pop();
          if (res.status !== "OK") {
            return setErrorMessage(res.message);
          }
          return DataAction.updateHistory(res.data as { [index: string]: Data });
        })
      );
    })
  );

const handleMaterialEdits: Epic<RootAction> = (
  action$,
  store
) =>
  action$.pipe(
    filter(action =>
      (isOfType(DATA_EDIT_MATERIAL, action))
    ),
    mergeMap(action => {
      const materialData = getMaterialFormData(store.value);
      const queue = DataService.editMaterial({ ...action.payload, ...materialData });
      return forkJoin(queue).pipe(
        mergeMap((response: Responce) => checkOrderResponce(response))
      );
    })
  );

const handleMaterialAdd: Epic<RootAction> = (
  action$,
  store
) =>
  action$.pipe(
    filter(action =>
      (isOfType(DATA_ADD_MATERIAL, action))
    ),
    mergeMap(action => {
      try {
        const materialData = getMaterialFormData(store.value);

        const multipliers = (materialData.multiplier as string).split(',').map((multiplier: string) => multiplier.trim());

        if (!multipliers.length) {
          throw new Error('Multipliers are required');
        }

        const weightUnits = (materialData.weight_unit as string).split(',').map((unit: string) => unit.trim());

        if (!weightUnits.length) {
          throw new Error('Weight units are required');
        }

        if (multipliers.length !== weightUnits.length) {
          throw new Error('Multipliers and weight units must have the same length');
        }

        const priceWeightUnits = (() => {
          const priceUnits = (materialData.price_unit as string).split(',').map((unit: string) => unit.trim());

          if (!priceUnits.length) {
            throw new Error('Price units are required');
          }

          const referenceCurrency = priceUnits[0].split('/')[0];

          if (!referenceCurrency) {
            throw new Error('Price units format is invalid');
          }

          return priceUnits
            .filter((unit: string) => unit.split('/')[0] === referenceCurrency)
            .map((unit: string) => {
              const [price, weight] = unit.split('/');

              return weight;
            });
        })();

        if (priceWeightUnits.length !== weightUnits.length) {
          throw new Error('Price units and weight units must have the same length');
        }

        const data = {
          material_code: materialData.material_code,
          color: materialData.color,
          name: materialData.name,
          material_number: materialData.material_number,
          price_weight_unit_multipliers: multipliers.map((multiplier: string, index: number) => {
            return {
              price_weight_unit: priceWeightUnits[index],
              weight_unit: weightUnits[index],
              multiplier: multiplier,
            };
          }),
        };

        const queue = DataService.addMaterial(data);
        return forkJoin(queue).pipe(
          mergeMap((response: Responce) => checkOrderResponce(response))
        );
      } catch (error) {
        return [
          setErrorMessage(error.message),
          DataAction.createRequestStatusUpdate("ERROR")
        ];
      }
    })
  );

export default combineEpics(
  handleCreateOrderOperations,
  handleCustomerSearchSubmit,
  handleCreatePositionItem,
  handleCreateAdditionalCostItem,
  handlePositionItemPriceChange,
  handlePurchaseOrderDetails,
  handleSalesOrderDetails,
  handlePurchaseOrderDelete,
  handleSalesOrderDelete,
  handlePurchaseDeliveryDelete,
  handleSalesDeliveryDelete,
  handlePurchaseInvoiceDelete,
  handleSalesInvoiceDelete,
  handleOrderDetailsSet,
  handleCustomerSelected,
  handleAdditionalSaleCustomerDropdownSelected,
  handleEditPositionLock,
  handleSentToSap,
  handleBillingToSap,
  handleDeliveringToSap,
  handleHistoryLoad,
  handleUserHistoryLoad,
  handleMaterialEdits,
  handleMaterialAdd,
  handleReportDownload,
  handlePrefillingCustomerData,
  handleDeletePositionItem,
  handleCustomerShippingPartnerSelected,
  handleCustomerRepresentativeSelected,
  handleDataFieldsChange
);
