import * as React from 'react';
import { FormBuilder, HandledFormProps, FormElements } from '../../../utils/form/form';
import { i18nContext, I18n } from '../../../../language';
import { FORM_BILLING_DATA } from '../../../../data/constants';
import { billingFields } from './billing-fields';
import { RootState, RootAction } from '../../../../store/types';
import { connect } from 'react-redux';
import { billingFieldNames } from '../../../../reducers/form';
import { format } from 'date-fns';

interface BillingDataFormProps extends HandledFormProps {
    readonly?: boolean;

    applySelect?: (field: string, value: string, preview: string) => void;
}
const formBuilder = new FormBuilder();

const BillingDataForm = FormBuilder.form<BillingDataFormProps>(
    (props: BillingDataFormProps) => {
        return (
            <i18nContext.Consumer>
                {i18n => {
                    formBuilder.setElements(billingFields(i18n, props.readonly));
                    
                    return (
                        <div className="p-0 d-flex flex-wrap billing-form">
                            {formBuilder.build()}
                        </div>
                    )
                }}
            </i18nContext.Consumer>
        )
    },
    FORM_BILLING_DATA
);

class BillingData extends React.Component<BillingDataFormProps, {}> {
    constructor(props: BillingDataFormProps) {
        super(props);
    }

    render() {
        return [
            <BillingDataForm {...this.props} />,
        ]
    }
}

const billingFormControlls = FormBuilder.formActions(FORM_BILLING_DATA);
const mapStateToProps = (
    state: RootState,
    ownProps: BillingDataFormProps
) => {
    return { }
};
const mapDispatchToProps = (
    dispatch: (action: RootAction) => void,
    ownProps: BillingDataFormProps
) => {
    return {
        applySelect(field: string, value: string, preview: string) {
            dispatch(billingFormControlls.setValue(billingFieldNames[field], value));
            dispatch(billingFormControlls.setValue(`customer_preview-${field}`, preview));
        },
    };
};

export const BillingDataFormContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(BillingData);

export default BillingDataFormContainer;
