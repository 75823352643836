import * as React from 'react';
import { connect } from 'react-redux';
import { RootAction, RootState } from '../../../../store/types';
import { getOptions, getOrderPosiotionItems, getPositionsAndAdditionalCostsFormValues, getPriceUnits } from '../../../../selectors';
import { deletePositionItem } from '../../../../actions/data';
import { default as PositionItemForm, PositionsFormProps } from './position-form';
import { getOrderPositionItemsWeightUnits, getShowAdditionalSalesFields } from '../../../../selectors/order';

const mapStateToProps = (
    state: RootState,
    ownProps: PositionsFormProps
) => {
    return {
        values: getPositionsAndAdditionalCostsFormValues(state),
        priceUnits: getPriceUnits(state),
        options: getOptions(state),
        items: getOrderPosiotionItems(state),
        showAdditionalField: getShowAdditionalSalesFields(state),
        weightUnits: getOrderPositionItemsWeightUnits(state),
    }
};
const mapDispatchToProps = (
    dispatch: (action: RootAction) => void,
    ownProps: PositionsFormProps
) => {
    return {
        deleteItem(id: string) {
            dispatch(deletePositionItem(id));
        }
    };
};

export const PositionsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(PositionItemForm);

export default PositionsContainer;