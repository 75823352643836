import * as React from 'react';

export const typeMap = {
    radioBtn: (val: boolean, title?: string, props?: any, className?: string) =>
        <td className={["text-center", className].join(' ')} title={title}  {...props}>
            <div className="w-100 d-flex justify-content-center">
                <input type="radio" readOnly checked={val} />
            </div>
        </td>,
}

export const purchaseAndSalesMap: {
    [index: string]:
    (val: string | boolean | number | JSX.Element, title?: string, props?: any) => JSX.Element
} = {
    external_ref_number: (val: boolean, title?: string, props?: any) => typeMap.radioBtn(!!val, title, props, "external_ref_number"),
    relevant_for_sap: (val: boolean, title?: string, props?: any) => typeMap.radioBtn(val, title, props, "relevant_for_sap"),
    // locked: (val: boolean, title?: string, props?: any) => typeMap.radioBtn(!!val, title, props, "locked"),
    invoice_id: (val: boolean, title?: string, props?: any) => typeMap.radioBtn(!!val, title, props, "invoice_id"),
    delivery_note_id: (val: boolean, title?: string, props?: any) => typeMap.radioBtn(!!val, title, props, "delivery_note_id"),
    sap_order_id: (val: boolean, title?: string, props?: any) => typeMap.radioBtn(!!val, title, props, "sap_order_id"),
    internal: (val: boolean, title?: string, props?: any) => typeMap.radioBtn(val, title, props, "internal"),
}

export const customHeader: { [index: string]: string } = {
    // 'locked': 'L',
    'sap_order_id': 'A',
    'invoice_id': 'F',
    'relevant_for_sap': 'G',
    'delivery_note_id': 'D',
}

export const numericValues = ['weight', 'unmatched_weight'];
export const priceValues = ['unit_price', 'position_price'];