import { action } from 'typesafe-actions';
import { TRADING_RESET_MODE } from '../data/constants';
import {
    TRADING_MODE_CHANGED,
    TRADING_MATCH_CONFIRM,
    TRADING_SALE_SELECT,
    TRADING_PURCHASE_SELECT,
    TRADING_SALE_UNLOCK,
    TRADING_SALE_LOCK,
    TRADING_PURCHASE_UNLOCK,
    TRADING_PURCHASE_LOCK,
    TRADING_RESET_ALL,
    TRADING_PURCHASE_CANCEL_LOCK,
    TRADING_SALE_CANCEL_LOCK,
    TRADING_UNLOCK_ALL,
    SAP_SELECT_ALL_PURCHASE,
    SAP_SELECT_ALL_SALE,
    SAP_SET_ITEMS,
    SAP_SELECT_ITEM,
    SAP_RESET_ITEMS,
    SAP_SENT_TO_SAP,
    SAP_SET_ACTIVE_TABLE,
    SAP_BILLING_TO_SAP,
    SAP_SELECT_ALL_ACTIVE,
    SAP_DELIVERING_TO_SAP,
    TRADING_SALE_CANCEL,
    TRADING_PURCHASE_CANCEL,
} from '../data/constants';

export const changeMode = (mode: string) =>
    action(TRADING_MODE_CHANGED, { mode });

export const selectPurchase = (id: string) =>
    action(TRADING_PURCHASE_SELECT, { id });
export const selectSale = (id: string) =>
    action(TRADING_SALE_SELECT, { id });

export const lockPurchase = (id: string, manually?: boolean) =>
    action(TRADING_PURCHASE_LOCK, { id, manually });
export const lockSale = (id: string, manually?: boolean) =>
    action(TRADING_SALE_LOCK, { id, manually });

export const cancelLockPurchase = (id: string) =>
    action(TRADING_PURCHASE_CANCEL_LOCK, { id });
export const cancelLockSale = (id: string) =>
    action(TRADING_SALE_CANCEL_LOCK, { id });

export const unlockPurchase = (id: string, manually?: boolean) =>
    action(TRADING_PURCHASE_UNLOCK, { id, manually });
export const unlockSale = (id: string, manually?: boolean) =>
    action(TRADING_SALE_UNLOCK, { id, manually });

export const canselPurchase = (ids: string) =>
    action(TRADING_PURCHASE_CANCEL, { purchase_positions: [ids] });
export const canselSale = (ids: string) =>
    action(TRADING_SALE_CANCEL, { sale_positions: [ids] });

export const confirmMatch = () =>
    action(TRADING_MATCH_CONFIRM);

export const unlockAll = () =>
    action(TRADING_UNLOCK_ALL);

export const resetMatch = () =>
    action(TRADING_RESET_ALL);
export const reestMode = () => 
    action(TRADING_RESET_MODE);

export const selectAllSapPurchase = () => action(SAP_SELECT_ALL_PURCHASE);
export const selectAllSapSale = () => action(SAP_SELECT_ALL_SALE);
export const selectAllSapActive = () => action(SAP_SELECT_ALL_ACTIVE);

export const selectSapItem = (item: string) =>
    action(SAP_SELECT_ITEM, { item });
export const setSapItems = (items: string[]) =>
    action(SAP_SET_ITEMS, { items });

export const resetSapItems = () => action(SAP_RESET_ITEMS);

export const sentToSap = () => action(SAP_SENT_TO_SAP);
export const billingToSap = (lng: string) => 
    action(SAP_BILLING_TO_SAP, { lng });
export const deliveringToSAP = (lng: string) =>
    action(SAP_DELIVERING_TO_SAP, { lng });

export const setActiveSapTable = (name: string) =>
    action(SAP_SET_ACTIVE_TABLE, { name });
