import * as React from 'react';
import { FormBuilder } from '../../../utils/form/form';
import { FORM_POSITIONS_AND_ADDITIONAL_COSTS } from '../../../../data/constants';
import { i18nContext } from '../../../../language';
import AdditionalCostFields from '../additional-costs/additional-cost-item';
import { AdditionalCostsFormProps } from '../additional-costs/additional-cost-form';

export const Headers = (): string[] => [
    "Cost Code",
    "Amount",
    "Costs",
];

const DetailAdditionalCostItemForm = (props: AdditionalCostsFormProps) => {
    return (
        <i18nContext.Consumer>
            {i18n => (
                <div className="p-3 additional-cost-table-detail-container w-100">
                    {props.items && props.items.length > 0 && props.options && !props.protected && (
                        <table className="additional-cost-table w-100">
                            <thead>
                                <tr>
                                    {Headers().map((h, i) => <td key={i}>{i18n.t(h)}</td>)}
                                </tr>
                            </thead>
                            <tbody>
                                {props.items.map((item: string) => {
                                    const formBuilder = new FormBuilder();
                                    formBuilder.setElements(AdditionalCostFields(props, item));
                                    return (
                                        <tr>
                                            {formBuilder.build()}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    )}
                </div>
            )}
        </i18nContext.Consumer>
    )
};

export default DetailAdditionalCostItemForm;