import * as React from 'react';
import { HandledFormProps, FormBuilder, FormElements } from '../../utils/form/form';
import { FORM_VIEWING_YEAR } from '../../../data/constants';

const formBuilder = new FormBuilder();

interface ViewingYearFormProps extends HandledFormProps {
    options?: Array<{
        value: string;
        text: string;
    }>
}

const ViewingYearForm = FormBuilder.form<ViewingYearFormProps>(
    (props: ViewingYearFormProps) => {
        formBuilder.setElements([{
            input: FormElements.select({
                name: 'selected_year',
                className: 'state-select',
                options: props.options
            })
        }]);
        return (
            <div className="col-6 mt-3 mx-auto">
                {formBuilder.build()}
            </div>
        )
    },
    FORM_VIEWING_YEAR
);

export default ViewingYearForm;