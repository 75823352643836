import * as React from 'react';
import { connect } from 'react-redux';
import { RootAction, RootState } from '../../../../store/types';
import { getOptions, getOrderAdditionalCostItems, getPositionsAndAdditionalCostsFormValues, getPriceUnits } from '../../../../selectors';
import { deleteAdditionalCostItem } from '../../../../actions/data';
import { default as AdditionalCostItemForm, AdditionalCostsFormProps } from './additional-cost-form';
import { getShowAdditionalSalesFields } from '../../../../selectors/order';

const mapStateToProps = (
    state: RootState,
    ownProps: AdditionalCostsFormProps
) => {
    return {
        values: getPositionsAndAdditionalCostsFormValues(state),
        options: getOptions(state),
        items: getOrderAdditionalCostItems(state),
        priceUnits: getPriceUnits(state),
    }
};
const mapDispatchToProps = (
    dispatch: (action: RootAction) => void,
    ownProps: AdditionalCostsFormProps
) => {
    return {
        deleteItem(id: string) {
            dispatch(deleteAdditionalCostItem(id));
        }
    };
};

export const AdditionalCostsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(AdditionalCostItemForm);

export default AdditionalCostsContainer;