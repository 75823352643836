import * as React from 'react';
import { connect } from 'react-redux';
import { RootAction, RootState } from '../../../../store/types';
import {
    getOrderPosiotionItems,
    getOrderAdditionalCostItems,
    getShippingAddressFormIsDisplayed,
    getCustomerOrPartnersAreBlocked,
    getShowSalesShippingAddressFields,
    getAdditionalCostsFeatureAvailability,
} from '../../../../selectors';
import {
    createPositionItem,
    createAdditionalCostItem,
} from '../../../../actions/data';
import { default as CreateOrderTableContainerComponent, CreateOrderTableFormsContainerComponentProps } from './create-order-table-container-component';

const mapStateToProps = (
    state: RootState,
    ownProps: CreateOrderTableFormsContainerComponentProps
) => {
    return {
        positionItems: getOrderPosiotionItems(state),
        additionalCostItems: getOrderAdditionalCostItems(state),
        shippingAddressFormIsDisplayed: getShippingAddressFormIsDisplayed(state),
        customerOrPartnersAreBlocked: getCustomerOrPartnersAreBlocked(state),
        showSalesShippingAddressFields: getShowSalesShippingAddressFields(state),
        additionalCostsAreEnabled: getAdditionalCostsFeatureAvailability(state),
    }
};
const mapDispatchToProps = (
    dispatch: (action: RootAction) => void,
    ownProps: CreateOrderTableFormsContainerComponentProps
) => {
    return {
        addPositionItem() {
            dispatch(createPositionItem());
        },
        addAdditionalCostItem() {
            dispatch(createAdditionalCostItem());
        },
    };
};

export const CreateOrderTableContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateOrderTableContainerComponent);

export default CreateOrderTableContainer;