import * as React from 'react';
import { ThemeProvider } from '@zendeskgarden/react-theming';
import { Dots } from '@zendeskgarden/react-loaders';

export const Loader = (props: { className?: string }) => (
    <div className={props.className} >
        <ThemeProvider>
            <Dots delayMS={200} />
        </ThemeProvider>
    </div>
);
