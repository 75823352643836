import { Textes } from './data';
import { I18n } from '../../../../language';
import { FormElements } from '../../../utils/form/form';
import { billingFieldNames } from '../../../../reducers/form';

export const billingFields = (i18n: I18n, readonly?: boolean) => {
    return [
        {
            label: i18n.t(Textes.invoice_date),
            input: FormElements.date({
                name: billingFieldNames.invoice_date,
                className: 'w-100 widest-input form-control form-control-sm configurator__form-control configurator__form-control--fixed d-inline-block',
                disabled: readonly,
                locale: i18n.language,
            }),
            className: 'col-12 pb-0',
            wrapClass: 'd-flex flex-row justify-content-between',
            classNameLeft: 'd-flex align-items-center col-4 p-0',
            classNameRight: 'col-8 pr-0',
        },
    ];
}
