import * as React from 'react';
import { FormBuilder, HandledFormProps, FormElements } from '../../../utils/form/form';
import { i18nContext } from '../../../../language';
import { Data } from '../../../../reducers/data';
import { FORM_CUSTOMER_SELECT, defaultCustomerSearch } from '../../../../data/constants';
import { SimpleTable } from '../../../utils/table';
import { Textes } from '../order/data';
import { typeMap } from '../../tables/tableformat';

interface CustomerSelectFormProps extends HandledFormProps {
    searchedCustomers?: Data;
    activeSearch?: string;

    onSelectAnotherCustomer?: () => void;
}

const Headers = ["Selected", "Customer number / Company name", "Address", "Blocked"];
const fieldsToDisplay = ["sap_id", "address", "name", "blocked"];

const prpareItem = (items: Data, activeSearch?: string, onSelectAnotherCustomer?: () => void) => {
    if (!items) {
        return items;
    }

    const handleOnChangeActionBasicCondition = onSelectAnotherCustomer && activeSearch === defaultCustomerSearch;

    const preparedItems: Data = {};

    Object.keys(items).map(key => {
        const isBlocked = items[key]['blocked'];
        const isInternal = items[key]['internal'];

        const handleOnChangeAction = handleOnChangeActionBasicCondition && !isInternal;

        Object.keys(items[key]).map(k => {
            if (!preparedItems[key]) {
                preparedItems[key] = {};
            }
            if (k === 'sap_id') {
                preparedItems[key][k] = (
                    <label htmlFor={`customer-select-${items[key].sap_id}`} className="text-center pointer mb-0 d-flex justify-content-center w-100 h-100">
                        {FormElements.input({
                            id: `customer-select-${items[key][k]}`,
                            name: "customer_select",
                            type: "radio",
                            value: String(items[key][k]),
                            required: true,
                            onChange: (event: React.FormEvent<HTMLInputElement>) => handleOnChangeAction && onSelectAnotherCustomer(),
                        })}
                    </label>
                )
            } else if (k === 'blocked') {
                preparedItems[key][k] = typeMap.radioBtn(!!isBlocked, null, null, "text-center pointer mb-0 d-flex justify-content-center w-100 h-100");
            } else if (fieldsToDisplay.includes(k)) {
                preparedItems[key][k] = (
                    <label htmlFor={`customer-select-${items[key].sap_id}`}
                        className={[
                            "pointer mb-0 w-100 h-100",
                            items[key][k] ? "" : "empty-cell",
                            isBlocked ? "red-label" : ""
                        ].join(" ")}
                    >
                        {items[key][k]}
                    </label>);
            }
        });
    })
    return preparedItems;
}

const CustomerSelectForm = FormBuilder.form<CustomerSelectFormProps>(
    (props: CustomerSelectFormProps) => {
        return (
            <i18nContext.Consumer>
                {i18n => (
                    <div>
                        {props.activeSearch && <h5>{`${i18n.t('Please select')} ${i18n.t(Textes[props.activeSearch])}`}</h5>}
                        <div className="search-table-container">
                            <SimpleTable
                                headers={Headers}
                                items={prpareItem(props.searchedCustomers, props.activeSearch, props.onSelectAnotherCustomer)}
                                className="search-table" />
                        </div>
                    </div>
                )}
            </i18nContext.Consumer>
        )
    },
    FORM_CUSTOMER_SELECT
);

export default CustomerSelectForm;