import * as React from 'react';
import { connect } from 'react-redux';
import { RootState, RootAction } from '../store/types';
import { default as Login } from './login/login';
import { HomePage } from './homepage/homepage';
import { Header } from './login/header';
import { default as LanguageSwitcher } from './language-switcher';
import { appGetInit } from '../actions/app';
import { getSessionUser, getSelectedLanguage } from '../selectors';
import { i18n } from '../language';
import { i18nContext } from '../language';
import { componentWillAppendToBody } from 'react-append-to-body';
import { default as AlertComponent } from './alert';

interface MainAppProps {
  user?: string;
  selectedLanguage?: string;
  applicationGetInit?: () => void;
}

const Alert = componentWillAppendToBody(AlertComponent);

class MainApp extends React.Component<MainAppProps, {}> {

  componentDidMount() {
    this.props.applicationGetInit();
  }

  render() {
    return (
      <i18nContext.Provider value={{
        language: this.props.selectedLanguage,
        t: (key: string) => i18n.t(key),
        formatNumber: (value: number) => i18n.formatNumber(value),
        formatPrice: (value: number) => i18n.formatPrice(value),
      }}>
        <Alert />
        {this.props.user ? (
          <div className="w-100">
            <HomePage />
          </div>
        ) :
          <div className="w-100 h-100 position-fixed overflow-auto">
            <div key="head" className="login-header position-fixed d-flex w-100 flex-row py-3">
              <Header />
              <LanguageSwitcher />
            </div>
            <div key="login" className="d-flex justify-content-center align-items-center w-100 h-100">
              <Login />
            </div>
          </div>
        }
      </i18nContext.Provider>
    )
  }
}

const mapStateToProps = (
  state: RootState,
  ownProps: MainAppProps
) => {
  return {
    user: getSessionUser(state),
    selectedLanguage: getSelectedLanguage(state),
  }
};
const mapDispatchToProps = (
  dispatch: (action: RootAction) => void,
  ownProps: MainAppProps
) => {
  return {
    applicationGetInit: () => {
      dispatch(appGetInit());
    },
  };
};

const MainAppContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MainApp);

export default MainAppContainer;