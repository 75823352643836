import { PersistedState } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {
  defaultState,
  rootReducer,
} from './root-reducer';
import rootEpic from './root-epic';
import { applyMiddleware, createStore, compose } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import {
  persistStore,
  persistReducer,
} from 'redux-persist';
import StorageLocal, { StorageSession } from '../services/storage';
import socketMiddleware from '../services/socket/middleware';

export const epicMiddleware = createEpicMiddleware();

const persistConfig = {
  key: 'hum_td',
  storage,
  version: 0,
  whitelist: ['app'],
  migrate: (state: PersistedState, version: number) => {
    // const app = state instanceof Object && (state as any).app;
    const result = { ...state } as PersistedState;
    const defaults = defaultState(
      StorageLocal.get('Language', 'HuM_') || 'en',
      StorageSession.get('authorizationToken', 'HuM_') || '',
      StorageSession.get('sessionUser', 'HuM_') || '',
      StorageSession.get('sessionRole', 'HuM_') || '',
    );
    Object.assign(result, defaults);
    return Promise.resolve(result);
  },
};

function configureStore() {
  const middlewares = [epicMiddleware];

  // compose enhancers
  const enhancer = applyMiddleware(...middlewares, socketMiddleware);
  const composeEnhancers =
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  // const composeEnhancers = compose;

  const dat = createStore(
    persistReducer(persistConfig, rootReducer),
    {},
    composeEnhancers(enhancer),
  );
  middlewares.forEach(m => m.run(rootEpic));

  return dat;
}

export const initStore = () => {
  // pass an optional param to rehydrate state on app start
  const store = configureStore();
  const persistor = persistStore(store);
  return { store, persistor };
};

export default initStore;
