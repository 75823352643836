import * as React from 'react';
import { FormBuilder } from '../../../utils/form/form';
import { FORM_POSITIONS_AND_ADDITIONAL_COSTS } from '../../../../data/constants';
import { i18nContext } from '../../../../language';
import { PositionsFormProps } from '../positions/position-form';
import { Navs } from '../../../utils/navs';
import DetailPositionItemTable from './position-table';

const Textes = {
    title: "Order Details",
    positions: "Positions",
    additionalCosts: "Additional Costs",
    history: "History",
    close: "Close",
    delete: "Delete Order",
    edit: "Edit",
    save: "Save Changes",
    discard: "Discard Changes",
    closeWS: "Close without saving",
    cancel: "Cancel",
    removeText: "Do you really want to delete this order?",
}

const detailsTabs = {
    positions: "positions",
    additional_costs: "additional_costs",
    history: "history",
}

export interface DetaillPositionsFormProps extends PositionsFormProps {
    isEditable?: boolean;
    isInvoiceCreated?: boolean;
    minWeight?: { [index: string]: number };
    additionalCostsIds?: string[];
    orderDetails?: any;
    activeTab?: string;
    isPurchase?: boolean;

    onTabLoad?: (eventKey: string, e: React.SyntheticEvent<{}>) => void;
    handleEditable?: () => void;
    discardChanges?: () => void;
    handleDelete?: () => void;
    handeClosePopup?: () => void;
}

const DetaillPositionItemForm = (props: DetaillPositionsFormProps) => {
    return (
        (<DetailPositionItemTable
            readonly={props.readonly}
            items={props.minWeight && Object.keys(props.minWeight) || []}
            {...props}
        />)
    )
};

export default DetaillPositionItemForm;