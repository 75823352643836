import * as React from 'react';
import { FormBuilder } from '../../../utils/form/form';
import { i18nContext } from '../../../../language';
import { FORM_SHIPPING_ADDRESS } from '../../../../data/constants';
import { RootState, RootAction } from '../../../../store/types';
import { getOptions, getOrderDetailsCustomerShippingPartnersAsOptions, getSelectedCustomerShippingPartnerIsBlocked } from '../../../../selectors';
import { connect } from 'react-redux';
import { getIsLimitedUpdate, getOrderInvoiceCreated, getOrderPrevMonthLock, getOrderRegisteredInSAP, getOrderRelevantForSap, getShowSalesShippingAddressFields } from '../../../../selectors/order';
import { shippingAddressFields } from '../addresses/shipping-address-fields';
import { ShippingAddressFormProps } from '../addresses/shipping-address-form';
import { AddressFormProps } from '../addresses/data';
import { getInactiveOrderFormFields } from '../../../../helpers/helper';


const formBuilder = new FormBuilder();

const TEXTES = {
    currentAddressConfiguration: 'Current address configuration',
};

const DetailsShippingAddressForm = FormBuilder.form<ShippingAddressFormProps>(
    (props: ShippingAddressFormProps) => {
        const inactiveFields = getInactiveOrderFormFields(
            props.readonly,
            props.isInvoiceCreated,
            props.isRelevantOrderForSap,
            props.isLimitedUpdate,
            props.prevMonthLock,
            props.isRegisteredInSap
        );

        return (
            <i18nContext.Consumer>
                {i18n => {
                    formBuilder.setElements(shippingAddressFields(
                        i18n,
                        props.readonly,
                        props.options,
                        props.isInvoiceCreated,
                        props.shippingPartnersOptions(i18n.t(TEXTES.currentAddressConfiguration)),
                        'Shipping Address',
                        props.partnerIsBlocked,
                        inactiveFields
                    ));
                    return (
                        <div className="p-3 d-flex flex-wrap position-relative shipping-address-form">
                            {formBuilder.build()}
                        </div>
                    );
                }}
            </i18nContext.Consumer>
        );
    },
    FORM_SHIPPING_ADDRESS
);

class DetailsShippingAddress extends React.Component<ShippingAddressFormProps, {}> {
    constructor(props: ShippingAddressFormProps) {
        super(props);
    }

    render() {
        return [
            <DetailsShippingAddressForm { ...this.props } />,
        ];
    }
}

const shippingAddressFormControlls = FormBuilder.formActions(FORM_SHIPPING_ADDRESS);

const mapStateToProps = (
    state: RootState,
    ownProps: AddressFormProps
) => {
    return {
        options: getOptions(state),
        shippingPartnersOptions: getOrderDetailsCustomerShippingPartnersAsOptions(state),
        isRelevantOrderForSap: getOrderRelevantForSap(state),
        isInvoiceCreated: getOrderInvoiceCreated(state),
        isLimitedUpdate: getIsLimitedUpdate(state),
        prevMonthLock: getOrderPrevMonthLock(state),
        isRegisteredInSap: getOrderRegisteredInSAP(state),
        partnerIsBlocked: getSelectedCustomerShippingPartnerIsBlocked(state),
    }
}

const mapDispatchToProps = (
    dispatch: (action: RootAction) => void,
    ownProps: AddressFormProps
) => {
    return {}
}

const DetailsShippingAddressFormContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(DetailsShippingAddress);

export default DetailsShippingAddressFormContainer;
