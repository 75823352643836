import * as React from 'react';
import { SimpleTable } from '../../utils/table';
import { Data } from '../../../reducers/data';

interface PriceTableProps {
    items: Data;
    title: string;
    color: string;
    className?: string;
}
const Headers = [" ", "Weight", "Price in €"];

export const PriceTable = (props: PriceTableProps) => (
    <div className={props.className}>
        <div className="text-center font-weight-bold">{props.title}</div>
        {props.items && <SimpleTable
            headers={Headers}
            items={props.items}
            formatNumbers
            className={[
                'mt-3',
                'price-table',
                `price-table-${props.color}`
            ].join(' ')} />}
    </div>
)
