import * as React from 'react';
import { FormBuilder } from '../../../utils/form/form';
import { i18nContext } from '../../../../language';
import PositionFields from '../positions/position-item';
import { PositionsFormProps, Headers } from '../positions/position-form';

export interface DetaillPositionsFormProps extends PositionsFormProps {
    minWeight?: {[index: string]: number};
}

const DetailPositionItemTable = (props: DetaillPositionsFormProps) => {
        return (
            <i18nContext.Consumer>
                {i18n => (
                    <div className="p-3 position-table-detail-container w-100">
                        <table className="position-table w-100">
                            <thead>
                                <tr>
                                    {props.items && props.items.length > 0 && props.options ? Headers(props.isPurchase, false, props.showAdditionalField).map((h, i) => <td key={i}>{i18n.t(h)}</td>) : null}
                                </tr>
                            </thead>
                            <tbody>
                                {props.items && props.options && props.items.map((item: string) => {
                                    const formBuilder = new FormBuilder();
                                    formBuilder.setElements(PositionFields(props, item));
                                    return (
                                        <tr>
                                            {formBuilder.build()}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                )}
            </i18nContext.Consumer>
        )
    }

export default DetailPositionItemTable;