import * as app from '../actions/app';
import { ActionType } from 'typesafe-actions';
import {
    APP_CHANGE_LANGUAGE,
    APP_SET_SUCCESS_MESSAGE,
    APP_SET_ERROR_MESSAGE,
    APP_HIDE_NOTIFICATION_MESSAGE,
    APP_SET_INFO_MESSAGE,
    APP_UPDATE_LIMITED_VERSION,
    SET_SELECTED_TAB,
} from '../data/constants';
import { i18n } from '../language'
import { generateId } from '../helpers/helper';

export type AppAction = ActionType<typeof app>;

export type AppState = {
    language: string;
    isInit: boolean;
    notificationMessages: { [index: string]: { [index: string]: string | boolean } };
    selectedTab: string;
    limitedVersion: boolean;
};

export const defaultAppState: AppState = {
    language: 'en',
    isInit: false,
    notificationMessages: {},
    selectedTab: null,
    limitedVersion: false,
};

export function buildDefaultState(data: {
    language?: string;
}): AppState {
    return {
        ...defaultAppState,
        ...data,
    };
}

export const saleSuccess = 'SALE_SUCCESS';
export const purchaseSuccess = 'PURCHASE_SUCCESS';
export const materialseSuccess = 'MATERIALS_SUCCESS';
export const orderInfo = 'ORDER_INFO';
export const settingsInfo = 'SETTINGS_INFO';
export const dashboardInfo = 'DASHBOARD_INFO';

const messages: { [index: string]: string } = {
    [saleSuccess]: "Sales table was updated",
    [purchaseSuccess]: "Purchase table was updated",
    [materialseSuccess]: "Materials were updated",
    [orderInfo]: "Order data was updated",
    [settingsInfo]: "Settings were changed",
    [dashboardInfo]: "Dashboard was updated",
};

const addErrorMessage = (message: string, id: string) =>
    ({ message, color: "danger", display: true, id })
const addSuccessMessage = (message: string, id: string) =>
    ({ message, color: "success", display: true, id })
const addInfoMessage = (message: string, id: string) =>
    ({ message, color: "info", display: true, id })

export const AppMainReducer = (
    state: AppState = defaultAppState,
    action: AppAction
) => {
    switch (action.type) {
        case APP_CHANGE_LANGUAGE:
            i18n.changeLanguage(action.payload.language);
            return { ...state, language: action.payload.language };
        case APP_SET_ERROR_MESSAGE: {
            const id = generateId();
            return {
                ...state, notificationMessages:
                {
                    ...state.notificationMessages,
                    [id]: addErrorMessage(action.payload.message, id)
                }
            };
        }
        case APP_SET_SUCCESS_MESSAGE: {
            const id = generateId();
            return {
                ...state, notificationMessages:
                {
                    ...state.notificationMessages,
                    [action.payload.type]: addSuccessMessage(messages[action.payload.type], id)
                }
            };
        }
        case APP_SET_INFO_MESSAGE: {
            const id = generateId();
            return {
                ...state, notificationMessages:
                {
                    ...state.notificationMessages,
                    [action.payload.type]: addInfoMessage(messages[action.payload.type], id)
                }
            };
        }
        case APP_HIDE_NOTIFICATION_MESSAGE:
            const { [action.payload.id]: hiddenNotificationMessages, ...notificationMessages } = state.notificationMessages;
            return { ...state, notificationMessages };
        case APP_UPDATE_LIMITED_VERSION:
            return {
                ...state,
                limitedVersion: action.payload.isLimited
            };
        case SET_SELECTED_TAB:
            return { ...state, selectedTab: action.payload.name };
        default:
            return state;
    }
};

export const AppReducer = {
    reducer: AppMainReducer,
    defaultSate: defaultAppState,
    stateBuilder: buildDefaultState,
};

export default AppReducer;