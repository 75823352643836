import { FormElements } from '../../../utils/form/form';
import { additionalCostsFieldNames } from '../../../../reducers/form';
import { AdditionalCostsFormProps } from './additional-cost-form';
import { i18n } from '../../../../language';


const AdditionalCostFields = (props: AdditionalCostsFormProps, index: string) => [
    {
        input: FormElements.select({
            name: `${additionalCostsFieldNames.cost_code}-${index}`,
            className: 'state-select',
            required: true,
            options: props.options.additional_costs || [],
            disabled: props.readonly,
        }),
        required: true,
        tableCell: true,
    }, {
        input: FormElements.number({
            name: `${additionalCostsFieldNames.amount}-${index}`,
            className: 'widest-input',
            type: 'text',
            min: '1',
            required: true,
            disabled: props.readonly,
            maxLength: 4,
        }),
        required: true,
        type: 'number',
        tableCell: true,
    }, {
        input: FormElements.number({
            name: `${additionalCostsFieldNames.costs}-${index}`,
            className: 'widest-input',
            required: true,
            thousandSeparator: i18n.getCurrentThousandSeparator(),
            decimalSeparator: i18n.getCurrentDecimalSeparator(),
            postfix: props.priceUnits && props.priceUnits[index],
            postfixclassname: "pl-1",
            step: 'any',
            disabled: props.readonly,
            maxLength: 20,
        }),
        required: true,
        type: 'number',
        tableCell: true,
    },
]

export default AdditionalCostFields;
