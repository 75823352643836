import * as React from 'react';
import { TabTableProps, TabTable } from '../../tables/tabtable';
import { i18nContext } from '../../../../language';
import { RootState, RootAction } from '../../../../store/types';
import { connect } from 'react-redux';
import { getDataIsLoaded, getReportsData } from '../../../../selectors/data';
import { customSort, customRowRenderWithButtons, getReportsTableHeaders } from '../../tables/tabtable-components';
import { resetReports, loadReports, downloadReportById } from '../../../../actions/data';

export interface ReportsTabProps extends TabTableProps {
    updateReports?: () => void;
    handleDownload?: (key: string, id: string) => void;
}
const Textes = {
    reports: "Reports",
    refresh: "Refresh Reports",
};


class ReportsTab extends React.PureComponent<ReportsTabProps, {}> {
    render() {
        return (
            <i18nContext.Consumer>
                {i18n =>
                    <div className="mt-2">
                        <button 
                        className="btn btn-secondary text-center ml-auto d-flex mr-2 mb-2 justify-content-center"
                         onClick={this.props.updateReports}
                         >
                            {i18n.t(Textes.refresh)}
                        </button>
                        <TabTable
                            {...this.props}
                            options={{
                                customRowRender: customRowRenderWithButtons(
                                    this.props.handleDownload,
                                    { download: "Download" }
                                ),
                                customSort: customSort,
                                search: false,
                                sortOrder: {
                                    name: 'created_at',
                                    direction: 'desc',
                                },
                            }}
                            customeHeaders={getReportsTableHeaders()}
                        />
                    </div>
                }
            </i18nContext.Consumer>
        )
    }
}
const mapStateToProps = (
    state: RootState,
    ownProps: ReportsTabProps
) => {
    const isLoaded = getDataIsLoaded(state);
    return {
        items: getReportsData(state),
        isLoaded: isLoaded && isLoaded.reports,
    }
};

const mapDispatchToProps = (
    dispatch: (action: RootAction) => void,
    ownProps: ReportsTabProps
) => {
    return {
        updateReports() {
            dispatch(resetReports());
            dispatch(loadReports());
        },
        handleDownload(key: string, id: string) {
            dispatch(downloadReportById(id))
        }
    };
};
export const ReportsTabContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ReportsTab);


export default ReportsTabContainer;