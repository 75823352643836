import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

/* Styles */
import './assets/sass/app.scss'; // Internal css

/* Main components */
import initStore from './store';
import { default as MainApp } from './components/main-app';

const { store, persistor } = initStore();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <MainApp />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
