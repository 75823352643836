import * as React from 'react';
import { FormBuilder, HandledFormProps, FormElements, ElementProps } from '../../../utils/form/form';
import { i18nContext, I18n } from '../../../../language';
import { FORM_CUSTOMER_SEARCH } from '../../../../data/constants';

const Textes = {
    searchPlaceholder: "Customer number / Company name",
    startSearch: "Start Search",
    title: "Customer ID",
    partners: "Partners",
};

export interface SearchFormProps extends HandledFormProps {
    selectedCustomer?: string;
    customersToSelect?: [{ value: string, text: string }];
    readonly?: boolean;
    title?: string;
    field?: string;
    columnView?: boolean;

    setRefToFocus?: (ref: HTMLInputElement) => void;
}

const CustomerSearchForm = FormBuilder.form<SearchFormProps>(
    (props: SearchFormProps) => {
        const title = props.title || Textes.title;
        return (
            <i18nContext.Consumer>
                {i18n => {
                    const inputField: ElementProps = {
                        name: props.field ? `customer_search-${props.field}` : "customer_search",
                        type: 'text',
                        className: 'w-100 widest-input customer-search px-2',
                        required: true,
                        disabled: props.readonly,
                        placeholder: i18n.t(Textes.searchPlaceholder),
                        classInputWrapper: props.columnView ? 'w-100' : 'col-7 pl-0 pr-2',
                    };
                    const customerSelectField: ElementProps = {
                        name: 'additional_sale_customer_dropdown_select',
                        label: i18n.t('Test'),
                        type: 'select',
                        disabled: props.readonly,
                        options: props.customersToSelect,
                    };
                    if (props.setRefToFocus) {
                        inputField.ref = props.setRefToFocus;
                    }
                    return (
                        <div>
                            <h5>{i18n.t(title)}</h5>
                            <div className={`customer-search d-flex p-0 align-items-center ${props.columnView ? 'flex-column' : 'flex-row '}`}>
                                {FormElements.input(inputField)}
                                {FormElements.button({
                                    value: i18n.t(Textes.startSearch),
                                    className: `btn btn-secondary mx-auto py-1 ${props.columnView ? 'w-100 mt-3' : ' col-5 '}`,
                                    type: 'submit',
                                    disabled: props.readonly,
                                })}
                            </div>
                            {(props.customersToSelect && props.customersToSelect.length > 1) && (
                                <div className='d-flex mt-2'>
                                    <div className='left-side d-flex align-items-center col-2 p-0'>
                                        <label>{i18n.t(Textes.partners)}</label>
                                    </div>
                                    <div className='right-side col-10 pr-0'>
                                        {FormElements.select(customerSelectField)}
                                    </div>
                                </div>
                            )}
                            {props.selectedCustomer && (
                                <div>
                                    {`${i18n.t('Selected')} ${i18n.t(title)}: ${props.selectedCustomer}`}
                                </div>
                            )}
                        </div>
                    )
                }}
            </i18nContext.Consumer>
        )
    },
    FORM_CUSTOMER_SEARCH
);

export default CustomerSearchForm;