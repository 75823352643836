import { action } from 'typesafe-actions';
import {
    APP_GET_INIT,
    APP_CHANGE_LANGUAGE,
    APP_SET_INIT, APP_SET_ERROR_MESSAGE,
    APP_SET_SUCCESS_MESSAGE,
    APP_HIDE_NOTIFICATION_MESSAGE,
    APP_SET_INFO_MESSAGE,
    APP_LOG_ERROR_MESSAGE,
    APP_UPDATE_LIMITED_VERSION,
    SET_SELECTED_TAB
} from '../data/constants';

/**
 * Application get initialise
 *
 * @export
 * @returns
 */
export const appGetInit = () => action(APP_GET_INIT);

export const appSetInit = () => action(APP_SET_INIT);

export const changeLanguage = (language: string) =>
    action(APP_CHANGE_LANGUAGE, { language });

export const setErrorMessage = (message: string) =>
    action(APP_SET_ERROR_MESSAGE, { message });

export const setSuccessMessage = (type: string) =>
    action(APP_SET_SUCCESS_MESSAGE, { type });

export const setInfoMessage = (type: string) =>
    action(APP_SET_INFO_MESSAGE, { type });

export const hideNotificationMessage = (id: string) =>
    action(APP_HIDE_NOTIFICATION_MESSAGE, { id });

export const logErrorMessage = (message: string) =>
    action(APP_LOG_ERROR_MESSAGE, { message }) 

export const changeTab = (name: string) => 
        action(SET_SELECTED_TAB, { name });

export const updateAppLimitedVersion = (isLimited: boolean) =>
        action(APP_UPDATE_LIMITED_VERSION, { isLimited });
