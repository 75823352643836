import * as React from 'react';
import { createRequestStatusReset, resetOrdersData } from '../../../actions/data';
import { connect } from 'react-redux';
import { RootState, RootAction } from '../../../store/types';
import { getRequestStatus, getSelectedSapItems } from '../../../selectors';
import { PopupContainer } from './popup-container';
import { i18nContext } from '../../../language';
import { resetSapItems, setActiveSapTable } from '../../../actions/trading';
import { SapTables } from '../../../reducers/trading';

const Textes = {
    close: "Close",
};

interface CreateOrderPopupProps {
    title?: string;
    requestStatus?: string;
    showPopup?: boolean;
    allowToCreate?: boolean;

    submitTitle?: string;
    content?: any;

    clearData?: () => void;
    clearStatus?: () => void;
    closePopup?: () => void;
    submitRequest?: () => void,
}

class CreateOrderPopup extends React.Component<CreateOrderPopupProps, {}> {
    state = {
        requestSent: false,
    };

    constructor(props: CreateOrderPopupProps) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handeClosePopup = this.handeClosePopup.bind(this);
    }

    handleSubmit() {
        this.setState({ requestSent: true });
        this.props.submitRequest();
    }
    handeClosePopup() {
        this.props.closePopup();
        this.props.clearData();
        this.props.clearStatus();
    }
    componentDidUpdate() {
        if (this.state.requestSent && this.props.requestStatus) {
            if (this.props.requestStatus === "OK") {
                this.handeClosePopup();
            }
            if (this.props.requestStatus === "ERROR") {
                this.props.clearStatus();
            }
            this.setState({ requestSent: false });
        }
    }

    render() {
        return (
            <PopupContainer id="invoiceable-popup"
                showPopup={this.props.showPopup}
                closePopup={this.handeClosePopup}
                inLoad={this.state.requestSent}
            >
            {this.props.showPopup &&
                <i18nContext.Consumer>
                    {i18n =>
                        <div className={[
                            "card bg-light w-100 d-flex flex-column",
                            "protectable",
                            this.state.requestSent ? "protectable--protected" : ""
                        ].join(' ')}>
                            <div className="card-header">
                                <h2 className="pl-3 mt-2" >{this.props.title}</h2>
                            </div>
                            {this.props.content}
                            <div className="d-flex p-3 justify-content-end detail-footer">
                                <div
                                    className={[
                                        "mr-3",
                                        "p-0",
                                        "protectable",
                                        !this.props.allowToCreate ? "protectable--protected" : ""
                                    ].join(' ')}
                                >
                                    <button
                                        className="w-100 btn btn-secondary text-center"
                                        onClick={this.handleSubmit}>
                                        {this.props.submitTitle}
                                    </button>
                                </div>
                                <button className="btn btn-secondary text-center" onClick={this.handeClosePopup}>
                                    {i18n.t(Textes.close)}
                                </button>
                            </div>
                        </div>
                    }
                </i18nContext.Consumer>
            }
            </PopupContainer>
        )
    }
}

const mapStateToProps = (
    state: RootState,
    ownProps: CreateOrderPopupProps
) => {
    const selected = getSelectedSapItems(state);
    return {
        requestStatus: getRequestStatus(state),
        allowToCreate: selected && selected.length > 0,
    }
};
const mapDispatchToProps = (
    dispatch: (action: RootAction) => void,
    ownProps: CreateOrderPopupProps
) => {
    return {
        clearStatus() {
            dispatch(createRequestStatusReset());
        },
        clearData() {
            dispatch(setActiveSapTable(SapTables.purchase));
            dispatch(resetSapItems());
            dispatch(resetOrdersData());
        },
    };
};

export const CreateOrderPopupContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateOrderPopup);

export default CreateOrderPopupContainer;