import * as React from 'react';
import { clearOrderData, createRequestStatusReset } from '../../../actions/data';
import { connect } from 'react-redux';
import { RootState, RootAction } from '../../../store/types';
import { getRequestStatus } from '../../../selectors';
import { PopupContainer } from './popup-container';
import { clearSearch } from '../../../actions/search';
import MaterialForm from '../forms/material-form';

const SEARCH_TYPE = "IN_ORDER";

interface MaterialPopupProps {
    title?: string;
    requestStatus?: string;
    showPopup?: boolean;
    isEditing?: boolean;

    clearData?: () => void;
    clearStatus?: () => void;
    closePopup?: () => void;
    submitRequest?: () => void;
    updateData?: () => void;
}

class MaterialPopup extends React.Component<MaterialPopupProps, {}> {
    state = {
        requestSent: false,
    };

    constructor(props: MaterialPopupProps) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handeClosePopup = this.handeClosePopup.bind(this);
    }

    handleSubmit() {
        this.setState({ requestSent: true });
        this.props.submitRequest();
    }
    handeClosePopup() {
        this.props.closePopup();
        this.props.clearData();
        this.props.clearStatus();
    }
    componentDidUpdate() {
        if (this.state.requestSent && this.props.requestStatus) {
            if (this.props.requestStatus === "OK") {
                this.handeClosePopup();
            }
            if (this.props.requestStatus === "ERROR") {
                this.props.clearStatus();
            }
            this.setState({ requestSent: false });
        }
    }

    render() {
        return (
            <PopupContainer
                id="order-popup"
                showPopup={this.props.showPopup}
                closePopup={this.handeClosePopup}
                inLoad={this.state.requestSent}
            >
                {this.props.showPopup &&
                    <div className={[
                        "card bg-light w-100 d-flex flex-column",
                        "protectable",
                        this.state.requestSent ? "protectable--protected" : ""
                    ].join(' ')}>
                        <div className="card-header">
                            <h2 className="pl-3 mt-2" >{this.props.title}</h2>
                        </div>
                        <MaterialForm
                            className="p-3"
                            readonly={!!this.props.isEditing}
                            onCansel={this.handeClosePopup}
                            onSubmit={this.handleSubmit}
                            discardChanges={this.props.updateData}
                        />
                    </div>
                }
            </PopupContainer>
        )
    }
}

const mapStateToProps = (
    state: RootState,
    ownProps: MaterialPopupProps
) => {
    return {
        requestStatus: getRequestStatus(state),
    }
};
const mapDispatchToProps = (
    dispatch: (action: RootAction) => void,
    ownProps: MaterialPopupProps
) => {
    return {
        clearStatus() {
            dispatch(createRequestStatusReset());
        },
        clearData() {
            dispatch(clearOrderData());
            dispatch(clearSearch(SEARCH_TYPE));
        },
    };
};

export const MaterialPopupContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(MaterialPopup);

export default MaterialPopupContainer;