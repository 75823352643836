import * as React from 'react';
import { PopupContainer } from './popup-container';
import { i18nContext } from '../../../language';
import HistoryBar from '../tabs/history';

const Textes = {
    title: "History",
    close: "Close",
    refresh: "Refresh History",
};

interface HistoryPopupPopupProps {
    showPopup?: boolean;
    hideUsers?: boolean;

    loadHistory?: () => void;
    closePopup?: () => void;
}

class HistoryPopup extends React.PureComponent<HistoryPopupPopupProps, {}> {
    render() {
        return (
            <PopupContainer id="history-popup"
                showPopup={this.props.showPopup}
                closePopup={this.props.closePopup}
            >
                {this.props.showPopup &&
                    <i18nContext.Consumer>
                        {i18n =>
                            <div className="card bg-light w-100 d-flex flex-column">
                                <div className="card-header">
                                    <h2 className="pl-3 mt-2" >{i18n.t(Textes.title)}</h2>
                                </div>
                                <HistoryBar hideUsers={this.props.hideUsers} />
                                <div className="d-flex p-3 justify-content-end">
                                    <button className="btn btn-secondary text-center mr-3" onClick={this.props.loadHistory}>
                                        {i18n.t(Textes.refresh)}
                                    </button>
                                    <button className="btn btn-secondary text-center" onClick={this.props.closePopup}>
                                        {i18n.t(Textes.close)}
                                    </button>
                                </div>
                            </div>
                        }
                    </i18nContext.Consumer>}
            </PopupContainer>
        )
    }
}

export default HistoryPopup;