import { RootState } from '../store/types.d'

export const getSelectedLanguage = (state: RootState): string =>
    state.app.language;

export const getApplicationInit = (state: RootState): boolean =>
    state.app.isInit;

export const getNotificationMessages = (state: RootState) =>
    state.app.notificationMessages;

export const getLimitedVersion = (state: RootState) =>
    state.app.limitedVersion;
