import * as React from 'react';
import { Tabs, Tab } from 'react-bootstrap';

export const Navs = (props: {
  contents: Array<{
    name: string;
    key: string;
    content: JSX.Element;
  }>;
  defaultActiveKey?: string;
  activeKey?: string;
  onSelect?: (eventKey: string, e: React.SyntheticEvent<{}>) => void;
  mountOnEnter?: boolean;
  unmountOnExit?: boolean;
}) => (
    <Tabs
      activeKey={props.activeKey}
      defaultActiveKey={props.defaultActiveKey}
      id="nav-tab"
      onSelect={props.onSelect}
      mountOnEnter={props.mountOnEnter}
    >
      {props.contents.map((tab, i) => (
        <Tab key={i} eventKey={tab.key} href={'#' + tab.key} title={tab.name}>
          {tab.content}
        </Tab>
      ))}
    </Tabs>
  );
