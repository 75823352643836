import * as React from 'react';
import { FormBuilder, HandledFormProps } from '../../../utils/form/form';
import { i18nContext, I18n } from '../../../../language';
import { Data } from '../../../../reducers/data';
import OrderDataForm from '../order/order-data';
import DetailsShippingAddressForm from './shipping-address-form';

const Textes = {
    order_id: 'Order ID',
    external_ref_number: 'External Ref Number',
    sap_order_id: 'sap_order_id',
    customer_sap_id: 'customer_sap_id',
    customer: 'Customer',
    order_date: 'order_date',
    created_by: 'created_by',
    status: 'Order Status',
    active: 'Active',
    deleted: 'Deleted'
};

interface DetailsOrderFormProps extends HandledFormProps {
    data: Data;
    editable?: boolean;
    isPurchase?: boolean;
    showDetails?: boolean;
    showSalesShippingAddressFields?: boolean;
}

const DetailsOrderForm = (props: DetailsOrderFormProps) => (
    <i18nContext.Consumer>
        {i18n => {
            return (
                <div className="d-flex flex-row">
                    <div className="col-4 py-3 pl-3 pr-0 d-flex flex-column">
                        <div className="d-flex flex-row justify-content-between px-2 pb-2">
                            <div>{`${i18n.t(Textes.order_id)}:`}</div>
                            <div className="text-right">{props.data.id}</div>
                        </div>
                        <div className="d-flex flex-row justify-content-between px-2 pb-2">
                            <div>{`${i18n.t(Textes.external_ref_number)}:`}</div>
                            <div className="text-right">{props.data.external_ref_number}</div>
                        </div>
                        <div className="d-flex flex-row justify-content-between px-2 pb-2">
                            <div>{`${i18n.t(Textes.sap_order_id)}:`}</div>
                            <div className="text-right">{props.data.sap_order_id}</div>
                        </div>
                        <div className="d-flex flex-row justify-content-between px-2 pb-2">
                            <div>{`${i18n.t(Textes.customer)}:`}</div>
                            <div className="text-right">{props.data.customer_sap_id + ' ' + props.data.primary_name}</div>
                        </div>
                        <div className="d-flex flex-row justify-content-between px-2 pb-2">
                            <div>{`${i18n.t(Textes.customer_sap_id)}:`}</div>
                            <div className="text-right">{props.data.customer_sap_id}</div>
                        </div>
                        <div className="d-flex flex-row justify-content-between px-2 pb-2">
                            <div>{`${i18n.t(Textes.created_by)}:`}</div>
                            <div className="text-right">{props.data.created_by}</div>
                        </div>
                        <div className="d-flex flex-row justify-content-between px-2">
                            <div>{`${i18n.t(Textes.status)}:`}</div>
                            <div className="text-right">{props.data.canceled ? i18n.t(Textes.deleted) : i18n.t(Textes.active)}</div>
                        </div>
                    </div>
                    <div className="col-8">
                        <OrderDataForm
                            className="p-0"
                            readonly={!props.editable}
                            isPurchase={props.isPurchase} 
                            showDetails={props.showDetails}
                        />
                        {props.showSalesShippingAddressFields && <DetailsShippingAddressForm
                            readonly={!props.editable}
                        />}
                    </div>
                </div>
            )
        }}
    </i18nContext.Consumer>
)

export default DetailsOrderForm;