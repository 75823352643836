import * as React from 'react';
import { i18nContext } from '../../../language';
import { RootState, RootAction } from '../../../store/types';
import { connect } from 'react-redux';
import { getUserData, getDeliveryNoteFeatureAvailability } from '../../../selectors';
import { UserPermissions } from '../../../models/user-permissions';
import { PopupButton } from '../popup/popup-button';
import { createPurchase, createSales, exportData, loadPurchaseOrders, loadSalesOrders, loadSapOrders, loadBillSapOrders, loadDeliveringSapOrders } from '../../../actions/data';
import { NavDropdown } from 'react-bootstrap';
import { componentWillAppendToBody } from 'react-append-to-body';
import { default as OrderPopup } from '../popup/create-order-popup';
import { default as InvOrderPopup } from '../popup/sap-popup';
import { selectAllSapActive, sentToSap, billingToSap, deliveringToSAP } from '../../../actions/trading';
import InvoiceableBar from '../tabs/invoiceable';
import BillingBar from '../tabs/billing';
import DeliveringBar from '../tabs/delivering';
import { FORM_ORDER_DATA, FORM_BILLING_DATA } from '../../../data/constants';
import { FormBuilder } from '../../utils/form/form';
import { orderFieldNames, billingFieldNames } from '../../../reducers/form';
import { format } from 'date-fns'
import { purchaseOrderTypeDefaultOption, salesOrderTypeDefaultOption } from '../popup/order/data';
import { KEY_CODE_A, KEY_CODE_B, KEY_CODE_D, KEY_CODE_V, KEY_CODE_I } from '../../../services/input';

const Textes = {
    sap: "SAP",
    export: "Export",
    purchase: "New Purchase",
    sale: "New Sale",
    invoiceable_orders: "Invoiceable orders",
    billing_orders: "Billing orders to SAP",
    delivery_note_orders: "Orders delivery note creation",
    subminInv: "Generate order in SAP",
    subminBilling: "Billing order in SAP",
    submitDelivery: "Create order delivery note in SAP",
    exportSales: "Export Sales",
    exportPurchases: "Export Purchases",
    exportMatches: "Export Matches",
    exportInventory: "Export Inventory",
    exportPnL: "Export Profit And Losses",
    exportMixed: "Export Mixed Orders",
    exportMixedWithMatch: "Export Mixed Orders With Matched Weight",
    exportMixedNeedsMatch: "Export Mixed Orders Which Require Matching",
};

const ExportTypes = {
    exportSales: "exportSales",
    exportPurchases: "exportPurchases",
    exportMatches: "exportMatches",
    exportInventory: "exportInventory",
    exportPnL: "exportPnL",
    exportMixed: "exportMixed",
    exportMixedWithMatch: "exportMixedWithMatch",
    exportMixedNeedsMatch: "exportMixedNeedsMatch",
}

interface ButtonsHeaderProps {
    userData?: {
        user: string;
        role: string;
        permissions: UserPermissions;
    };
    deliveryNoteIsEnabled?: boolean;
    submitNewPurchase?: () => void;
    submitNewSales?: () => void;
    submitExport?: (type: string, lng: string) => void;
    submitSapRequest?: () => void;
    submitBillingRequest?: (lng: string) => void;
    submitDeliveringRequest?: (lng: string) => void;
    onInvPopupOpen?: () => void;
    onPurchaseOrderPopupOpen?: () => void;
    onSalesOrderPopupOpen?: () => void;
    onBillPopupOpen?: () => void;
    onDeliveryPopupOpen?: () => void;
}

const AppendedOrderPopup = componentWillAppendToBody(OrderPopup);

const AppendedInvOrderPopup = componentWillAppendToBody(InvOrderPopup);

const renderToggleButton = (props: { title: string }) => (
    togglePopup: () => void
) => {
    return (
        <button
            key={`button-${props.title}`}
            className="d-flex align-items-center w-100 mx-1 btn btn-secondary"
            onClick={togglePopup}
        >
            {props.title}
        </button>
    );
};


const renderToggleDropdownButton = (props: { title: string }) => (
    togglePopup: () => void
) => {
    return (
        <NavDropdown.Item
            key={`button-${props.title}`}
            onClick={togglePopup}
        >
            {props.title}
        </NavDropdown.Item>
    );
};

class ButtonsHeader extends React.PureComponent<ButtonsHeaderProps, {}> {
    render() {
        return (
            <i18nContext.Consumer>
                {i18n => {
                    const getSapInteractionPopups = () => {
                        return [this.props.deliveryNoteIsEnabled && {
                            content: <DeliveringBar />,
                            title: i18n.t(Textes.delivery_note_orders),
                            eventKey: KEY_CODE_D,
                            submitRequest: () => this.props.submitDeliveringRequest(i18n.language),
                            submitTitle: i18n.t(Textes.submitDelivery),
                            onPopupOpen: this.props.onDeliveryPopupOpen,
                        }, {
                            content: <InvoiceableBar />,
                            title: i18n.t(Textes.invoiceable_orders),
                            eventKey: KEY_CODE_I,
                            submitRequest: this.props.submitSapRequest,
                            onPopupOpen: this.props.onInvPopupOpen,
                            submitTitle: i18n.t(Textes.subminInv)
                        }, {
                            content: <BillingBar />,
                            title: i18n.t(Textes.billing_orders),
                            eventKey: KEY_CODE_B,
                            submitRequest: () => this.props.submitBillingRequest(i18n.language),
                            submitTitle: i18n.t(Textes.subminBilling),
                            onPopupOpen: this.props.onBillPopupOpen,
                        }].filter(popup => Boolean(popup));
                    };

                    return <div key="buttons" className="col-7 mt-3 d-flex flex-row">
                        <PopupButton
                            appendedPopup={AppendedOrderPopup}
                            renderToggleButton={renderToggleButton}
                            popups={[{
                                title: i18n.t(Textes.purchase),
                                eventKey: KEY_CODE_A,
                                isPurchase: true,
                                submitRequest: this.props.submitNewPurchase,
                                onPopupOpen: this.props.onPurchaseOrderPopupOpen
                            }, {
                                title: i18n.t(Textes.sale),
                                eventKey: KEY_CODE_V,
                                submitRequest: this.props.submitNewSales,
                                onPopupOpen: this.props.onSalesOrderPopupOpen
                            }]}
                        />
                        {this.props.userData.permissions.exports ?
                            <NavDropdown className="d-flex w-100 mx-1 btn btn-secondary dropdown-btn" title={i18n.t(Textes.export)} id="export-dropdown">
                                <NavDropdown.Item onClick={() => this.props.submitExport(ExportTypes.exportPurchases, i18n.language)}>
                                    {i18n.t(Textes.exportPurchases)}
                                </NavDropdown.Item>
                                <NavDropdown.Item onClick={() => this.props.submitExport(ExportTypes.exportSales, i18n.language)}>
                                    {i18n.t(Textes.exportSales)}
                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item onClick={() => this.props.submitExport(ExportTypes.exportMatches, i18n.language)}>
                                    {i18n.t(Textes.exportMatches)}
                                </NavDropdown.Item>
                                <NavDropdown.Item onClick={() => this.props.submitExport(ExportTypes.exportInventory, i18n.language)}>
                                    {i18n.t(Textes.exportInventory)}
                                </NavDropdown.Item>
                                <NavDropdown.Item onClick={() => this.props.submitExport(ExportTypes.exportPnL, i18n.language)}>
                                    {i18n.t(Textes.exportPnL)}
                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item onClick={() => this.props.submitExport(ExportTypes.exportMixed, i18n.language)}>
                                    {i18n.t(Textes.exportMixed)}
                                </NavDropdown.Item>
                                <NavDropdown.Item onClick={() => this.props.submitExport(ExportTypes.exportMixedWithMatch, i18n.language)}>
                                    {i18n.t(Textes.exportMixedWithMatch)}
                                </NavDropdown.Item>
                                <NavDropdown.Item onClick={() => this.props.submitExport(ExportTypes.exportMixedNeedsMatch, i18n.language)}>
                                    {i18n.t(Textes.exportMixedNeedsMatch)}
                                </NavDropdown.Item>
                            </NavDropdown> :
                            <div className="w-100"></div>
                        }
                        {this.props.userData.permissions.sap_interaction ?
                            <NavDropdown className="d-flex w-100 mx-1 btn btn-secondary dropdown-btn" title={i18n.t(Textes.sap)} id="sap-dropdown">
                                <PopupButton
                                    appendedPopup={AppendedInvOrderPopup}
                                    renderToggleButton={renderToggleDropdownButton}
                                    popups={getSapInteractionPopups()}
                                />
                            </NavDropdown> :
                            <div className="w-100"></div>
                        }
                    </div>
                    }
                }
            </i18nContext.Consumer>
        )
    }
}

const orderFormCotnrolls = FormBuilder.formActions(FORM_ORDER_DATA);
const getOrderDateSettingAction = () => orderFormCotnrolls.setValue(orderFieldNames.order_date, format(new Date(), "YYYY-MM-DD"));
const getOrderTypeSettingAction = (type: string) => orderFormCotnrolls.setValue(orderFieldNames.order_type, type);

const billingFormControlls = FormBuilder.formActions(FORM_BILLING_DATA);
const getInvoiceDateSettingAction = () => billingFormControlls.setValue(billingFieldNames.invoice_date, format(new Date(), "YYYY-MM-DD"));

const mapStateToProps = (
    state: RootState,
    ownProps: ButtonsHeaderProps
) => {
    return {
        userData: getUserData(state),
        deliveryNoteIsEnabled: getDeliveryNoteFeatureAvailability(state),
    }
};
const mapDispatchToProps = (
    dispatch: (action: RootAction) => void,
    ownProps: ButtonsHeaderProps
) => {
    return {
        submitNewPurchase() {
            dispatch(createPurchase());
        },
        submitNewSales(id: string, notes: string, positions: Array<{}>) {
            dispatch(createSales());
        },
        submitExport(type: string, lng: string) {
            dispatch(exportData(type, lng));
        },
        submitSapRequest() {
            dispatch(sentToSap());
        },
        submitBillingRequest(lng: string) {
            dispatch(billingToSap(lng));
        },
        submitDeliveringRequest(lng: string) {
            dispatch(deliveringToSAP(lng));
        },
        onInvPopupOpen() {
            dispatch(loadSapOrders());
        },
        onBillPopupOpen() {
            dispatch(loadBillSapOrders());
            dispatch(getInvoiceDateSettingAction());
        },
        onDeliveryPopupOpen() {
            dispatch(loadDeliveringSapOrders());
            dispatch(getInvoiceDateSettingAction());
        },
        onSalesOrderPopupOpen() {
            dispatch(getOrderDateSettingAction());
            dispatch(getOrderTypeSettingAction(salesOrderTypeDefaultOption));
        },
        onPurchaseOrderPopupOpen() {
            dispatch(getOrderDateSettingAction());
            dispatch(getOrderTypeSettingAction(purchaseOrderTypeDefaultOption));
        }
    };
};

const ButtonsHeaderContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ButtonsHeader);

export default ButtonsHeaderContainer;