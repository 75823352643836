import * as React from 'react';
import { TabTableProps, TabTable } from '../../tables/tabtable';
import { i18nContext } from '../../../../language';
import { RootState, RootAction } from '../../../../store/types';
import { getLogsData } from '../../../../selectors';
import { connect } from 'react-redux';
import { customRowRenderWithButtons, customSort, getLogsTableHeaders } from '../../tables/tabtable-components';
import ModalPopup from '../../popup/modal-popup';
import { loadLogs, resetLogs } from '../../../../actions/data';
import { getDataIsLoaded } from '../../../../selectors/data';

export interface LogsTabProps extends TabTableProps {
    updateLogs?: () => void;
}
const Textes = {
    logs: "Logs",
    cancel: "Close",
    refresh: "Refresh Logs",
};


class LogsTab extends React.PureComponent<LogsTabProps, {}> {
    logKey: string;
    logId: string;
    state = {
        showPopup: false,
    }
    constructor(props: LogsTabProps) {
        super(props);
        this.onTogglePopup = this.onTogglePopup.bind(this);
    }
    onTogglePopup(key?: string, id?: string) {
        this.logId = id;
        this.logKey = key;
        this.setState({
            showPopup: !this.state.showPopup,
        });
    }
    render() {
        return (
            <i18nContext.Consumer>
                {i18n =>
                    <div className="mt-2">
                        <button
                            className="btn btn-secondary text-center ml-auto d-flex mr-2 mb-2 justify-content-center"
                            onClick={this.props.updateLogs}
                        >
                            {i18n.t(Textes.refresh)}
                        </button>
                        <TabTable
                            {...this.props}
                            options={{
                                customRowRender: customRowRenderWithButtons(
                                    this.onTogglePopup,
                                    { request: "View Request", response: "View Response" }
                                ),
                                customSort: customSort,
                                search: false,
                                sortOrder: {
                                    name: 'created_at',
                                    direction: 'desc',
                                },
                            }}
                            customeHeaders={getLogsTableHeaders()}
                        />
                        <ModalPopup
                            contentClassName="log-modal-content"
                            closePopup={this.onTogglePopup}
                            showModal={this.state.showPopup}
                            textes={{
                                title: i18n.t(Textes.logs),
                                cancel: i18n.t(Textes.cancel),
                                text: this.props.items && this.logId && this.props.items[this.logId] &&
                                    <textarea className="w-100 h-100" readOnly>
                                        {Object.values(this.props.items[this.logId][this.logKey]).pop()}
                                    </textarea>
                            }}
                        />
                    </div>
                }
            </i18nContext.Consumer>
        )
    }
}
const mapStateToProps = (
    state: RootState,
    ownProps: LogsTabProps
) => {
    const isLoaded = getDataIsLoaded(state);
    return {
        items: getLogsData(state),
        isLoaded: isLoaded && isLoaded.logs,
    }
};

const mapDispatchToProps = (
    dispatch: (action: RootAction) => void,
    ownProps: LogsTabProps
) => {
    return {
        updateLogs() {
            dispatch(resetLogs());
            dispatch(loadLogs());
        },
    };
};
export const LogsTabContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(LogsTab);


export default LogsTabContainer;