import * as React from 'react';
import {
  Field,
  reduxForm,
  change,
  BaseFieldProps,
  GenericFieldHTMLAttributes,
  WrappedFieldProps,
} from 'redux-form';
import { SelectInput } from './select';
import { ColumnSizes, sizesToClass } from './types';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils from 'react-day-picker/moment';
import { format } from 'date-fns'
import NumberFormat from 'react-number-format';
import { FocusInput } from './ref-input';
export interface ElementProps extends React.HTMLProps<any> {
  postfix?: string;
  classInputWrapper?: string;
  postfixclassname?: string;
  prefix?: string;
  prefixclassname?: string;
  placeholder?: string;
  wrapperClass?: string;
  options?: Array<{
    text?: string;
    value: string | number;
    props?: React.HTMLProps<any>;
  }>;
  type?: string;
  marks?: { [i: string]: string };
  noStep?: boolean;
  step?: string;
  autoFocus?: boolean;
  active?: boolean;
  readonly?: boolean;
  locale?: string;
  maxLength?: number;
  thousandSeparator?: string | boolean;
  decimalSeparator?: string;
  allowNegative?: boolean;
  allowEmptyFormatting?: boolean;
  suffix?: string;
  isAllowed?: (value: {formattedValue: string, value: string, floatValue: number}) => boolean;
  normalize?: (value: number) => number;
  validate?: (value: any, allValues: any, props: any, name: any) => string;
}
export interface SelectOption {
  text: string;
  value: string | number;
}

export interface ElementsVisibility {
  [index: string]: boolean;
}
export const PopoverError = (props: {
  children: string | JSX.Element;
  className: string;
}) => (
    <div
      className={[props.className, 'popover fade show bs-popover-bottom']
        .join(' ')
        .trim()}
      role="tooltip"
      x-placement="bottom"
    >
      <div className="arrow" />
      <h3 className="popover-header" />
      <div className="popover-body">{props.children}</div>
    </div>
  );
  const renderField = (
    props: GenericFieldHTMLAttributes | WrappedFieldProps | BaseFieldProps | any
  ) => {
    const {
      input,
      label,
      className,
      classInputWrapper,
      type,
      meta: { touched, error, warning },
    } = props;
    return (
      <div
        className={[
          classInputWrapper || 'w-100',
          'input-wrapper',
          error ? 'input-has-errors' : '',
          warning ? 'input-has-warnings' : '',
        ]
          .join(' ')
          .trim()}
      >
        <input
          key="input"
          {...props}
          {...input}
          className={[className].join(' ').trim()}
          placeholder={props.placeholder || label || ''}
          type={type}
        />
        {touched &&
          ((error && (
            <PopoverError key="error" className="validation-error">
              {error}
            </PopoverError>
          )) ||
            (warning && (
              <PopoverError key="warning" className="validation-warning">
                {warning}
              </PopoverError>
            )))}
      </div>
    );
  };
const renderNumberField = (
  props: GenericFieldHTMLAttributes | WrappedFieldProps | BaseFieldProps | any
) => {
  const {
    input,
    label,
    className,
    classInputWrapper,
    type,
    meta: { touched, error, warning },
  } = props;
  return (
    <div
      className={[
        classInputWrapper || 'w-100',
        'input-wrapper',
        error ? 'input-has-errors' : '',
        warning ? 'input-has-warnings' : '',
      ]
        .join(' ')
        .trim()}
    >
      <NumberFormat
        key="input"
        {...props}
        {...input}
        className={[className].join(' ').trim()}
        placeholder={props.placeholder || label || ''}
      />
      {touched &&
        ((error && (
          <PopoverError key="error" className="validation-error">
            {error}
          </PopoverError>
        )) ||
          (warning && (
            <PopoverError key="warning" className="validation-warning">
              {warning}
            </PopoverError>
          )))}
    </div>
  );
};
const renderDateField = (
  props: GenericFieldHTMLAttributes | WrappedFieldProps | BaseFieldProps | any
) => {
  const {
    input,
    label,
    className,
    classInputWrapper,
    type,
    meta: { touched, error, warning },
  } = props;

  return (
    <div
      className={[
        classInputWrapper || 'w-100',
        'input-wrapper',
        error ? 'input-has-errors' : '',
        warning ? 'input-has-warnings' : '',
      ]
        .join(' ')
        .trim()}
    >
      <DayPickerInput
        key="date"
        format="YYYY-MM-DD"
        formatDate={(date: any, dateFormat: any, locale: any) => format(date, dateFormat)}
        inputProps={{ ...input, ...props }}
        onDayChange={input.onChange}
        dayPickerProps={{
          locale: props.locale,
          localeUtils: MomentLocaleUtils,
        }}
        value={input && input.value || ""}
        placeholder={"YYYY-MM-DD"}
      />
      {touched &&
        ((error && (
          <PopoverError key="error" className="validation-error">
            {error}
          </PopoverError>
        )) ||
          (warning && (
            <PopoverError key="warning" className="validation-warning">
              {warning}
            </PopoverError>
          )))}
    </div>
  );
};

const renderToggleField = (
  props: GenericFieldHTMLAttributes | WrappedFieldProps | BaseFieldProps | any
) => {
  const {
    input,
    label,
    className,
    classInputWrapper,
    type,
    meta: { touched, error, warning },
  } = props;

  return (

    <div
      className={[
        classInputWrapper || 'w-100',
        'input-wrapper',
        'justify-content-center',
        error ? 'input-has-errors' : '',
        warning ? 'input-has-warnings' : '',
      ]
        .join(' ')
        .trim()}
    >
      <label className="switch">
        <input {...{ ...input, ...props }} type="checkbox" checked={input && input.value} />
        <div className="slider"></div>
      </label>
    </div>
  )
}
const inputBuilderWithRef = (props: ElementProps = {}) => {
  const defaultClasses =
    props.type === 'range'
      ? 'd-inline-block'
      : 'form-control form-control-sm configurator__form-control configurator__form-control--fixed d-inline-block';
  const component = FocusInput;
  return (
    <Field
      {...props}
      component={component}
      key="input"
      className={[props.className, defaultClasses].join(' ').trim()}
      type={props.type ? props.type : 'text'}
      name={props.name}
      value={props.value}
      forwardRef={props.ref}
      withRef
    />
  );
};
const inputBuilder = (props: ElementProps = {}) => {
  const defaultClasses =
    props.type === 'range'
      ? 'd-inline-block'
      : 'form-control form-control-sm configurator__form-control configurator__form-control--fixed d-inline-block';
  const component = renderField;
  return (
    <Field
      {...props}
      component={component}
      key="input"
      className={[props.className, defaultClasses].join(' ').trim()}
      type={props.type ? props.type : 'text'}
      name={props.name}
      value={props.value}
    />
  );
};
const numberInputBuilder = (props: ElementProps = {}) => {
  const defaultClasses =
    props.type === 'range'
      ? 'd-inline-block'
      : 'form-control form-control-sm configurator__form-control configurator__form-control--fixed d-inline-block';
  const component = renderNumberField;
  return (
    <Field
      {...props}
      component={component}
      key="input"
      className={[props.className, defaultClasses].join(' ').trim()}
      type={props.type ? props.type : 'text'}
      name={props.name}
      value={props.value}
    />
  );
};
const dateInputBuilder = (props: ElementProps = {}) => {
  const component = renderDateField;
  return (
    <Field
      {...props}
      component={component}
      key="input"
      className={[props.className].join(' ').trim()}
      type={props.type ? props.type : 'text'}
      name={props.name}
      value={props.value}
    />
  );
};
const toggleInputBuilder = (props: ElementProps = {}) => {
  const component = renderToggleField;
  return (
    <Field
      {...props}
      component={component}
      key="input"
      className={[props.className].join(' ').trim()}
      type={props.type ? props.type : 'text'}
      name={props.name}
      value={props.value}
    />
  );
};
/**
 * Quick element constructor
 */
export const FormElements = {
  /**
   *
   * Returns <input> element for the form
   *
   * @param {ElementProps} props
   * @memberof FormElements
   */
  input: (props: ElementProps = {}): JSX.Element => {
    return (
      <React.Fragment key={props.name}>
        {props.prefix ? (
          <div
            key="prefix"
            className={[
              'text-small configurator__mini-unit',
              props.prefixclassname ? props.prefixclassname : '',
            ]
              .join(' ')
              .trim()}
          >
            {props.prefix}
          </div>
        ) : null}
        {!props.ref ? inputBuilder({ ...props, prefix: null, postfix: null }) : inputBuilderWithRef({ ...props, prefix: null, postfix: null })}
        {!props.postfix ? null : String(props.postfix).trim() === '' ? (
          <div
            key="postfix"
            className={[
              'text-small configurator__mini-unit invisible',
              props.postfixclassname ? props.postfixclassname : '',
            ]
              .join(' ')
              .trim()}
          />
        ) : (
            <div
              key="postfix"
              className={[
                'text-small configurator__mini-unit',
                props.postfixclassname ? props.postfixclassname : '',
              ]
                .join(' ')
                .trim()}
            >
              {props.postfix}
            </div>
          )}
      </React.Fragment>
    );
  },
  /**
   *
   * Returns <select> element for the form
   *
   * @param {ElementProps} props
   * @memberof FormElements
   */
  select: (props: ElementProps): JSX.Element => {
    return <SelectInput key={props.name} {...props} />;
  },
  number: (props: ElementProps): JSX.Element => {
      return (
      <React.Fragment key={props.name}>
        {numberInputBuilder({ ...props })}
        {!props.postfix ? null : String(props.postfix).trim() === '' ? (
          <div
            key="postfix"
            className={[
              'text-small configurator__mini-unit invisible',
              props.postfixclassname ? props.postfixclassname : '',
            ]
              .join(' ')
              .trim()}
          />
        ) : (
            <div
              key="postfix"
              className={[
                'text-small configurator__mini-unit',
                props.postfixclassname ? props.postfixclassname : '',
              ]
                .join(' ')
                .trim()}
            >
              {props.postfix}
            </div>
          )}
      </React.Fragment>
      )
  },
  date: (props: ElementProps): JSX.Element => {
    return (
      <React.Fragment key={props.name}>
        {dateInputBuilder({ ...props })}
      </React.Fragment>
    );
  },
  toggle: (props: ElementProps): JSX.Element => {
    return (
      <React.Fragment key={props.name}>
        {toggleInputBuilder({ ...props })}
      </React.Fragment>
    );
  },
  button: (props: ElementProps = {}): JSX.Element => {
    const { type, ...prop } = props;
    return <button {...prop}>{props.value}</button>;
  },
};
export const FormSeparator = (props: {
  className?: string;
  col?: ColumnSizes;
}) => {
  return (
    <div
      className={[sizesToClass(props.col), props.className, 'mb-3 mt-3'].join(
        ' '
      )}
    />
  );
};
export const FormGroupLabel = (props: {
  label?: JSX.Element | JSX.Element[] | string;
  hide?: boolean;
  invisible?: boolean;
  className?: string;
  col?: ColumnSizes;
}) => {
  return (
    <p
      className={[
        'configurator-form-group mb-0',
        props.className ? props.className : '',
        props.hide ? 'd-none' : '',
        props.invisible ? 'invisible' : '',
        // sizesToClass(props.col),
      ]
        .join(' ')
        .trim()}
    >
      {props.label}
    </p>
  );
};
interface FormGroupParams {
  label?: string | JSX.Element;
  hide?: boolean;
  invisible?: boolean;
  className?: string;
  classNameLeft?: string;
  classNameRight?: string;
  col?: ColumnSizes;
  wrap?: boolean;
  wrapClass?: string;
  required?: boolean;
}
export const FormGroup: React.SFC<FormGroupParams> = props => {
  const { children, label } = props;
  // const wide = Array.isArray(children);
  const noLabel = !label;
  return noLabel ? (
    <div
      className={[
        'configurator-form-group',
        props.className ? props.className : '',
        props.hide ? 'd-none' : '',
        props.invisible ? 'invisible' : '',
        // sizesToClass(props.col),
      ]
        .join(' ')
        .trim()}
    >
      <div className="row m-0">{children}</div>
    </div>
  ) : (
      <div
        className={[
          'configurator-form-group',
          props.className ? props.className : '',
          props.hide ? 'd-none' : '',
          props.invisible ? 'invisible' : '',
          // sizesToClass(props.col),
        ]
          .join(' ')
          .trim()}
      >
        <div
          className={[
            'self-align-row',
            props.wrap ? 'flex-wrap' : 'flex-nowrap',
            props.wrapClass,
          ]
            .filter(a => a)
            .join(' ')}
        >
          <div
            className={[
              'left-side',
              props.classNameLeft ? props.classNameLeft : '',
            ]
              .join(' ')
              .trim()}
          >
            <label>
              {label}
              {props.required && <sup className="requred-postfix">*</sup>}
            </label>
          </div>
          <div
            className={[
              'right-side',
              props.classNameRight ? props.classNameRight : '',
            ]
              .join(' ')
              .trim()}
          >
            {children}
          </div>
        </div>
      </div>
    );
};
export const FormTableCell: React.SFC<FormGroupParams> = props => {
  const { children } = props;
  return (
    <td
      className={[
        'configurator-form-group',
        props.className ? props.className : '',
      ]
        .join(' ')
        .trim()}
    >
      <div className="d-flex align-items-center">{children}</div>
    </td>
  )
};
export interface HandledFormProps {
  handleSubmit?: React.FormEventHandler;
  onSubmit?: React.FormEventHandler;
  onChange?: (values: { [index: string]: string | boolean | number }) => void;
  initialValues?: { [index: string]: string | boolean | number };
  className?: string;
}
export interface FormSimpleProps {
  [index: string]: string | boolean | number;
}
export interface FormGroupProps {
  separator?: boolean;
  className?: string;
  classNameRight?: string;
  classNameLeft?: string;
  wrapClass?: string;
  groupLabel?: JSX.Element | JSX.Element[] | string;
  label?: string | JSX.Element;
  input?: JSX.Element[] | JSX.Element;
  hide?: boolean;
  invisible?: boolean;
  wrap?: boolean;
  col?: ColumnSizes;
  required?: boolean;
  tableCell?: boolean;
}
/**
 * Class provides easy way to build forms and form components
 *
 * @export
 * @class FormBuilder
 */
export class FormBuilder {
  protected _form: JSX.Element[];
  protected _elements: FormGroupProps[] = [];
  constructor(elements?: FormGroupProps[]) {
    if (elements) {
      this.setElements(elements);
    }
  }
  /**
   *
   * Prepare react-form element for the given data
   *
   * @static
   * @template U
   * @param {((props: U) => JSX.Element | JSX.Element[])} formElement
   * @param {string} [formName='main']
   * @returns
   * @memberof FormBuilder
   */
  static form<U extends HandledFormProps>(
    formElement: (props: U) => JSX.Element | JSX.Element[],
    formName: string = 'main',
    validate?: (values: {
      [index: string]: string;
    }) => { [index: string]: string },
    warn?: (values: { [index: string]: string }) => { [index: string]: string }
  ): (props: U) => JSX.Element {
    return reduxForm({
      form: formName,
      validate,
      warn,
    })((props: U | HandledFormProps) => {
      return (
        <form
          className={props.className ? props.className : ''}
          onSubmit={props.handleSubmit}
        >
          {formElement(props as U)}
        </form>
      );
    }) as any;
  }

  static formActions(formName: string = 'main') {
    return {
      setValue: (field: string, value: string | number | boolean) => {
        return change(formName, field, value);
      },
    };
  }

  setElements(elements: FormGroupProps[]) {
    this._form = null;
    this._elements = elements;
  }

  /**
   * Add item to builded form
   *
   * @param {FormGroupProps} item
   * @memberof FormBuilder
   */
  add(item: FormGroupProps) {
    this._elements.push(item);
  }

  build() {
    if (this._form) {
      return this._form;
    }
    return (this._form = this._elements.map((data, i) =>
      data.tableCell ? (
        <FormTableCell key={i}>
          {data.input}
        </FormTableCell>
      ) : data.groupLabel ? (
        <FormGroupLabel
          key={i}
          label={data.groupLabel}
          hide={data.hide}
          invisible={data.invisible}
          className={data.className}
          col={data.col}
        />
      ) : data.separator ? (
        <FormSeparator col={data.col} key={i} className={data.className} />
      ) : (
        <FormGroup key={i} {...data as FormGroupParams}>
          {data.input}
        </FormGroup>
      )
    ));
  }
}

export type FormBuilderActions = ReturnType<typeof FormBuilder.formActions>;
