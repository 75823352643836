import * as React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { i18nContext } from '../../../language';
import { InputHandler, KEY_CODE_ESC } from '../../../services/input';


interface ModalPopupProps {
    showModal?: boolean;
    textes?: {
        title: string,
        confirm?: string,
        cancel: string,
        text: string | JSX.Element,
    }
    contentClassName?: string;

    submitRemove?: () => void;
    closePopup?: () => void;
}

class ModalPopup extends React.Component<ModalPopupProps, {}> {
    constructor(props: ModalPopupProps) {
        super(props);
        this.escFunction = this.escFunction.bind(this);
    }

    escFunction() {
        if (this.props.showModal) {
            this.props.closePopup();
            return true;
        }
        return false;
    }
    
    componentWillReceiveProps (newProps: ModalPopupProps) {
      if(newProps.showModal && newProps.showModal !== this.props.showModal) {
        InputHandler.hookHandler(KEY_CODE_ESC, 'modal', () => this.escFunction());
      }
    }
    render() {
        return (
            <Modal
                centered
                key="modal"
                contentClassName={this.props.contentClassName}
                isOpen={this.props.showModal}
                toggle={this.props.closePopup}>
                <ModalHeader toggle={this.props.closePopup}>{this.props.textes.title}</ModalHeader>
                <ModalBody>{this.props.textes.text}</ModalBody>
                <ModalFooter>
                    {this.props.textes.confirm && <Button color="danger" onClick={this.props.submitRemove}>{this.props.textes.confirm}</Button>}{' '}
                    <Button color="secondary" onClick={this.props.closePopup}>{this.props.textes.cancel}</Button>
                </ModalFooter>
            </Modal>
        );
    };
}

export default ModalPopup;