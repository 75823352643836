import * as data from '../actions/data';
import { ActionType } from 'typesafe-actions';
import { DATA_UPDATE_CURRENCIES, DATA_UPDATE_PREV_MONTH_LOCKED, DATA_UPDATE_SHIPPING_TERMS, DATA_UPDATE_TERMS_OF_PAYMENT } from '../data/constants';
import {
    DATA_SET_REPORTS,
    DATA_RESET_REPORTS,
    DATA_ORDER_IS_EDITED,
    DATA_UPDATE_WAREHOUSES,
    DATA_UPDATE_PURCHASE_ORDERS,
    DATA_UPDATE_SALES_ORDERS,
    DATA_RESET_ORDERS,
    DATA_LOAD_ALL,
    DATA_UPDATE_PURCHASE,
    DATA_UPDATE_SALES,
    DATA_UPDATE_CUSTOMERS,
    DATA_UPDATE_MATERIALS,
    DATA_UPDATE_DIVISIONS,
    DATA_UPDATE_COST_CENTERS,
    DATA_UPDATE_ADDITIONAL_COSTS,
    DATA_UPDATE_COUNTRIES,
    DATA_UPDATE_REPRESENTATIVES,
    DATA_UPDATE_FEATURES_AVAILABILITY,
    DATA_LOAD_METADATA,
    DATA_UPDATE_DASHBOARD,
    DATA_CREATE_REQUEST_STATUS_UPDATE,
    DATA_ORDER_ADD_POSITION_ITEM,
    DATA_ORDER_DELETE_POSITION_ITEM,
    DATA_ORDER_DELETE_ALL_POSITION_ITEMS,
    DATA_ORDER_ADD_ADDITIONAL_COST_ITEM,
    DATA_ORDER_DELETE_ADDITIONAL_COST_ITEM,
    DATA_ORDER_DELETE_ALL_ADDITIONAL_COST_ITEMS,
    DATA_ORDER_CLEAR,
    DATA_ORDER_SET_CUSTOMER_PARTNERS,
    DATA_SET_DETAILS,
    DATA_RESET_DETAILS,
    DATA_UPDATE_HISTORY,
    DATA_SET_LOGS,
    DATA_ORDER_DETAILS_TRIGGER_SET,
    DATA_ORDER_DETAILS_TRIGGER_RESET,
    DATA_RESET_LOGS,
    DATA_RESET_HISTORY,
    DATA_UPDATE_SETTINGS,
    DATA_SET_SHIPPING_ADDRESS_FORM_IS_DISPLAYED,
    DATA_SET_SELECTED_CUSTOMER_IS_BLOCKED,
    DATA_SET_SELECTED_CUSTOMER_SHIPPING_PARTNER_IS_BLOCKED,
    DATA_SET_PURCHASE,
    DATA_SET_SALES,
} from '../data/constants';

export type DataAction = ActionType<typeof data>;

export type CustomerPartner = { [index: string]: string | number | boolean | JSX.Element };

export type Data = { [index: string]: {
    [index: string]: string | string[] | number | boolean | JSX.Element | CustomerPartner[] | Data
} }

export type DataState = {
    customers: Data,
    purchase: Data,
    sales: Data,
    purchase_orders: Data,
    sales_orders: Data
    materials: Data,
    cost_centers: Data,
    divisions: Data,
    additional_costs: Data,
    countries: Data,
    representatives: Data,
    features_availability: Data,
    prev_month_locked: boolean,
    currencies: Data,
    isLoaded: { [index: string]: boolean },
    isLoaded_meta: boolean,
    dashboard: { [index: string]: { [index: string]: Data } | Data },
    dashboard_years: { [index: string]: string },
    requestStatus: string,
    orderPositionItems: string[],
    orderAdditionalCostItems: string[],
    orderCustomerShippingPartners: CustomerPartner[],
    orderDetails: { [index: string]: Data },
    activeOrder: { type: string, id: string },
    history: { [index: string]: Data },
    log: Data,
    reports: Data,
    positionOrderDetailsTrigger: { table: string, id: string },
    settings: Data,
    shipping_terms: Data,
    warehouses: Data,
    terms_of_payment: Data,
    orderIsEdited: boolean,
    shippingAddressFormIsDisplayed: boolean,
    selectedCustomerIsBlocked: boolean,
    selectedCustomerShippingPartnerIsBlocked: boolean,
};

export const defaultDataState: DataState = {
    customers: null,
    purchase: null,
    sales: null,
    purchase_orders: null,
    sales_orders: null,
    materials: null,
    cost_centers: null,
    divisions: null,
    additional_costs: null,
    countries: null,
    representatives: null,
    features_availability: {},
    prev_month_locked: false,
    currencies: null,
    isLoaded: null,
    isLoaded_meta: false,
    dashboard: null,
    dashboard_years: null,
    requestStatus: null,
    orderPositionItems: [],
    orderAdditionalCostItems: [],
    orderCustomerShippingPartners: [],
    orderDetails: null,
    history: null,
    log: null,
    reports: null,
    positionOrderDetailsTrigger: null,
    activeOrder: null,
    settings: null,
    warehouses: null,
    orderIsEdited: false,
    shipping_terms: null,
    terms_of_payment: null,
    shippingAddressFormIsDisplayed: false,
    selectedCustomerIsBlocked: false,
    selectedCustomerShippingPartnerIsBlocked: false,
};

export function buildDefaultState(data: {
}): DataState {
    return {
        ...defaultDataState,
        ...data,
    };
}

export const DataMainReducer = (
    state: DataState = defaultDataState,
    action: DataAction
) => {
    switch (action.type) {
        case DATA_LOAD_ALL:
            return { ...state, isLoaded: {} };
        case DATA_LOAD_METADATA:
            return { ...state, isLoaded_meta: true };
        case DATA_UPDATE_PURCHASE:
            return { ...state, purchase: { ...state.purchase, ...action.payload.data }, isLoaded: { ...state.isLoaded, purchase: true } };
        case DATA_UPDATE_SALES:
            return { ...state, sales: { ...state.sales, ...action.payload.data }, isLoaded: { ...state.isLoaded, sale: true } };
        case DATA_UPDATE_PURCHASE_ORDERS:
            return { ...state, purchase_orders: { ...state.purchase_orders, ...action.payload.data }, isLoaded: { ...state.isLoaded, purchase_orders: true } };
        case DATA_UPDATE_SALES_ORDERS:
            return { ...state, sales_orders: { ...state.sales_orders, ...action.payload.data }, isLoaded: { ...state.isLoaded, sale_orders: true } };
        case DATA_SET_PURCHASE:
            return { ...state, purchase: { ...action.payload.data }, isLoaded: { ...state.isLoaded, purchase: true } };
        case DATA_SET_SALES:
            return { ...state, sales: { ...action.payload.data }, isLoaded: { ...state.isLoaded, sale: true } };
        case DATA_RESET_ORDERS:
            return { ...state, purchase_orders: null, sales_orders: null, isLoaded: { ...state.isLoaded, sale_orders: false, purchase_orders: false } };
        case DATA_UPDATE_CUSTOMERS:
            return { ...state, customers: action.payload.data, isLoaded: { ...state.isLoaded, customers: true } };
        case DATA_UPDATE_MATERIALS:
            return { ...state, materials: { ...state.materials, ...action.payload.data }, isLoaded: { ...state.isLoaded, materials: true } };
        case DATA_UPDATE_DIVISIONS:
            return { ...state, divisions: action.payload.data };
        case DATA_UPDATE_COST_CENTERS:
            return { ...state, cost_centers: action.payload.data };
        case DATA_UPDATE_WAREHOUSES:
            return { ...state, warehouses: action.payload.data };
        case DATA_UPDATE_SHIPPING_TERMS:
            return { ...state, shipping_terms: action.payload.data };
        case DATA_UPDATE_TERMS_OF_PAYMENT:
            return { ...state, terms_of_payment: action.payload.data };
        case DATA_UPDATE_ADDITIONAL_COSTS:
            return { ...state, additional_costs: action.payload.data };
        case DATA_UPDATE_COUNTRIES:
            return { ...state, countries: action.payload.data };
        case DATA_UPDATE_REPRESENTATIVES:
            return { ...state, representatives: action.payload.data };
        case DATA_UPDATE_FEATURES_AVAILABILITY:
            return { ...state, features_availability: action.payload.data }
        case DATA_UPDATE_PREV_MONTH_LOCKED:
            return { ...state, prev_month_locked: action.payload.data }
        case DATA_UPDATE_CURRENCIES:
            return { ...state, currencies: action.payload.data }
        case DATA_UPDATE_DASHBOARD:
            let dashboard: { [index: string]: { [index: string]: Data } | Data } = {};
            let years: { [index: string]: string } = {};
            if (state.dashboard) {
                Object.keys(action.payload.data.orders).map(key =>
                    dashboard[key] = { ...state.dashboard[key], ...action.payload.data.orders[key] } as { [index: string]: Data } | Data)
            } else {
                dashboard = action.payload.data.orders;
            }
            if (action.payload.data.years) {
                years = action.payload.data.years;
            }
            return { ...state, dashboard: { ...state.dashboard, ...dashboard }, dashboard_years: { ...state.dashboard_years, ...years } };
        case DATA_CREATE_REQUEST_STATUS_UPDATE:
            return { ...state, requestStatus: action.payload.status };
        case DATA_ORDER_ADD_POSITION_ITEM:
            const orderPositionItems = [...state.orderPositionItems];
            orderPositionItems.push(action.payload.id);
            return {
                ...state,
                orderPositionItems
            };
        case DATA_ORDER_DELETE_POSITION_ITEM:
            return { ...state, orderPositionItems: state.orderPositionItems.filter(el => el != action.payload.id) };
        case DATA_ORDER_DELETE_ALL_POSITION_ITEMS:
            return { ...state, orderPositionItems: [] };
        case DATA_ORDER_ADD_ADDITIONAL_COST_ITEM:
            const orderAdditionalCostItems = [...state.orderAdditionalCostItems];
            orderAdditionalCostItems.push(action.payload.id);
            return {
                ...state,
                orderAdditionalCostItems
            };
        case DATA_ORDER_DELETE_ADDITIONAL_COST_ITEM:
            return { ...state, orderAdditionalCostItems: state.orderAdditionalCostItems.filter(el => el != action.payload.id) };
        case DATA_ORDER_DELETE_ALL_ADDITIONAL_COST_ITEMS:
            return { ...state, orderAdditionalCostItems: [] }
        case DATA_ORDER_SET_CUSTOMER_PARTNERS:
            return {
                ...state,
                orderCustomerShippingPartners: action.payload.partners.shippingPartners,
            };
        case DATA_ORDER_CLEAR:
            return { ...state, orderPositionItems: [], orderAdditionalCostItems: [], shippingAddressFormIsDisplayed: false };
        case DATA_SET_DETAILS:
            return { ...state, orderDetails: state.orderDetails ? action.payload.data : { ...state.orderDetails, ...action.payload.data }, activeOrder: { id: action.payload.data.order_details.id, type: action.payload.type } };
        case DATA_RESET_DETAILS:
            return { ...state, orderDetails: null, orderPositionItems: [], orderAdditionalCostItems: [], activeOrder: null };

        case DATA_UPDATE_HISTORY:
            return { ...state, history: action.payload.data, isLoaded: { ...state.isLoaded, history: true } };
        case DATA_RESET_HISTORY:
            return { ...state, history: null, isLoaded: { ...state.isLoaded, history: false } };

        case DATA_SET_LOGS:
            return { ...state, log: action.payload.data, isLoaded: { ...state.isLoaded, logs: true } };
        case DATA_RESET_LOGS:
            return { ...state, log: null, isLoaded: { ...state.isLoaded, logs: false } };

        case DATA_SET_REPORTS:
            return { ...state, reports: action.payload.data, isLoaded: { ...state.isLoaded, reports: true } };
        case DATA_RESET_REPORTS:
            return { ...state, reports: null, isLoaded: { ...state.isLoaded, reports: false } };

        case DATA_ORDER_DETAILS_TRIGGER_SET:
            return { ...state, positionOrderDetailsTrigger: action.payload };
        case DATA_ORDER_DETAILS_TRIGGER_RESET:
            return { ...state, positionOrderDetailsTrigger: null };

        case DATA_UPDATE_SETTINGS:
            return { ...state, settings: state.settings && { ...state.settings, ...action.payload.data } || action.payload.data };

        case DATA_ORDER_IS_EDITED:
            return { ...state, orderIsEdited: action.payload.orderIsEdited };

        case DATA_SET_SHIPPING_ADDRESS_FORM_IS_DISPLAYED:
            return { ...state, shippingAddressFormIsDisplayed: action.payload.display };

        case DATA_SET_SELECTED_CUSTOMER_IS_BLOCKED:
            return { ...state, selectedCustomerIsBlocked: action.payload.isBlocked };
        case DATA_SET_SELECTED_CUSTOMER_SHIPPING_PARTNER_IS_BLOCKED:
            return { ...state, selectedCustomerShippingPartnerIsBlocked: action.payload.isBlocked };

        default:
            return state;
    }
};

export const DataReducer = {
    reducer: DataMainReducer,
    defaultSate: defaultDataState,
    stateBuilder: buildDefaultState,
};

export default DataReducer;