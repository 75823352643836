import * as React from 'react';
import { connect } from 'react-redux';
import { RootState, RootAction } from '../../../store/types';
import { TabTable, TabTableProps } from '../tables/tabtable';
import { getHistory, getHistoryUsersOptions } from '../../../selectors';
import HistoryUserSelectForm from '../forms/history-user-select';
import { loadUserHistory } from '../../../actions/data';
import { customRowRenderHistory, getHistoryTableHeaders, customSort } from '../tables/tabtable-components';
import { i18nContext, I18n } from '../../../language';
import { getDataIsLoaded } from '../../../selectors/data';


export interface HistoryTabProps extends TabTableProps {
    outerClassName?: string;
    hideUsers?: boolean;
    historyUsers?: Array<{ value: string, text: string }>;
    updateHistory?: () => void;
}

const allUsersHistoryOption = (i18n: I18n): { value: string, text: string } =>
    ({ value: "", text: i18n.t("All users history") });

class HistoryTab extends React.PureComponent<HistoryTabProps, {}> {
    render() {
        return (
            <i18nContext.Consumer>
                {i18n =>
                    <div className={["d-flex flex-column", this.props.outerClassName].join(' ')}>
                        {!this.props.hideUsers && this.props.historyUsers &&
                            this.props.historyUsers.length > 0 &&
                            (<HistoryUserSelectForm options={[allUsersHistoryOption(i18n), ...this.props.historyUsers]} onSubmit={this.props.updateHistory} />)}
                        <TabTable
                            {...this.props}
                            options={{
                                search: false,
                                customSort: customSort,
                                customRowRender: customRowRenderHistory(),
                                sortOrder: {
                                    name: 'id',
                                    direction: 'desc',
                                },
                            }}
                            customeHeaders={getHistoryTableHeaders()}
                        />
                    </div>
                }
            </i18nContext.Consumer>
        )
    }
}

const mapStateToProps = (
    state: RootState,
    ownProps: HistoryTabProps
) => {
    const isLoaded = getDataIsLoaded(state);
    return {
        items: getHistory(state),
        historyUsers: getHistoryUsersOptions(state),
        isLoaded: isLoaded && isLoaded.history,
    }
};

const mapDispatchToProps = (
    dispatch: (action: RootAction) => void,
    ownProps: HistoryTabProps
) => {
    return {
        updateHistory() {
            dispatch(loadUserHistory());
        },
    };
};
export const HistoryBarContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(HistoryTab);

export default HistoryBarContainer;