import * as React from 'react';

export interface WidgetSliderProps {
  sliderTitle?: string;
  children?: JSX.Element | JSX.Element[];
  onClick?: () => void;
  showContent?: boolean;
}

export const WidgetSlider = (props: WidgetSliderProps) => (
  <div
    className={[
      'widget-slider d-flex flex-column',
      props.showContent ? 'content-show' : ''
    ].join(' ')}
  >
    <div className="widget-slider-content flex-wrap">{props.children}</div>
    <div
      className="px-6 widget-slider-head d-flex flex-row justify-content-between align-items-center"
      onClick={props.onClick}
    >
      <span className="widget-slider-title font-weight-bold">{props.sliderTitle}</span>
      <span className="widget-slider-icon font-weight-bold mr-3">&#8609;</span>
    </div>
  </div>
);
