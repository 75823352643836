import StorageLocal from "./storage";
import { values } from "ramda";

export const LanguageService = {
    currentLanguage: 'en',
    translations: {},
    // notTranslated: {},
    init(translations: { [index: string]: { [index: string]: { [index: string]: string } } },
        currentLanguage: string = 'en') {
        this.translations = translations;
        this.currentLanguage = currentLanguage;

        return this;
    },
    t(key: string) {
        if (this.translations &&
            this.translations[this.currentLanguage] &&
            this.translations[this.currentLanguage].translations[key]) {
            return this.translations[this.currentLanguage].translations[key];
        }
        // if (!Object.keys(this.notTranslated).includes(key)) {
        //     console.log(key);
        //     this.notTranslated[key] = key;
        // }
        return key;
    },
    translateTo(key: string, lng: string) {
        if (this.translations &&
            this.translations[lng] &&
            this.translations[lng].translations[key]) {
            return this.translations[lng].translations[key];
        }
        return key;
    },
    getCurrentLanguage() {
        return this.currentLanguage;
    },
    getCurrentDecimalSeparator() {
        switch (this.currentLanguage) {
            case 'en':
                return '.';
            case 'de':
            default:
                return ',';
        }
    },
    getCurrentThousandSeparator() {
        switch (this.currentLanguage) {
            case 'en':
                return ',';
            case 'de':
            default:
                return '.';
        }
    },
    changeLanguage(language: string) {
        this.currentLanguage = language;
        StorageLocal.clear('HuM_');
        StorageLocal.set('Language', language, 'HuM_');
    },
    format(value: number, size: number = 3) {
        if (!value) {
            value = 0;
        }
        value = Number(value.toFixed(size));
        let newValue = String(value);
        let regExp;
        switch (this.currentLanguage) {
            case 'en':
                newValue = value.toLocaleString('en-US');
                regExp = /([\d|,]+)\.(\d+)/;
                break;
            case 'de':
            default:
                newValue = value.toLocaleString('de-DE');
                regExp = /([\d|\.]+),(\d+)/;
        }
        const match = regExp.exec(newValue);
        if (!match || match && !match[2]) {
            return `${newValue}${this.currentLanguage === 'de' ? ',' : '.'}${size === 2 ? '00' : '000'}`;
        }
        if (match && match[2] && match[2].length > 0) {
            const length: number = size - match[2].length;
            for (var i = 0; i < length; i++) {
                newValue += '0';
            }
        }
        return newValue;
    },
    formatNumber(value: number): string {
        return this.format(value, 3);
    },
    formatPrice(value: number): string {
        return this.format(value, 2);
    },
    formatGeneralPrice(value: number) {
        value = Number(value.toFixed(2));
        let newValue = String(value);
        const regExp = /(\d+)\.(\d+)/;
        const match = regExp.exec(newValue);
        if (!match || match && !match[2]) {
            return `${newValue}.00`;
        }
        if (match && match[2] && match[2].length > 0) {
            const length: number = 2 - match[2].length;
            for (var i = 0; i < length; i++) {
                newValue += '0';
            }
        }
        return newValue;
    },
    clearFormating(value: string) {
        if (!value) {
            return 0;
        }
        let newValue = String(value);
        switch (this.currentLanguage) {
            case 'en':
                newValue = newValue.replace(/,/g, '');
                break;
            case 'de':
            default:
                newValue = newValue.replace(/\./g, '');
                newValue = newValue.replace(/,/g, '.');
        }
        return Number(newValue);
    }
};