import * as React from 'react';
import { I18n } from '../../../language';

const DescriptionTemplate = (data: { [index: string]: string | number | boolean }, i18n: I18n) => {
    switch (String(data.action)) {
        case "record created":
            return `${i18n.t('Record')} "${data.object_id}" ${i18n.t('created in')} ${i18n.t(String(data.affected_object))}`;
        case "record locked":
            return `${i18n.t('Record')} ${i18n.t(String(data.affected_object))} "${data.object_id}" ${i18n.t('locked by')} ${i18n.t(String(data.new_value))}`;
        case "record unlocked":
            return `${i18n.t('Record')} ${i18n.t(String(data.affected_object))} "${data.object_id}" ${i18n.t('unlocked by')} ${i18n.t(String(data.old_value))}`;
        case "field updated":
            return `${i18n.t('Record')} "${data.object_id}" ${i18n.t('updated in')} ${i18n.t(String(data.affected_object))}: 
                ${i18n.t('field')} "${i18n.t(String(data.affected_field))}" ${i18n.t('has changed from')} "${i18n.t(String(data.old_value))}" ${i18n.t('to')} "${i18n.t(String(data.new_value))}"`;
        case "record matched":
            return `${i18n.t('Record')} ${i18n.t(String(data.affected_object))} "${data.object_id}" ${i18n.t('matched with')} "${i18n.t(String(data.matched_to))}": 
                    ${i18n.t('field')} "${i18n.t(String(data.affected_field))}" ${i18n.t('has changed from')} "${i18n.t(String(data.old_value))}" ${i18n.t('to')} "${i18n.t(String(data.new_value))}"`;
        case "record transfered to inventory":
            return `${i18n.t('Record')} ${i18n.t(String(data.affected_object))} "${data.object_id}" ${i18n.t('transferred to')} ${i18n.t('Invantory')}`;
        case "record transfered to pnl":
            return `${i18n.t('Record')} ${i18n.t(String(data.affected_object))} "${data.object_id}" ${i18n.t('transferred to')} ${i18n.t('Profits and Losses')}`;
        case "matching operation canceled":
            return `${i18n.t(String(data.action))} ${i18n.t('for')} ${i18n.t(String(data.affected_object))} "${data.object_id}"`
        default:
            return `${i18n.t(String(data.action))} ${i18n.t('in')} ${i18n.t(String(data.affected_object))}, ${i18n.t('id')} "${data.object_id}": 
                ${i18n.t('field')}  "${i18n.t(String(data.affected_field))}", ${i18n.t('value')}: "${i18n.t(String(data.old_value))}" => "${i18n.t(String(data.new_value))}"`;
    }
}

export const HistoryTemplate = (data: { [index: string]: string | number | boolean }, i18n: I18n) => {
    return (
        <tr>
            <td key="created_by" className="created_by">{data.created_by}</td>
            <td key="created_at" className="created_at">{data.created_at}</td>
            <td key="action">{DescriptionTemplate(data, i18n)}</td>
        </tr>
    )
}