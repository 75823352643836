import Socket from "./socket";
import { handleSocketData } from "../../actions/socket";
import { Data } from '../../reducers/data';
import { CONNECT_SOCKET, DISCONNECT_SOCKET } from "../../data/constants";

const socketMiddleware = (store: any) => {

    const onDataHandle = (data: { type: string, data: Data }) => {
        store.dispatch(handleSocketData(data));
    };

    const socket = new Socket(onDataHandle);

    return (next: any) => (action: any) => {
        switch (action.type) {
            case CONNECT_SOCKET:
                const login = action.payload.credentials.login;
                socket.connect(login);
                break;
            case DISCONNECT_SOCKET:
                socket.disconnect();
                break;
            default:
                break;
        }

        return next(action)
    };
};

export default socketMiddleware;