import * as React from 'react';
import { InputHandler } from '../../../services/input';

export interface PopupButtonProps {
  title?: string;
  appendedPopup: any;

  popups?: Array<{
    title?: string,
    eventKey?: string,
    isPurchase?: boolean,
    content?: JSX.Element,
    onPopupOpen?: () => void,
    submitRequest?: () => void,
    submitTitle?: string,
  }>;
  renderToggleButton: (props: {
    title: string;
  }) => (togglePopup: () => void) => JSX.Element
}

export class PopupButton extends React.Component<PopupButtonProps, {}> {
  state: {
    showPopup: boolean,
    active: number,
  } = {
      showPopup: false,
      active: null,
    };
  constructor(props: PopupButtonProps) {
    super(props);
    this.onToggle = this.onToggle.bind(this);
  }
  componentDidMount() {
    this.props.popups.map((popup, i) => {
      InputHandler.subscribeHandler(String(popup.eventKey), popup.title, () => {
        const parentElement = document.body;
        if (!parentElement.classList.contains('popup-showed') && !parentElement.classList.contains('modal-open') && (event.target as Element).localName !== "input"){
          this.onToggle(i);
          return true;
        }
        return false;
      });
    });
  }
  componentWillUnmount() {
    this.props.popups.map((popup, i) => InputHandler.unsubscribeHandler(String(popup.eventKey), popup.title));
  }

  onToggle(index: number) {
    this.setState({
      showPopup: !this.state.showPopup,
      active: index,
    },
      () => {
        const parentElement = document.body;
        if (!parentElement) {
          return;
        }

        if (this.state.showPopup) {
          parentElement.classList.add('popup-showed');
          if (this.props.popups[this.state.active]) {
            this.props.popups[this.state.active].onPopupOpen();
          }
        } else {
          parentElement.classList.remove('popup-showed');
        }
      }
    );
  }
  render() {
    return ([
      this.props.popups.map(
        (popup, i) => this.props.renderToggleButton({ title: popup.title })(() => this.onToggle(i))
      ),
      (<this.props.appendedPopup
        key="popup"
        closePopup={this.onToggle}
        showPopup={this.state.showPopup}
        title={this.state.showPopup && this.props.popups[this.state.active] && this.props.popups[this.state.active].title}
        submitTitle={this.state.showPopup && this.props.popups[this.state.active] && this.props.popups[this.state.active].submitTitle}
        submitRequest={this.state.showPopup && this.props.popups[this.state.active] && this.props.popups[this.state.active].submitRequest}
        isPurchase={this.state.showPopup && this.props.popups[this.state.active] && this.props.popups[this.state.active].isPurchase}
        content={this.state.showPopup && this.props.popups[this.state.active] && this.props.popups[this.state.active].content}
      />
      )]
    );
  }
}
