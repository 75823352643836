import * as React from 'react';
import { FORM_MATERIAL } from '../../../data/constants';
import { i18nContext } from '../../../language';
import { FormBuilder, HandledFormProps, FormElements } from '../../utils/form/form';
import { materialFieldNames } from '../../../reducers/form';

const Textes = {
    add: "Add new",
    save: "Save Changes",
    discard: "Discard Changes",
    cancel: "Close without saving",
};

export interface MaterialFormProps extends HandledFormProps {
    protected?: boolean;
    readonly?: boolean;

    onCansel?: () => void;
    onSubmit?: () => void;
    discardChanges?: () => void;
}

const MaterialFields = (readonly?: boolean) => [
    {
        input: FormElements.input({
            name: materialFieldNames.material_code,
            className: 'widest-input',
            required: true,
            disabled: readonly,
            maxLength: 5,
        }),
        tableCell: true,
        required: true,
        className: 'mt-3',
    }, {
        input: FormElements.input({
            name: materialFieldNames.name,
            className: 'widest-input',
            maxLength: 20,
        }),
        tableCell: true,
        className: 'mt-3',
    }, {
        input: FormElements.input({
            name: materialFieldNames.multiplier,
            className: 'widest-input',
            maxLength: 40,
            disabled: readonly,
            required: true,
        }),
        tableCell: true,
        required: true,
        type: 'number',
        className: 'mt-3',
    }, {
        input: FormElements.input({
            name: materialFieldNames.price_unit,
            className: 'widest-input',
            maxLength: 40,
            disabled: readonly,
            required: true,
        }),
        className: 'mt-3',
        required: true,
        tableCell: true,
    }, {
        input: FormElements.input({
            name: materialFieldNames.weight_unit,
            className: 'widest-input',
            maxLength: 20,
            disabled: readonly,
            required: true,
        }),
        className: 'mt-3',
        required: true,
        tableCell: true,
    }, {
        input: FormElements.input({
            name: materialFieldNames.material_number,
            className: 'widest-input',
            maxLength: 10,
        }),
        className: 'mt-3',
        tableCell: true,
    }, {
        input: FormElements.input({
            name: materialFieldNames.color,
            className: 'widest-input',
            maxLength: 20,
            required: true,
            prefix: '#'
        }),
        className: 'mt-3',
        required: true,
        tableCell: true,
    },
]

const MaterialForm = FormBuilder.form<MaterialFormProps>(
    (props: MaterialFormProps) => {
        const formBuilder = new FormBuilder();
        formBuilder.setElements(MaterialFields(props.readonly));
        return (
            <i18nContext.Consumer>
                {i18n => (
                    <div className="position-table-container w-100">
                        <table className="position-table w-100">
                            <thead>
                                <tr>
                                    {Object.keys(materialFieldNames).map((h, i) => <td key={i}>{i18n.t(h)}</td>)}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>{formBuilder.build()}</tr>
                            </tbody>
                        </table>
                        <div className="d-flex justify-content-end mt-3">
                            {props.readonly && (
                                <button className="btn btn-secondary text-center mr-auto" onClick={e => {
                                    e.preventDefault();
                                    props.discardChanges();
                                }}>
                                    {i18n.t(Textes.discard)}
                                </button>
                            )}
                            <div className={[
                                "mr-3",
                                "p-0",
                                "protectable",
                                props.protected ? "protectable--protected" : ""
                            ].join(' ')}>
                                <button
                                    className="w-100 btn btn-secondary text-center"
                                    type="submit">
                                    {props.readonly ? i18n.t(Textes.save) : i18n.t(Textes.add)}
                                </button>
                            </div>
                            <button className="btn btn-secondary text-center" onClick={e => {
                                e.preventDefault();
                                props.onCansel();
                            }}>
                                {i18n.t(Textes.cancel)}
                            </button>
                        </div>
                    </div>
                )}
            </i18nContext.Consumer>
        )
    },
    FORM_MATERIAL
);

export default MaterialForm;