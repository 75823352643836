import { RootState } from '../store/types.d'
import * as prop from 'ramda/src/prop';
import { createSelector } from 'reselect';
import { FORM_LOGIN, FORM_VIEWING_YEAR } from '../data/constants';
import { UserPermissions } from '../models/user-permissions';

export const getLoginFormValues = (state: RootState) =>
    prop('values', state.form[FORM_LOGIN]) || {};

export const getViewingYearValue = (state: RootState) =>
    prop('values', state.form[FORM_VIEWING_YEAR]) || {};

export const getErrorMessage = (state: RootState): string =>
    state.session.errorMessage;

export const getSessionUser = (state: RootState): string =>
    state.session.user;

export const getUserRole = (state: RootState): string =>
    state.session.role;

export const getUserToken = (state: RootState): string =>
    state.session.token;

export const getHeartbeatCount = (state: RootState): number =>
    state.session.heartbeat;

export const getCredentialsBaseToken = (state: RootState): string =>
    state.session.authorizationToken;

export const getUserPermissions = (state: RootState): UserPermissions =>
    state.session.permissions || {};

export const getUserData = createSelector(
    [getSessionUser, getUserRole, getUserPermissions],
    (user, role, permissions) => ({ user, role, permissions })
);
