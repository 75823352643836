import * as React from 'react';
import { TabTableProps, TabTable } from '../tables/tabtable';
import { customSort, customRowRender, getOrdersTableHeaders } from '../tables/tabtable-components';

export interface OrderTabProps extends TabTableProps {
    materialColors?: { [index: string]: string };
    contextMenuId?: string;
    hiddenColumns?: string[];

    onSearch?: (searchText: string) => void;
    onSearchClose?: () => void;
    onPositionOrderDetailsTrigger?: (id: string) => void;
}

export class OrderTab extends React.PureComponent<OrderTabProps, {}> {
    render() {
        return (
            <TabTable
                key="table"
                {...this.props}
                options={{
                    customSort: customSort,
                    customRowRender: customRowRender(
                        this.props.materialColors,
                        this.props.contextMenuId,
                        this.props.onPositionOrderDetailsTrigger,
                        this.props.hiddenColumns,
                    ),
                    onSearchClose: this.props.onSearchClose,
                    onSearchChange: this.props.onSearch,
                    searchText: this.props.searchValue,
                    sortOrder: {
                        name: 'id',
                        direction: 'desc',
                    },
                }}
                customeHeaders={getOrdersTableHeaders({hiddenColumns: this.props.hiddenColumns})}
            />
        )
    }
}
