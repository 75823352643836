import * as React from 'react';
import { connect } from 'react-redux';
import { RootState, RootAction } from '../../../store/types';
import { getOrderDetailsTrigger, } from '../../../selectors';
import { canselPurchase, unlockPurchase, lockPurchase } from '../../../actions/trading';
import { PURCHASE_POSITION_CONTEXT_MENU_ID } from '../../../data/constants';
import { getOrderPurchaseDetails, canselPurchaseOrder, changePurchaseOrder, lockOrderPurchasePositions, unlockOrderPurchasePositions, loadPurchaseHistory, resetHistory, resetOrderDetailsTrigger, resetOrderDetails, canselPurchaseDelivery, canselPurchaseInvoice } from '../../../actions/data';
import { connectMenu } from 'react-contextmenu';
import PositionContextMenu from '../contextmenu';
import PurchaseTable from './purchaseTable';

const SEARCH_TYPE = "PURCHASE";

const ConnectedMenu = connectMenu(PURCHASE_POSITION_CONTEXT_MENU_ID)(PositionContextMenu);

export interface OrderTabProps {
    title?: string;
    showPositionOrderDetailsTrigger?: { show: boolean, id: string };

    onPositionDelete?: (id: string) => void;
    onPositionLock?: (id: string) => void;
    onPositionUnlock?: (id: string) => void;
    onGetPositionOrderDetails?: (id: string) => void;
    onOrderDelete?: (id: string) => void;
    onDeliveryDelete?: (id: string) => void;
    onInvoiceDelete?: (id: string) => void;
    onOrderChangesSave?: (id: string) => void;
    onOrderLock?: (ids: string[]) => void;
    onOrderUnLock?: (ids: string[]) => void;
    onHistoryLoad?: (ids: string[], orderId?: string) => void;
    onRemovePositionOrderDetailsTrigger?: () => void;
}

export class OrderTab extends React.PureComponent<OrderTabProps, {}> {
    render() {
        return [
            <PurchaseTable title={this.props.title} />,
            <ConnectedMenu
                key="menu"
                removeItem={this.props.onPositionDelete}
                unlockItem={this.props.onPositionUnlock}
                lockItem={this.props.onPositionLock}
                getDetails={this.props.onGetPositionOrderDetails}
                deleteOrder={this.props.onOrderDelete}
                deleteDelivery={this.props.onDeliveryDelete}
                deleteInvoice={this.props.onInvoiceDelete}
                saveOrderChanges={this.props.onOrderChangesSave}
                lockOrderPositions={this.props.onOrderLock}
                unlockOrderPositions={this.props.onOrderUnLock}
                isPurchase={true}
                historyLoad={this.props.onHistoryLoad}
                showPositionOrderDetailsTrigger={this.props.showPositionOrderDetailsTrigger}
                removePositionOrderDetailsTrigger={this.props.onRemovePositionOrderDetailsTrigger}
            />
        ]
    }
}
const mapStateToProps = (
    state: RootState,
    ownProps: OrderTabProps
) => {
    const trigger = getOrderDetailsTrigger(state);

    return {
        showPositionOrderDetailsTrigger: trigger && { ...trigger, show: trigger.table === SEARCH_TYPE },
    }
};

const mapDispatchToProps = (
    dispatch: (action: RootAction) => void,
    ownProps: OrderTabProps
) => {
    return {
        onPositionDelete(id: string) {
            dispatch(canselPurchase(id));
        },
        onPositionLock(id: string) {
            dispatch(lockPurchase(id, true));
        },
        onPositionUnlock(id: string) {
            dispatch(unlockPurchase(id, true));
        },
        onGetPositionOrderDetails(id: string) {
            dispatch(resetOrderDetails());
            dispatch(getOrderPurchaseDetails(id));
        },
        onOrderDelete(id: string) {
            dispatch(canselPurchaseOrder(id));
        },
        onDeliveryDelete(id: string) {
            dispatch(canselPurchaseDelivery(id));
        },
        onInvoiceDelete(id: string) {
            dispatch(canselPurchaseInvoice(id));
        },
        onOrderChangesSave(id: string) {
            dispatch(changePurchaseOrder(id));
        },
        onOrderLock(ids: string[]) {
            dispatch(lockOrderPurchasePositions(ids));
        },
        onOrderUnLock(ids: string[]) {
            dispatch(unlockOrderPurchasePositions(ids));
        },
        onHistoryLoad(ids: string[], orderId: string) {
            dispatch(resetHistory());
            dispatch(loadPurchaseHistory(ids, orderId));
        },
        onRemovePositionOrderDetailsTrigger() {
            dispatch(resetOrderDetailsTrigger());
        }
    };
};

export const PurchaseBarContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(OrderTab);

export default PurchaseBarContainer;