import { connect } from 'react-redux';
import { RootState, RootAction } from '../../../../store/types';
import { getBillingSalesData, getSelectedSapSaleItems } from '../../../../selectors';
import { getMaterialsColor } from '../../../../selectors/index';
import { TabTableProps } from '../../tables/tabtable';
import { selectSapItem } from '../../../../actions/trading';
import { SelectebleOrderTab } from '../selected-order';

const mapStateToProps = (
    state: RootState,
    ownProps: TabTableProps
) => {
    const items = getBillingSalesData(state);
    return {
        items,
        isLoaded: !!items,
        materialColors: getMaterialsColor(state),
        selectedMatch: getSelectedSapSaleItems(state),
        showId: true,
    };
};

const mapDispatchToProps = (
    dispatch: (action: RootAction) => void,
    ownProps: TabTableProps
) => {
    return {
        onRowSelect(id: string) {
            dispatch(selectSapItem(id));
        },
    };
};

export const CompSalesBarContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(SelectebleOrderTab);

export default CompSalesBarContainer;