import { combineReducers } from 'redux';
import SessionReducer from "../reducers/session";
import FormReducer from '../reducers/form';
import AppReducer from '../reducers/app';
import DataReducer from '../reducers/data';
import TradingReducer from '../reducers/trading';
import SearchReducer from '../reducers/search';

export const rootReducer = combineReducers({
  form: FormReducer,
  session: SessionReducer.reducer,
  app: AppReducer.reducer,
  data: DataReducer.reducer,
  trading: TradingReducer.reducer,
  search: SearchReducer.reducer,
});

export function defaultState(
  language?: string,
  authorizationToken?: string,
  user?: string,
  role?: string
) {
  return {
    app: AppReducer.stateBuilder({ language }),
    session: SessionReducer.stateBuilder({ authorizationToken, user, role })
  };
}

export type RootReducer = typeof rootReducer;
export type DefaultState = ReturnType<typeof defaultState>;
export default rootReducer;