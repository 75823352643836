import { Textes } from '../order/data';
import { I18n } from '../../../../language';
import { FormElements } from '../../../utils/form/form';
import { shippingAddressFieldNames } from '../../../../reducers/form';

export const shippingAddressFields = (
    i18n: I18n,
    readonly?: boolean,
    options?: { [index: string]: Array<{ value: string; text: string; }> },
    isInvoiceCreated?: boolean,
    partners?: { value: string; text: string; }[],
    fieldsGroupLabel?: string,
    displayBlocked?: boolean,
    inactiveFields?: string[]
) => {
    return [
        {
            separator: true,
            className: "border-line mx-0 mb-4",
        }, {
            label: i18n.t(fieldsGroupLabel) || i18n.t("Address Fields"),
            className: 'col-2 pb-2 pr-0',
        }, {
            label: displayBlocked ? i18n.t("The partner is blocked") : "",
            className: 'col-10 pb-2 pl-4 red-label',
        }, {
            label: i18n.t(Textes.primary_name),
            input: FormElements.input({
                name: shippingAddressFieldNames.shipping_address_primary_name,
                type: 'text',
                className: 'widest-input w-100',
                disabled: inactiveFields.includes(shippingAddressFieldNames.shipping_address_primary_name),
                maxLength: 255,
            }),
            className: 'col-12 pb-2',
            wrapClass: 'd-flex flex-row justify-content-between',
            classNameRight: 'col-10 pr-0',
            classNameLeft: 'd-flex align-items-center col-2 pl-0',
        }, {
            label: i18n.t(Textes.secondary_name),
            input: FormElements.input({
                name: shippingAddressFieldNames.shipping_address_secondary_name,
                type: 'text',
                className: 'widest-input w-100',
                disabled: inactiveFields.includes(shippingAddressFieldNames.shipping_address_secondary_name),
                maxLength: 255,
            }),
            className: 'col-12 pb-2',
            wrapClass: 'd-flex flex-row justify-content-between',
            classNameRight: 'col-10 pr-0',
            classNameLeft: 'd-flex align-items-center col-2 pl-0',
        }, {
            label: i18n.t(Textes.street),
            input: FormElements.input({
                name: shippingAddressFieldNames.shipping_address_street,
                className: 'widest-input',
                disabled: inactiveFields.includes(shippingAddressFieldNames.shipping_address_street),
                maxLength: 60,
            }),
            className: 'col-6 pb-2 pr-0',
            wrapClass: 'd-flex flex-row justify-content-between',
            classNameLeft: 'd-flex align-items-center col-4 p-0',
            classNameRight: 'col-8 pr-0',
        }, {
            label: i18n.t(Textes.house_number),
            input: FormElements.input({
                name: shippingAddressFieldNames.shipping_address_house_number,
                className: 'widest-input',
                disabled: inactiveFields.includes(shippingAddressFieldNames.shipping_address_house_number),
                maxLength: 10,
            }),
            className: 'col-6 pb-2',
            wrapClass: 'd-flex flex-row justify-content-between',
            classNameLeft: 'd-flex align-items-center col-4 p-0',
            classNameRight: 'col-8 pr-0',
        }, {
            label: i18n.t(Textes.post_code),
            input: FormElements.input({
                name: shippingAddressFieldNames.shipping_address_post_code,
                className: 'widest-input',
                disabled: inactiveFields.includes(shippingAddressFieldNames.shipping_address_post_code),
                maxLength: 10,
            }),
            className: 'col-6 pb-2 pr-0',
            wrapClass: 'd-flex flex-row justify-content-between',
            classNameLeft: 'd-flex align-items-center col-4 p-0',
            classNameRight: 'col-8 pr-0',
        }, {
            label: i18n.t(Textes.city),
            input: FormElements.input({
                name: shippingAddressFieldNames.shipping_address_city,
                className: 'widest-input',
                disabled: inactiveFields.includes(shippingAddressFieldNames.shipping_address_city),
                maxLength: 40,
            }),
            className: 'col-6 pb-2',
            wrapClass: 'd-flex flex-row justify-content-between',
            classNameLeft: 'd-flex align-items-center col-4 p-0',
            classNameRight: 'col-8 pr-0',
        }, {
            label: i18n.t(Textes.country),
            input: FormElements.select({
                name: shippingAddressFieldNames.shipping_address_country,
                className: 'widest-input',
                disabled: inactiveFields.includes(shippingAddressFieldNames.shipping_address_country),
                maxLength: 30,
                options: options.countries || [],
            }),
            className: 'col-6 pb-2 pr-0',
            wrapClass: 'd-flex flex-row justify-content-between',
            classNameLeft: 'd-flex align-items-center col-4 p-0',
            classNameRight: 'col-8 pr-0',
        }
    ];
}
