import * as React from 'react';
import { Alert } from 'reactstrap';
import { connect } from 'react-redux';
import { RootState, RootAction } from '../store/types';
import { getNotificationMessages, getAlertTimeout } from '../selectors';
import { hideNotificationMessage } from '../actions/app';

interface AlertProps {
    notificationMessages?: { [index: string]: { [index: string]: string } };
    hideNotificationMessage?: (key: string) => void
}
const useEffect = (callback: () => void, timeout: number) => {
    const timer = setTimeout(() => {
        callback();
    }, timeout);
    return () => clearTimeout(timer);
};

export class AlertComponent extends React.PureComponent<AlertProps, {}> {
    state: {
        timeouts:  {[index: string]: { id: string, timeout: () => void } }
    } = {timeouts: {}};
    addTimeout(key: string, id: string) {
        if(this.state && this.state.timeouts && this.state.timeouts[key]) {
            if(this.state.timeouts[key].id === id) {
                return;
            }
            this.removeTimeout(key);
        }
        const timeout = useEffect(() => this.props.hideNotificationMessage(key), getAlertTimeout());
        const timeouts = this.state ? this.state.timeouts : {};
        timeouts[key] =  {id, timeout };
        this.setState({ timeouts });
    } 
    removeTimeout(key: string) {
        if(this.state && this.state.timeouts && this.state.timeouts[key]) {
            this.state.timeouts[key].timeout();
            const { [key]: old,  ...timeouts } = this.state.timeouts;
            this.setState({ timeouts });
        }
    }
    componentWillReceiveProps() {
        Object.keys(this.props.notificationMessages).map((key) => 
            !!this.props.notificationMessages[key].display && this.addTimeout(key,this.props.notificationMessages[key].id)
        );
    }

    render() {
        return (
            <div className="alert-container">
                {Object.keys(this.props.notificationMessages).map((key) => {
                    const alert = this.props.notificationMessages[key];
                    return (
                        <Alert key={key} color={String(alert.color)} isOpen={!!alert.display} toggle={() => {
                            this.removeTimeout(key);
                            this.props.hideNotificationMessage(key)
                        }}>
                            {alert.message}
                        </Alert>)
                })}
            </div>)
    }
}

const mapStateToProps = (state: RootState, ownProps: AlertProps) => {
    return {
        notificationMessages: getNotificationMessages(state),
    }
};
const mapDispatchToProps = (
    dispatch: (action: RootAction) => void,
    ownProps: AlertProps
) => {
    return {
        hideNotificationMessage(key: string) {
            dispatch(hideNotificationMessage(key));
        },
    };
};

const AlertContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(AlertComponent);

export default AlertContainer;