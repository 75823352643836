import { RootState } from '../store/types.d'
import { createSelector } from 'reselect';
import { Data } from '../reducers/data';
import { getPurchaseRawData, getSalesRawData } from './data';
import { FORM_TRADING_MODE, FORM_BILLING_DATA, Modes, valuesToDisplayComp } from '../data/constants';
import * as prop from 'ramda/src/prop';
import { SapTables } from '../reducers/trading';

export const getBillingFormValues = (state: RootState) => {
    return state.form[FORM_BILLING_DATA] && prop('values', state.form[FORM_BILLING_DATA]);
}

export const getInvoiceDate = createSelector(
    [getBillingFormValues],
    (billingFormValues) => billingFormValues && billingFormValues.invoice_date
)

export const getMode = (state: RootState) => {
    const values = prop('values', state.form[FORM_TRADING_MODE]);
    return values && values.mode_select;
}
export const getSelectedPurchase = (state: RootState): string[] =>
    state.trading.selectedPurchase;

export const getSelectedSales = (state: RootState): string[] =>
    state.trading.selectedSale;

export const isCompSalesMain = createSelector(
    [getMode, getSelectedPurchase],
    (mode, selected) => mode === Modes.buy_sell && selected && selected.length > 1
)

export const isCompPurchaseMain = createSelector(
    [getMode, getSelectedSales],
    (mode, selected) => mode === Modes.buy_sell && selected && selected.length > 1
)

export const getActiceSapTable = (state: RootState): string =>
    state.trading.activeSapTable;

export const getSelectedSapItems = (state: RootState): string[] =>
    state.trading.selectedForSAP;

export const getSelectedSapPurchaseItems = createSelector(
    [getActiceSapTable, getSelectedSapItems],
    (actice, selected) => actice === SapTables.purchase ? selected : []
)

export const getSelectedSapSaleItems = createSelector(
    [getActiceSapTable, getSelectedSapItems],
    (actice, selected) => actice === SapTables.sale ? selected : []
)

const getSalesById = (state: RootState, id: string) => {
    const sales = getSalesRawData(state);
    return sales && sales[id];
}

const getPurchaseById = (state: RootState, id: string) => {
    const purch = getPurchaseRawData(state);
    return purch && purch[id];
}

export const isLockedSalePosition = (state: RootState, id: string): boolean => {
    const sales = getSalesById(state, id);
    return !!sales.locked;
}

export const isLockedPurchasePosition = (state: RootState, id: string): boolean => {
    const purch = getPurchaseById(state, id);
    return !!purch.locked;
}

export const lockedSaleByAnotherUser = (state: RootState, id: string): boolean => {
    const sales = getPurchaseById(state, id);
    if (!!sales.locked && sales.locked_by !== state.session.user) {
        return true;
    }
    return false;
}

export const lockedPurchaseByAnotherUser = (state: RootState, id: string): boolean => {
    const purch = getPurchaseById(state, id);
    if (!!purch.locked && purch.locked_by !== state.session.user) {
        return true;
    }
    return false;
}

export const isCompSalesAllowToSelect = (state: RootState, id: string): boolean => {
    if (isCompPurchaseMain(state)) {
        const purchase = getSelectedPurchase(state);
        if (!purchase || purchase.length === 0) {
            return true;
        }
        const mainPurch = getPurchaseById(state, purchase[0]);
        let unmatchedWeight: number = Number(mainPurch.unmatched_weight);

        const selected = getSelectedSales(state);

        selected && selected.filter(el => el != id).map(saleId => {
            const sale = getSalesById(state, saleId);
            unmatchedWeight -= Number(sale.unmatched_weight);
        })

        return unmatchedWeight != 0;
    }
    return true
}


export const isCompPurchaseAllowToSelect = (state: RootState, id: string): boolean => {
    if (isCompSalesMain(state)) {
        const sales = getSelectedSales(state);
        if (!sales || sales.length === 0) {
            return true;
        }
        const mainSale = getSalesById(state, sales[0]);
        let unmatchedWeight: number = Number(mainSale.unmatched_weight);

        const selected = getSelectedPurchase(state);

        selected && selected.filter(el => el != id).map(purchase => {
            const purch = getPurchaseById(state, purchase);
            unmatchedWeight -= Number(purch.unmatched_weight);
        })

        return unmatchedWeight != 0;
    }
    return true
}

export const allowMatch = (state: RootState): boolean => {
    let purchaseUnmatchedWeight = 0;
    const purchase = getSelectedPurchase(state);
    if (purchase && purchase.length !== 0) {
      const selected = getSelectedPurchase(state);

      selected && selected.map(purchase => {
          const purch = getPurchaseById(state, purchase);
          purchaseUnmatchedWeight += Number(purch.unmatched_weight);
      })
    }
    let salesUnmatchedWeight = 0;

    const selected = getSelectedSales(state);
    selected && selected.map(saleId => {
        const sale = getSalesById(state, saleId);
        salesUnmatchedWeight += Number(sale.unmatched_weight);
    })

    return selected && selected.length>0 && purchase && purchase.length >0 && purchaseUnmatchedWeight >= 0 && salesUnmatchedWeight >= 0;
}

const getSelectedMaterial = createSelector(
    [getPurchaseRawData, getSalesRawData, getSelectedPurchase, getSelectedSales],
    (dataPurchase, dataSales, selectedPurchase, selectedSale) => {

        let data: Data;
        let selectedProductId: string;
        if (selectedPurchase && selectedPurchase.length > 0) {
            data = dataPurchase;
            selectedProductId = selectedPurchase[0];
        } else if (selectedSale && selectedSale.length > 0) {
            data = dataSales;
            selectedProductId = selectedSale[0];
        }
        if (!data && !selectedProductId) {
            return null;
        }

        const selectedProduct = data[selectedProductId];
        const selectedMaterial = selectedProduct && String(selectedProduct.material_code);
        return selectedMaterial;
    }
)

export const mapCompensationData = (rawValues: Data, selectedMaterial: string) => {
    if (!rawValues) {
        return rawValues;
    }
    const values: { [index: string]: Data } = {};
    Object.keys(rawValues).map(key => {
        if ((!selectedMaterial || (selectedMaterial && rawValues[key].material_code === selectedMaterial)) && !rawValues[key].canceled && rawValues[key].unmatched_weight != 0) {
            const id = String(rawValues[key].id);
            values[id] = {};
            valuesToDisplayComp.map((p: string) => values[id][p] = { [p]: rawValues[key][p] });
        }
    });
    return values;
};

export const getCompPurchaseData = createSelector(
    [getPurchaseRawData, getSelectedMaterial],
    (data, selected) => mapCompensationData(data, selected)
)

export const getCompSalesData = createSelector(
    [getSalesRawData, getSelectedMaterial],
    (data, selected) => mapCompensationData(data, selected)
)
