import * as R from 'ramda';

export type GenericMap = { [id: string]: string | number | boolean };

export type ColumnSizes = {
  sm?: number;
  md?: number;
  lg?: number;
  xxl?: number;
};

// getColString :: (string, number) -> string
const getColString = (key: string, value: number) => `col-${key}-${value}`;

// pairsTostrings :: [[string|number]] -> [string]
const pairsToStrings = R.map(R.apply(getColString));

// getSizesString :: ColumnSizes -> string
const getSizesString = R.compose(R.join(' '), pairsToStrings, R.toPairs);

// thunk :: a -> () -> a
const thunk = (value: string) => () => value;

// defaultIfEmpty :: string -> string
const defaultIfEmpty = R.ifElse(R.isEmpty, R.__, R.identity);

// sizesToClass :: ColumnSizes -> string
export const sizesToClass = R.compose(defaultIfEmpty(thunk('col-sm-12')), getSizesString);
