import * as React from 'react';
import { i18nContext, I18n } from '../../../language';

const PreviousMonthLockedMessage = () => {
    return (
        <i18nContext.Consumer>
            {(i18n: I18n) =>
                <div className="">
                    {i18n.t("Previous month locked")}
                </div>
            }
        </i18nContext.Consumer>
    )
}

export default PreviousMonthLockedMessage;