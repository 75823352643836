import * as React from 'react';
import { HandledFormProps, FormBuilder } from '../../../utils/form/form';
import { i18nContext } from '../../../../language';
import PositionFields from './position-item';
import { salesOrderTypeWithAdditionalFields } from '../order/data';

const headerKeys: { [index: string]: string } = {
    cost_center: "Cost Center",
    division: "Division",
    material: "Material",
    weight: "Weight",
    unit: "Unit",
    price: "Price",
    position_price: "Position Price",
    storage_location: "Storage Location",
    tax_class: "Tax Class",
    notes: "Notes",
    delete_field: "",
};

export const Headers = (isPurchase?: boolean, showDeleteField?: boolean, showAdditionalField?: boolean): string[] => [
    headerKeys.cost_center,
    headerKeys.division,
    headerKeys.material,
    headerKeys.weight,
    headerKeys.unit,
    headerKeys.price,
    headerKeys.position_price,
    ...(isPurchase || showAdditionalField ? [headerKeys.storage_location] : []),
    ...(!isPurchase ? [headerKeys.tax_class] : []),
    headerKeys.notes,
    ...(showDeleteField ? [headerKeys.delete_field] : []),
];

const headersWidth: { [index: string]: string } = {
    [headerKeys.cost_center]: "6.5%",
    [headerKeys.division]: "6.5%",
    [headerKeys.material]: "12%",
    [headerKeys.weight]: "10%",
    [headerKeys.unit]: "5%",
};

export interface PositionsFormProps extends HandledFormProps {
    protected?: boolean;
    options?: { [index: string]: Array<{ value: string, text: string, priceUnits?: string }> };
    items?: string[];
    priceUnits?: { [index: string]: string };
    readonly?: boolean;
    showAdditionalField?: boolean;
    isPurchase?: boolean;
    weightUnits?: {
        [positionIndex: string]: string[];
    };

    deleteItem?: (id: string) => void;
}

const PositionItemForm = (props: PositionsFormProps) => {
    return (
        <i18nContext.Consumer>
            {i18n => (
                <div className="position-table-container w-100">
                    <table className="position-table w-100">
                        <thead>
                            <tr>
                                {!props.protected ? Headers(props.isPurchase, true, props.showAdditionalField).map(
                                    (h, i) => <td style={headersWidth[h] && { width: headersWidth[h] }} key={i}>
                                        {i18n.t(h)}
                                    </td>
                                ) : null}
                            </tr>
                        </thead>
                        <tbody>
                            {props.items && props.options && !props.protected && props.items.map((item: string) => {
                                const formBuilder = new FormBuilder();
                                formBuilder.setElements(PositionFields(props, item));
                                return (
                                    <tr>
                                        {formBuilder.build()}
                                        <td className="delete-btn">
                                            <div className={["btn-position-delete", "protectable", props.readonly ? "protectable--protected" : ""].join(' ')} onClick={e => props.deleteItem(item)} >
                                                <span>&times;</span>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            )}
        </i18nContext.Consumer>
    )
};

export default PositionItemForm;