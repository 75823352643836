export const FORM_LOGIN = 'form/login'
export const FORM_POSITIONS_AND_ADDITIONAL_COSTS = 'form/positions_and_additional_costs'
export const FORM_POSITIONS = 'form/positions'
export const FORM_ADDITIONAL_COSTS = 'form/additional_costs'
export const FORM_CUSTOMER_SEARCH = 'form/customer_search'
export const FORM_CUSTOMER_SELECT = 'form/customer_select'
export const FORM_ORDER_DATA = 'form/order_data'
export const FORM_VIEWING_YEAR = 'form/viewing_year'
export const FORM_TRADING_MODE = 'form/trading_mode'
export const FORM_HISTORY_USER = 'form/history_user'
export const FORM_MATERIAL = 'form/material'
export const FORM_SETTINGS = 'form/settings'
export const FORM_BILLING_DATA = 'form/billing_data'
export const FORM_SHIPPING_ADDRESS = 'form/shipping_address'

export const USER_LOGED_IN_SUCCESS = 'user/USER_LOGED_IN_SUCCESS'
export const USER_LOGED_IN_ERROR = 'user/USER_LOGED_IN_ERROR'
export const USER_SUBMIT_LOGIN_FORM = 'user/USER_SUBMIT_LOGIN_FORM'
export const USER_SUBMIT_LOGOUT = 'user/USER_SUBMIT_LOGOUT'
export const USER_LOGED_OUT = 'user/USER_LOGED_OUT'

export const SESSION_PING = 'session/SESSION_PING'
export const ITERATE_HEARTBEAT = 'session/ITERATE_HEARTBEAT'
export const RESET_HEARTBEAT = 'session/RESET_HEARTBEAT'

export const SESSION_SET_AUTHORIZATION_TOKEN = 'session/SESSION_SET_AUTHORIZATION_TOKEN'

export const APP_CHANGE_LANGUAGE = 'app/APP_CHANGE_LANGUAGE'
export const APP_GET_INIT = 'app/APP_GET_INIT'
export const APP_SET_INIT = 'app/APP_SET_INIT'
export const APP_SET_ERROR_MESSAGE = 'app/APP_SET_ERROR_MESSAGE'
export const APP_SET_SUCCESS_MESSAGE = 'app/APP_SET_SUCCESS_MESSAGE'
export const APP_SET_INFO_MESSAGE = 'app/APP_SET_INFO_MESSAGE'
export const APP_HIDE_NOTIFICATION_MESSAGE = 'app/APP_HIDE_NOTIFICATION_MESSAGE'
export const APP_UPDATE_LIMITED_VERSION = 'app/APP_UPDATE_LIMITED_VERSION'

export const APP_LOG_ERROR_MESSAGE = 'app/APP_LOG_ERROR_MESSAGE'

export const DATA_LOAD_ALL = 'data/DATA_LOAD_ALL'
export const DATA_LOAD_PURCHASE = 'data/DATA_LOAD_PURCHASE'
export const DATA_LOAD_SALES = 'data/DATA_LOAD_SALES'
export const DATA_LOAD_PURCHASE_ORDERS = 'data/DATA_LOAD_PURCHASE_ORDERS'
export const DATA_LOAD_SALES_ORDERS = 'data/DATA_LOAD_SALES_ORDERS'
export const DATA_LOAD_SAP_ORDERS = 'data/DATA_LOAD_SAP_ORDERS'
export const DATA_LOAD_BILLING_SAP_ORDERS = 'data/DATA_LOAD_BILLING_SAP_ORDERS'
export const DATA_LOAD_DELIVERING_SAP_ORDERS = 'data/DATA_LOAD_DELIVERING_SAP_ORDERS'
export const DATA_LOAD_CUSTOMERS = 'data/DATA_LOAD_CUSTOMERS'
export const DATA_LOAD_DASHBOARD = 'data/DATA_LOAD_DASHBOARD'

export const DATA_LOAD_LOGS = 'data/DATA_LOAD_LOGS'
export const DATA_SET_LOGS = 'data/DATA_SET_LOGS'
export const DATA_RESET_LOGS = 'data/DATA_RESET_LOGS'

export const DATA_LOAD_REPORTS = 'data/DATA_LOAD_REPORTS'
export const DATA_SET_REPORTS = 'data/DATA_SET_REPORTS'
export const DATA_RESET_REPORTS = 'data/DATA_RESET_REPORTS'

export const DATA_LOAD_HISTORY = 'data/DATA_LOAD_HISTORY'
export const DATA_LOAD_USER_HISTORY = 'data/DATA_LOAD_USER_HISTORY'
export const DATA_UPDATE_HISTORY = 'data/DATA_UPDATE_HISTORY'
export const DATA_RESET_HISTORY = 'data/DATA_RESET_HISTORY'

export const DATA_UPDATE_PURCHASE = 'data/DATA_UPDATE_PURCHASE'
export const DATA_UPDATE_SALES = 'data/DATA_UPDATE_SALES'
export const DATA_UPDATE_PURCHASE_ORDERS = 'data/DATA_UPDATE_PURCHASE_ORDERS'
export const DATA_UPDATE_SALES_ORDERS = 'data/DATA_UPDATE_SALES_ORDERS'
export const DATA_UPDATE_PURCHASE_STATUS = 'data/DATA_UPDATE_PURCHASE_STATUS'
export const DATA_UPDATE_SALES_STATUS = 'data/DATA_UPDATE_SALES_STATUS'
export const DATA_UPDATE_MATERIALS_STATUS = 'data/DATA_UPDATE_MATERIALS_STATUS'
export const DATA_UPDATE_CUSTOMERS = 'data/DATA_UPDATE_CUSTOMERS'
export const DATA_UPDATE_DASHBOARD = 'data/DATA_UPDATE_DASHBOARD'

export const DATA_SET_PURCHASE = 'data/DATA_SET_PURCHASE'
export const DATA_SET_SALES = 'data/DATA_SET_SALES'

export const DATA_REFRESH_PURCHASE = 'data/DATA_REFRESH_PURCHASE'
export const DATA_REFRESH_SALES = 'data/DATA_REFRESH_SALES'

export const DATA_RESET_ORDERS = 'data/DATA_RESET_ORDERS'

export const DATA_ORDER_CREATE_POSITION_ITEM = 'data/DATA_ORDER_CREATE_POSITION_ITEM'
export const DATA_ORDER_ADD_POSITION_ITEM = 'data/DATA_ORDER_ADD_POSITION_ITEM'
export const DATA_ORDER_DELETE_POSITION_ITEM = 'data/DATA_ORDER_DELETE_POSITION_ITEM'
export const DATA_ORDER_DELETE_ALL_POSITION_ITEMS = 'data/DATA_ORDER_DELETE_ALL_POSITION_ITEMS'
export const DATA_ORDER_CREATE_ADDITIONAL_COST_ITEM = 'data/DATA_ORDER_CREATE_ADDITIONAL_COST_ITEM'
export const DATA_ORDER_ADD_ADDITIONAL_COST_ITEM = 'data/DATA_ORDER_ADD_ADDITIONAL_COST_ITEM'
export const DATA_ORDER_DELETE_ADDITIONAL_COST_ITEM = 'data/DATA_ORDER_DELETE_ADDITIONAL_COST_ITEM'
export const DATA_ORDER_DELETE_ALL_ADDITIONAL_COST_ITEMS = 'data/DATA_ORDER_DELETE_ALL_ADDITIONAL_COST_ITEMS'
export const DATA_ORDER_CLEAR = 'data/DATA_ORDER_CLEAR'
export const DATA_ORDER_SET_CUSTOMER_PRESELECT = 'data/DATA_ORDER_SET_CUSTOMER_PRESELECT'
export const DATA_ORDER_SET_CUSTOMER_PARTNERS = 'data/DATA_ORDER_SET_CUSTOMER_PARTNERS'

export const DATA_ORDER_EDIT_LOCK = 'data/DATA_ORDER_EDIT_LOCK'
export const DATA_ORDER_EDIT_UNLOCK = 'data/DATA_ORDER_EDIT_UNLOCK'
export const DATA_ORDER_IS_EDITED = 'data/DATA_ORDER_IS_EDITED'

export const DATA_ORDER_DETAILS_TRIGGER_SET = 'data/DATA_ORDER_DETAILS_TRIGGER_SET'
export const DATA_ORDER_DETAILS_TRIGGER_RESET = 'data/DATA_ORDER_DETAILS_TRIGGER_RESET'

export const DATA_CREATE_PURCHASE = 'data/DATA_CREATE_PURCHASE'
export const DATA_CREATE_SALES = 'data/DATA_CREATE_SALES'
export const DATA_GET_PURCHASE_DETAILS = 'data/DATA_GET_PURCHASE_DETAILS'
export const DATA_GET_SALES_DETAILS = 'data/DATA_GET_SALES_DETAILS'
export const DATA_SET_DETAILS = 'data/DATA_SET_DETAILS'
export const DATA_UPDATE_DETAILS = 'data/DATA_UPDATE_DETAILS'
export const DATA_RESET_DETAILS = 'data/DATA_RESET_DETAILS'
export const DATA_DELETE_PURCHASE_ORDER = 'data/DATA_DELETE_PURCHASE_ORDER'
export const DATA_DELETE_SALES_ORDER = 'data/DATA_DELETE_SALES_ORDER'
export const DATA_DELETE_PURCHASE_DELIVERY = 'data/DATA_DELETE_PURCHASE_DELIVERY'
export const DATA_DELETE_SALES_DELIVERY = 'data/DATA_DELETE_SALES_DELIVERY'
export const DATA_DELETE_PURCHASE_INVOICE = 'data/DATA_DELETE_PURCHASE_INVOICE'
export const DATA_DELETE_SALES_INVOICE = 'data/DATA_DELETE_SALES_INVOICE'
export const DATA_CHANGE_SALES_ORDER = 'data/DATA_CHANGE_SALES_ORDER'
export const DATA_CHANGE_PURCHASE_ORDER = 'data/DATA_CHANGE_PURCHASE_ORDER'

export const DATA_SET_SHIPPING_ADDRESS_FORM_IS_DISPLAYED = 'data/DATA_SET_SHIPPING_ADDRESS_FORM_DISPLAY'

export const DATA_SET_SELECTED_CUSTOMER_IS_BLOCKED = 'data/DATA_SET_SELECTED_CUSTOMER_IS_BLOCKED'
export const DATA_SET_SELECTED_CUSTOMER_SHIPPING_PARTNER_IS_BLOCKED = 'data/DATA_SET_SELECTED_CUSTOMER_SHIPPING_PARTNER_IS_BLOCKED'

export const DATA_CREATE_REQUEST_STATUS_UPDATE = 'data/DATA_CREATE_REQUEST_STATUS_UPDATE'

export const DATA_EXPORT = 'data/DATA_EXPORT'

export const DATA_DOWNLOAD_REPORT = 'data/DATA_DOWNLOAD_REPORT'

export const DATA_LOAD_METADATA = 'data/DATA_LOAD_METADATA'
export const DATA_UPDATE_DIVISIONS = 'data/DATA_UPDATE_DIVISIONS'
export const DATA_UPDATE_WAREHOUSES = 'data/DATA_UPDATE_WAREHOUSES'
export const DATA_UPDATE_SHIPPING_TERMS = 'data/DATA_UPDATE_SHIPPING_TERMS'
export const DATA_UPDATE_TERMS_OF_PAYMENT = 'data/DATA_UPDATE_TERMS_OF_PAYMENT'
export const DATA_UPDATE_COST_CENTERS = 'data/DATA_UPDATE_COST_CENTERS'
export const DATA_UPDATE_ADDITIONAL_COSTS = 'data/DATA_UPDATE_ADDITIONAL_COSTS'
export const DATA_UPDATE_COUNTRIES = 'data/DATA_UPDATE_COUNTRIES'
export const DATA_UPDATE_REPRESENTATIVES = 'data/DATA_UPDATE_REPRESENTATIVES'
export const DATA_UPDATE_FEATURES_AVAILABILITY = 'data/DATA_UPDATE_FEATURES_AVAILABILITY'
export const DATA_UPDATE_PREV_MONTH_LOCKED = 'data/DATA_UPDATE_PREV_MONTH_LOCKED'
export const DATA_UPDATE_CURRENCIES = 'data/DATA_UPDATE_CURRENCIES'

export const DATA_UPDATE_SETTINGS = 'data/DATA_UPDATE_SETTINGS'
export const DATA_RESET_SETTINGS = 'data/DATA_RESET_SETTINGS'
export const DATA_CHANGE_SETTINGS = 'data/DATA_CHANGE_SETTINGS'

export const DATA_UPDATE_MATERIALS = 'data/DATA_UPDATE_MATERIALS'
export const DATA_EDIT_MATERIAL = 'data/DATA_EDIT_MATERIAL'
export const DATA_ADD_MATERIAL = 'data/DATA_ADD_MATERIAL'
export const DATA_SET_MATERIAL_TO_FORM = 'data/DATA_SET_MATERIAL_TO_FORM'

export const SEARCH_START = 'search/SEARCH_START'
export const SEARCH_CLEAR = 'search/SEARCH_CLEAR'
export const SEARCH_SET = 'search/SEARCH_SET'
export const SEARCH_IN_ORDER_SUBMIT = 'search/SEARCH_IN_ORDER_SUBMIT'
export const SET_ACTIVE_SEARCH = 'search/SET_ACTIVE_SEARCH'

export const TRADING_MODE_CHANGED = 'data/TRADING_MODE_CHANGED'
export const TRADING_PURCHASE_SELECT = 'data/TRADING_PURCHASE_SELECT'
export const TRADING_PURCHASE_LOCK = 'data/TRADING_PURCHASE_LOCK'
export const TRADING_PURCHASE_CANCEL_LOCK = 'data/TRADING_PURCHASE_CANCEL_LOCK'
export const TRADING_PURCHASE_UNLOCK = 'data/TRADING_PURCHASE_UNLOCK'
export const TRADING_SALE_SELECT = 'data/TRADING_SALE_SELECT'
export const TRADING_SALE_LOCK = 'data/TRADING_SALE_LOCK'
export const TRADING_SALE_CANCEL_LOCK = 'data/TRADING_SALE_CANCEL_LOCK'
export const TRADING_SALE_UNLOCK = 'data/TRADING_SALE_UNLOCK'
export const TRADING_PURCHASE_CANCEL = 'data/TRADING_PURCHASE_CANCEL'
export const TRADING_SALE_CANCEL = 'data/TRADING_SALE_CANCEL'
export const TRADING_MATCH_CONFIRM = 'data/TRADING_MATCH_CONFIRM'
export const TRADING_RESET_ALL = 'data/TRADING_RESET_ALL'
export const TRADING_RESET_MODE = 'data/TRADING_RESET_MODE'
export const TRADING_UNLOCK_ALL = 'data/TRADING_UNLOCK_ALL'

export const SAP_SELECT_ALL_PURCHASE = 'sap/SAP_SELECT_ALL_PURCHASE'
export const SAP_SELECT_ALL_SALE = 'sap/SAP_SELECT_ALL_SALE'
export const SAP_SELECT_ALL_ACTIVE = 'sap/SAP_SELECT_ALL_ACTIVE'
export const SAP_SET_ITEMS = 'sap/SAP_SET_ITEMS'
export const SAP_SELECT_ITEM = 'sap/SAP_SELECT_ITEM'
export const SAP_RESET_ITEMS = 'sap/SAP_RESET_ITEMS'
export const SAP_SENT_TO_SAP = 'sap/SAP_SENT_TO_SAP'
export const SAP_BILLING_TO_SAP = 'sap/SAP_BILLING_TO_SAP'
export const SAP_DELIVERING_TO_SAP = 'sap/SAP_DELIVERING_TO_SAP'
export const SAP_SET_ACTIVE_TABLE = 'sap/SAP_SET_ACTIVE_TABLE'

export const SET_SELECTED_TAB = 'order/SET_SELECTED_TAB'

export const CONNECT_SOCKET = 'socket/CONNECT_SOCKET'
export const SOCKET_DATA_UPDATE = 'socket/SOCKET_DATA_UPDATE'
export const DISCONNECT_SOCKET = 'socket/DISCONNECT_SOCKET'

export const SALES_POSITION_CONTEXT_MENU_ID = 'position-context-menu-sales';
export const PURCHASE_POSITION_CONTEXT_MENU_ID = 'position-context-menu-purchase';
export const MATERIALS_CONTEXT_MENU_ID = 'materials-menu-sales';

export const Modes = {
    buy_sell: 'BUY_SELL',
    inventory: 'INVENORY',
    profits: 'PROFITS_LOSSES'
};

export const Statuses = {
    new: 'NEW',
}

export const ExportFileNames: { [index: string]: string } = {
    exportSales: 'Sale',
    exportPurchases: 'Purchase',
    exportMatches: 'Matches',
    exportInventory: 'Inventory',
    exportPnL: 'PnL',
    exportMixed: 'Mixed',
    exportMixedWithMatch: 'MixedWithMatch',
    exportMixedNeedsMatch: 'MixedNeedsMatch',
}

export const valuesToDisplay = ['id', 'order_date', 'cost_center', 'division', 'material_code', 'weight', 'unit_price', 'name', 'order_no', 'unmatched_weight', 'external_ref_number', 'relevant_for_sap', 'sap_order_id', 'delivery_note_id', 'invoice_id', 'canceled', 'status', 'prev_month_lock'];

export const valuesToDisplayComp = ['id', 'name', 'order_date', 'unit_price', 'material_code', 'unmatched_weight'
    //   'locked'
];

export const valuesToDisplayInv = ['id', 'keep_an_eye_on_it', 'order_date', 'created_by', 'order_no', 'terms_of_payment', 'order_type', 'vat_transaction_indicator', 'name', 'positions', 'additional_costs'];

export const valuesToDisplayDelivering = ['id', 'keep_an_eye_on_it', 'order_date', 'created_by', 'order_no', 'terms_of_payment', 'order_type', 'vat_transaction_indicator', 'name', 'positions', 'additional_costs'];

export const valuesToDisplayBilling = ['id', 'order_date', 'cost_center', 'division', 'material_code', 'weight', 'position_price', 'name', 'order_type', 'unmatched_weight',
    // 'locked',
    'sap_id', 'name', 'positions', 'additional_costs'];

export const valuesToDisplayHistory = ['id', 'created_by', 'created_at', 'action', 'affected_field', 'affected_object', 'new_value', 'old_value', 'object_id', 'matched_to'];

export const valuesToDisplayCustomer = ['sap_id', 'name', 'address', 'internal'];

export const valuesToDisplayMaterials = ['id', 'material_code', 'name', 'multiplier', 'price_unit', 'weight_unit', 'material_number', 'color', 'status'];

export const valuesToDisplayLogs = ['id', 'created_at', 'login', 'call_type', 'code', 'severity', 'function', 'request', 'response'];

export const valuesToDisplayReports = ['id', 'created_at', 'filename', 'download'];

export const defaultCustomerSearch = 'customer_id';
