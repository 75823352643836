import * as React from 'react';
import { HandledFormProps, FormBuilder } from '../../../utils/form/form';
import { i18nContext } from '../../../../language';
import AdditionalCostFields from './additional-cost-item';
import { salesOrderTypeWithAdditionalFields } from '../order/data';

const headerKeys: {[index: string]: string} = {
    cost_code: "Cost Code",
    amount: "Amount",
    costs: "Costs",
    delete_field: "",
};

export const Headers = (): string[] => Object.values(headerKeys);

const headersWidth: {[index: string]: string} = {
    [headerKeys.cost_code]: "25%",
    [headerKeys.amount]: "14%",
    [headerKeys.costs]: "18%",
};

export interface AdditionalCostsFormProps extends HandledFormProps {
    protected?: boolean;
    options?: { [index: string]: Array<{ value: string, text: string, priceUnits?: string }> };
    items?: string[];
    readonly?: boolean;
    priceUnits?: { [index: string]: string };

    deleteItem?: (id: string) => void;
}

const AdditionalCostItemForm = (props: AdditionalCostsFormProps) => {
    return (
        <i18nContext.Consumer>
            {i18n => (
                <div className="additional-cost-table-container w-100">
                    {props.items && props.items.length > 0 && props.options && !props.protected && (
                        <table className="additional-cost-table w-100">
                            <thead>
                                <tr>
                                    {Headers().map((h, i) => <td style={headersWidth[h] && { width: headersWidth[h] }} key={i}>
                                        {i18n.t(h)}
                                    </td>)}
                                </tr>
                            </thead>
                            <tbody>
                                {props.items.map((item: string) => {
                                    const formBuilder = new FormBuilder();
                                    formBuilder.setElements(AdditionalCostFields(props, item));
                                    return (
                                        <tr>
                                            {formBuilder.build()}
                                            <td className="delete-btn">
                                                <div className={["btn-position-delete", "protectable", props.readonly ? "protectable--protected" : ""].join(' ')} onClick={e => props.deleteItem(item)} >
                                                    <span>&times;</span>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    )}
                </div>
            )}
        </i18nContext.Consumer>
    )
};

export default AdditionalCostItemForm;