import * as React from 'react';
import { default as PurchaseBar } from './trading/purchase';
import { default as SalesBar } from './trading/sales';
import CompensationMode from './trading/mode';
import { getCompTableHeaders } from '../tables/tabtable-components';

class TradingBar extends React.PureComponent<{}> {
    render() {
        return (
            <div className="compensation-table d-flex flex-row justify-content-between">
                <PurchaseBar title="Purchase" className="col-5 p-0" customeHeaders={getCompTableHeaders()} />
                <CompensationMode />
                <SalesBar title="Sale" className="col-5 p-0" customeHeaders={getCompTableHeaders()} />
            </div>)
    }
}

export default TradingBar; 