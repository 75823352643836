import { action } from 'typesafe-actions';
import {
    DATA_LOAD_ALL,
    DATA_UPDATE_PURCHASE,
    DATA_UPDATE_SALES,
    DATA_UPDATE_CUSTOMERS,
    DATA_LOAD_METADATA,
    DATA_UPDATE_MATERIALS,
    DATA_UPDATE_DIVISIONS,
    DATA_UPDATE_COST_CENTERS,
    DATA_UPDATE_ADDITIONAL_COSTS,
    DATA_UPDATE_COUNTRIES,
    DATA_UPDATE_REPRESENTATIVES,
    DATA_UPDATE_FEATURES_AVAILABILITY,
    DATA_CREATE_PURCHASE,
    DATA_CREATE_SALES,
    DATA_UPDATE_DASHBOARD,
    DATA_CREATE_REQUEST_STATUS_UPDATE,
    DATA_LOAD_PURCHASE,
    DATA_LOAD_SALES,
    DATA_LOAD_DASHBOARD,
    DATA_ORDER_ADD_POSITION_ITEM,
    DATA_ORDER_DELETE_POSITION_ITEM,
    DATA_ORDER_DELETE_ALL_POSITION_ITEMS,
    DATA_ORDER_CLEAR,
    DATA_ORDER_CREATE_POSITION_ITEM,
    DATA_ORDER_CREATE_ADDITIONAL_COST_ITEM,
    DATA_ORDER_ADD_ADDITIONAL_COST_ITEM,
    DATA_ORDER_DELETE_ADDITIONAL_COST_ITEM,
    DATA_ORDER_DELETE_ALL_ADDITIONAL_COST_ITEMS,
    DATA_ORDER_SET_CUSTOMER_PARTNERS,
    DATA_EXPORT,
    DATA_GET_PURCHASE_DETAILS,
    DATA_GET_SALES_DETAILS,
    DATA_DELETE_SALES_ORDER,
    DATA_DELETE_PURCHASE_ORDER,
    DATA_RESET_DETAILS,
    DATA_SET_DETAILS,
    DATA_CHANGE_SALES_ORDER,
    DATA_CHANGE_PURCHASE_ORDER,
    DATA_LOAD_HISTORY,
    DATA_ORDER_EDIT_LOCK,
    DATA_ORDER_EDIT_UNLOCK,
    DATA_LOAD_USER_HISTORY,
    DATA_UPDATE_HISTORY,
    DATA_UPDATE_PURCHASE_STATUS,
    DATA_UPDATE_SALES_STATUS,
    DATA_SET_MATERIAL_TO_FORM,
    DATA_EDIT_MATERIAL,
    DATA_ADD_MATERIAL,
    DATA_LOAD_LOGS,
    DATA_UPDATE_MATERIALS_STATUS,
    DATA_SET_LOGS,
    DATA_ORDER_DETAILS_TRIGGER_SET,
    DATA_ORDER_DETAILS_TRIGGER_RESET,
    DATA_RESET_LOGS,
    DATA_RESET_HISTORY,
    DATA_UPDATE_SETTINGS,
    DATA_RESET_SETTINGS,
    DATA_CHANGE_SETTINGS,
    DATA_UPDATE_WAREHOUSES,
    DATA_UPDATE_PURCHASE_ORDERS,
    DATA_UPDATE_SALES_ORDERS,
    DATA_LOAD_PURCHASE_ORDERS,
    DATA_LOAD_SALES_ORDERS,
    DATA_LOAD_SAP_ORDERS,
    DATA_RESET_ORDERS,
    DATA_LOAD_BILLING_SAP_ORDERS,
    DATA_LOAD_DELIVERING_SAP_ORDERS,
    DATA_DOWNLOAD_REPORT,
    DATA_LOAD_REPORTS,
    DATA_SET_REPORTS,
    DATA_RESET_REPORTS,
    DATA_UPDATE_DETAILS,
    DATA_ORDER_IS_EDITED,
    DATA_SET_SHIPPING_ADDRESS_FORM_IS_DISPLAYED,
    DATA_SET_SELECTED_CUSTOMER_IS_BLOCKED,
    DATA_SET_SELECTED_CUSTOMER_SHIPPING_PARTNER_IS_BLOCKED,
    DATA_REFRESH_PURCHASE,
    DATA_REFRESH_SALES,
    DATA_SET_PURCHASE,
    DATA_SET_SALES,
    DATA_UPDATE_PREV_MONTH_LOCKED,
    DATA_UPDATE_CURRENCIES,
    DATA_DELETE_PURCHASE_DELIVERY,
    DATA_DELETE_SALES_DELIVERY,
    DATA_DELETE_PURCHASE_INVOICE,
    DATA_DELETE_SALES_INVOICE
} from '../data/constants';

import { Data, CustomerPartner } from '../reducers/data';
import { DATA_UPDATE_SHIPPING_TERMS, DATA_UPDATE_TERMS_OF_PAYMENT, DATA_ORDER_SET_CUSTOMER_PRESELECT } from '../data/constants';

export const loadAllData = () => action(DATA_LOAD_ALL);

export const loadPurchaseOrders = () => action(DATA_LOAD_PURCHASE_ORDERS);
export const loadSalesOrders = () => action(DATA_LOAD_SALES_ORDERS);
export const loadSapOrders = () => action(DATA_LOAD_SAP_ORDERS);
export const loadBillSapOrders = () => action(DATA_LOAD_BILLING_SAP_ORDERS);
export const loadDeliveringSapOrders = () => action(DATA_LOAD_DELIVERING_SAP_ORDERS);

export const loadMetaData = () => action(DATA_LOAD_METADATA);

export const updatePurchase = (data: Data) =>
    action(DATA_UPDATE_PURCHASE, { data });
export const updateSales = (data: Data) =>
    action(DATA_UPDATE_SALES, { data });

export const setPurchase = (data: Data) =>
    action(DATA_SET_PURCHASE, { data });
export const setSales = (data: Data) =>
    action(DATA_SET_SALES, { data });

export const updatePurchaseOrders = (data: Data) =>
    action(DATA_UPDATE_PURCHASE_ORDERS, { data });
export const updateSalesOrders = (data: Data) =>
    action(DATA_UPDATE_SALES_ORDERS, { data });

export const updatePurchaseStatus = (data: Data) =>
    action(DATA_UPDATE_PURCHASE_STATUS, { data });
export const updateSalesStatus = (data: Data) =>
    action(DATA_UPDATE_SALES_STATUS, { data });
export const updateMaterialsStatus = (data: Data) =>
    action(DATA_UPDATE_MATERIALS_STATUS, { data });

export const updateCustomers = (data: Data) =>
    action(DATA_UPDATE_CUSTOMERS, { data });
export const updateMaterials = (data: Data) =>
    action(DATA_UPDATE_MATERIALS, { data });
export const updateDivisions = (data: Data) =>
    action(DATA_UPDATE_DIVISIONS, { data });
export const updateCostCenters = (data: Data) =>
    action(DATA_UPDATE_COST_CENTERS, { data });
export const updateWarehouses = (data: Data) =>
    action(DATA_UPDATE_WAREHOUSES, { data });
export const updateShippingTerms = (data: Data) =>
    action(DATA_UPDATE_SHIPPING_TERMS, { data });
export const updateTermsOfPayment = (data: Data) =>
    action(DATA_UPDATE_TERMS_OF_PAYMENT, { data });
export const updateAdditionalCosts = (data: Data) =>
    action(DATA_UPDATE_ADDITIONAL_COSTS, { data });
export const updateCountries = (data: Data) =>
    action(DATA_UPDATE_COUNTRIES, { data });
export const updateRepresentatives = (data: Data) =>
    action(DATA_UPDATE_REPRESENTATIVES, { data });
export const updateFeaturesAvailability = (data: Data) =>
    action(DATA_UPDATE_FEATURES_AVAILABILITY, { data });
export const updatePrevMonthLocked = (data: Data) =>
    action(DATA_UPDATE_PREV_MONTH_LOCKED, { data });
export const updateCurrencies = (data: Data) =>
    action(DATA_UPDATE_CURRENCIES, { data });
export const updateDashboard = (data: {orders: { [index: string]: { [index: string]: Data } | Data }, years: { [index: string]: string }}) =>
    action(DATA_UPDATE_DASHBOARD, { data });

export const updateSettings = (data: Data) =>
    action(DATA_UPDATE_SETTINGS, { data });

export const resetOrdersData = () => action(DATA_RESET_ORDERS)

export const createPurchase = () =>
    action(DATA_CREATE_PURCHASE);
export const createSales = () =>
    action(DATA_CREATE_SALES);

export const createRequestStatusUpdate = (status: string) =>
    action(DATA_CREATE_REQUEST_STATUS_UPDATE, { status });
export const createRequestStatusReset = () =>
    action(DATA_CREATE_REQUEST_STATUS_UPDATE, { status: null });

export const loadPurchase = () =>
    action(DATA_LOAD_PURCHASE);
export const loadSales = () =>
    action(DATA_LOAD_SALES);
export const loadDashboard = (year?: string) =>
    action(DATA_LOAD_DASHBOARD, { year });

export const refreshPurchase = (year?: string) =>
    action(DATA_REFRESH_PURCHASE, { year });
export const refreshSales = (year?: string) =>
    action(DATA_REFRESH_SALES, { year });

export const createPositionItem = () => action(DATA_ORDER_CREATE_POSITION_ITEM);

export const addPositionItem = (id: string) =>
    action(DATA_ORDER_ADD_POSITION_ITEM, { id });
export const deletePositionItem = (id: string) =>
    action(DATA_ORDER_DELETE_POSITION_ITEM, { id });
export const deleteAllPositionItems = () =>
    action(DATA_ORDER_DELETE_ALL_POSITION_ITEMS);

export const createAdditionalCostItem = () => action(DATA_ORDER_CREATE_ADDITIONAL_COST_ITEM);

export const addAdditionalCostItem = (id: string) =>
    action(DATA_ORDER_ADD_ADDITIONAL_COST_ITEM, { id });
export const deleteAdditionalCostItem = (id: string) =>
    action(DATA_ORDER_DELETE_ADDITIONAL_COST_ITEM, { id });
export const deleteAllAdditionalCostItems = () =>
    action(DATA_ORDER_DELETE_ALL_ADDITIONAL_COST_ITEMS);

export const clearOrderData = () => action(DATA_ORDER_CLEAR);

export const exportData = (type: string, lng: string) =>
    action(DATA_EXPORT, { type, lng });

export const getOrderPurchaseDetails = (id: string) =>
    action(DATA_GET_PURCHASE_DETAILS, { id });
export const getOrderSalesDetails = (id: string) =>
    action(DATA_GET_SALES_DETAILS, { id });

export const canselPurchaseOrder = (id: string) =>
    action(DATA_DELETE_PURCHASE_ORDER, { id });
export const canselSalesOrder = (id: string) =>
    action(DATA_DELETE_SALES_ORDER, { id });

export const canselPurchaseDelivery = (id: string) =>
    action(DATA_DELETE_PURCHASE_DELIVERY, { id });
export const canselSalesDelivery = (id: string) =>
    action(DATA_DELETE_SALES_DELIVERY, { id });

export const canselPurchaseInvoice = (id: string) =>
    action(DATA_DELETE_PURCHASE_INVOICE, { id });
export const canselSalesInvoice = (id: string) =>
    action(DATA_DELETE_SALES_INVOICE, { id });

export const setOrderDetails = (data: { [index: string]: Data | {[index: string]: string} }, type: string) =>
    action(DATA_SET_DETAILS, { data, type });
export const updateOrderDetails = (data: Data) =>
    action(DATA_UPDATE_DETAILS, { data });

export const resetOrderDetails = () => action(DATA_RESET_DETAILS);

export const changePurchaseOrder = (id: string) =>
    action(DATA_CHANGE_PURCHASE_ORDER, { id });
export const changeSalesOrder = (id: string) =>
    action(DATA_CHANGE_SALES_ORDER, { id });

export const setShippingAddressFormIsDisplayed = (display: boolean) => // @todo: remove
    action(DATA_SET_SHIPPING_ADDRESS_FORM_IS_DISPLAYED, { display });

export const setSelectedCustomerIsBlocked = (isBlocked: boolean) =>
    action(DATA_SET_SELECTED_CUSTOMER_IS_BLOCKED, { isBlocked });
export const setSelectedCustomerShippingPartnerIsBlocked = (isBlocked: boolean) =>
    action(DATA_SET_SELECTED_CUSTOMER_SHIPPING_PARTNER_IS_BLOCKED, { isBlocked });

export const lockOrderPurchasePositions = (purchase_positions: string[]) =>
    action(DATA_ORDER_EDIT_LOCK, { purchase_positions });
export const lockOrderSalePositions = (sale_positions: string[]) =>
    action(DATA_ORDER_EDIT_LOCK, { sale_positions });

export const setOrderEditing = (orderIsEdited: boolean) =>
    action(DATA_ORDER_IS_EDITED, { orderIsEdited });

export const unlockOrderPurchasePositions = (purchase_positions: string[]) =>
    action(DATA_ORDER_EDIT_UNLOCK, { purchase_positions });
export const unlockOrderSalePositions = (sale_positions: string[]) =>
    action(DATA_ORDER_EDIT_UNLOCK, { sale_positions });

export const loadSalesHistory = (sale_positions: string[], sale_order_id?: string) =>
    action(DATA_LOAD_HISTORY, { sale_positions, sale_order_id });
export const loadPurchaseHistory = (purchase_positions: string[], purchase_order_id?: string) =>
    action(DATA_LOAD_HISTORY, { purchase_positions, purchase_order_id });
export const loadUserHistory = () =>
    action(DATA_LOAD_USER_HISTORY);
export const updateHistory = (data: { [index: string]: Data }) =>
    action(DATA_UPDATE_HISTORY, { data });
export const resetHistory = () =>
    action(DATA_RESET_HISTORY);

export const setMaterialDataToForm = (material_id: string) =>
    action(DATA_SET_MATERIAL_TO_FORM, { material_id });

export const addMaterial = () => action(DATA_ADD_MATERIAL);
export const editMaterial = (material_id: string) =>
    action(DATA_EDIT_MATERIAL, { material_id });

export const loadLogs = () => action(DATA_LOAD_LOGS);
export const setLogs = (data: Data) =>
    action(DATA_SET_LOGS, { data });
export const resetLogs = () =>
    action(DATA_RESET_LOGS);

export const loadReports = () => action(DATA_LOAD_REPORTS);
export const setReports = (data: Data) =>
    action(DATA_SET_REPORTS, { data });
export const resetReports = () =>
    action(DATA_RESET_REPORTS);

export const setOrderDetailsTrigger = (id: string, table: string) =>
    action(DATA_ORDER_DETAILS_TRIGGER_SET, { id, table })
export const resetOrderDetailsTrigger = () =>
    action(DATA_ORDER_DETAILS_TRIGGER_RESET)

export const resetSettignsToDefaut = () => action(DATA_RESET_SETTINGS, {});
export const changeSettigns = (id: string, value: string) =>
    action(DATA_CHANGE_SETTINGS, { id, value });

export const downloadReport = (filename: string) =>
    action(DATA_DOWNLOAD_REPORT, { filename });
export const downloadReportById = (id: string) =>
    action(DATA_DOWNLOAD_REPORT, { id });

export const setCustomerPreselect = () => 
    action(DATA_ORDER_SET_CUSTOMER_PRESELECT)

export const setCustomerPartners = (partners: { shippingPartners: CustomerPartner[], metalAccountPartners: CustomerPartner[] }) =>
    action(DATA_ORDER_SET_CUSTOMER_PARTNERS, { partners });
