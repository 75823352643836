import * as React from 'react';
import { TabTableProps, TabTable } from '../../tables/tabtable';
import { i18nContext } from '../../../../language';
import { SimpleTable } from '../../../utils/table';
import { Data } from '../../../../reducers/data';
import { valuesToDisplayMaterials, MATERIALS_CONTEXT_MENU_ID } from '../../../../data/constants';
import { RootState } from '../../../../store/types';
import { getMaterials, getMaterialsData, getDataIsLoaded } from '../../../../selectors/data';
import { connect } from 'react-redux';
import { customRowRenderMaterials, customSort, getMaterialTableHeaders } from '../../tables/tabtable-components';
import { connectMenu } from 'react-contextmenu';
import MaterialContextMenu from './material-contextmenu';

export interface MaterialTabProps extends TabTableProps {
    items?: Data;
}

const ConnectedMenu = connectMenu(MATERIALS_CONTEXT_MENU_ID)(MaterialContextMenu);
class MaterialTab extends React.PureComponent<MaterialTabProps, {}> {
    render() {
        return (
            <i18nContext.Consumer>
                {i18n =>
                    [<TabTable
                        key="table"
                        {...this.props}
                        options={{
                            search: false,
                            customSort: customSort,
                            rowsPerPage: 50,
                            customRowRender: customRowRenderMaterials(MATERIALS_CONTEXT_MENU_ID),
                            sortOrder: {
                                name: 'material_code',
                                direction: 'desc',
                            },
                        }}
                        customeHeaders={getMaterialTableHeaders()}
                    />,
                    <ConnectedMenu key="menu" />]
                }
            </i18nContext.Consumer>
        )
    }
}

const mapStateToProps = (
    state: RootState,
    ownProps: MaterialTabProps
) => {
    const isLoaded = getDataIsLoaded(state);
    return {
        items: getMaterialsData(state),
        isLoaded: isLoaded && isLoaded.materials,
    }
};

export const MaterialBarContainer = connect(
    mapStateToProps,
    null
)(MaterialTab);

export default MaterialBarContainer;