import { action } from 'typesafe-actions';
import { UserPermissions } from '../models/user-permissions';
import { ITERATE_HEARTBEAT, RESET_HEARTBEAT } from '../data/constants';
import {
    USER_SUBMIT_LOGIN_FORM,
    USER_LOGED_IN_SUCCESS,
    USER_LOGED_IN_ERROR,
    USER_SUBMIT_LOGOUT,
    USER_LOGED_OUT,
    SESSION_PING,
    SESSION_SET_AUTHORIZATION_TOKEN,
} from '../data/constants';

export const submitLoginForm = () => action(USER_SUBMIT_LOGIN_FORM);

export const userLogedInSuccess = (user: { user: string, role: string, permissions: UserPermissions, token: string }) =>
    action(USER_LOGED_IN_SUCCESS, user);

export const userLogedInErrorr = (message: string) =>
    action(USER_LOGED_IN_ERROR, { message });

export const userLogoutSubmit = () => action(USER_SUBMIT_LOGOUT);

export const userLogoedOut = () => action(USER_LOGED_OUT);

export const sessionPing = () => action(SESSION_PING);

export const iterateHeardBeat = () => action(ITERATE_HEARTBEAT);
export const resetHeardBeat = () => action(RESET_HEARTBEAT);

export const setAuthorizationToken = (authorizationToken: string) =>
    action(SESSION_SET_AUTHORIZATION_TOKEN, { authorizationToken });
