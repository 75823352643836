import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Field } from 'redux-form';
import {ElementProps} from './form';

export class SelectInput extends React.PureComponent<ElementProps> {
  constructor(props: ElementProps) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    const element = ReactDOM.findDOMNode(this) as HTMLSelectElement;
    this.updateOptionsDOM(element);
  }
  updateOptionsDOM(element: HTMLSelectElement) {
    if (!element || !element.options) {
      return;
    }
    for (let i = 0; i < element.options.length; i++) {
      const option = element.options[i];
      if (option.selected) {
        option.setAttribute('selected', '');
      } else {
        option.removeAttribute('selected');
      }
    }
  }
  handleChange(event: React.FormEvent) {
    const element = (event.target as HTMLSelectElement);
    this.updateOptionsDOM(element);
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(event);
    }
  }
  render() {
    return (
      <React.Fragment>
        <Field
          {...{
            ...this.props,
            options: null,
            onChange: null,
            prefix: null,
            postfix: null,
          }}
          defaultValue={this.props.defaultValue}
          name={this.props.name}
          component="select"
          onChange={this.handleChange}
          className={[
            this.props.className ? this.props.className : '',
            'form-control form-control-sm configurator__form-control d-inline-block',
            this.props.postfix ? 'configurator__form-control--fixed' : '',
            ].join(' ').trim()}
        > {this.props.options && this.props.options.map((p, i) => (
            <option {...p.props} key={i} value={p.value}>
              {p.text ? p.text : p.value}
            </option>))
          }
        </Field>
        { this.props.postfix === '' ? (
          <span className="text-small configurator__mini-unit invisible" />
        ) : this.props.postfix ? (
          <span className="text-small configurator__mini-unit" >
            {this.props.postfix}
          </span>
        ) : null}
      </React.Fragment>
    );
  }
}
