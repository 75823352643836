import * as React from 'react';
import { FormElements, FormBuilder, HandledFormProps } from '../utils/form/form';
import { FORM_LOGIN } from '../../data/constants';
import { i18nContext, I18n } from '../../language';

const Textes = {
  login: "Login with Microsoft",
  password: "Password",
  username: "Username",
}

const fields = (i18n: I18n) => [
  {
    label: ' ',
    input: FormElements.button({
      value: i18n.t(Textes.login),
      className: 'btn btn-secondary w-100',
      type: 'submit',
    }),
  },
];


const formBuilder = new FormBuilder();

export const LoginForm = FormBuilder.form<HandledFormProps>(
  (props: HandledFormProps) => (
    <div className="col-12">
      <i18nContext.Consumer>
      {i18n => {
        formBuilder.setElements(fields(i18n));
        return (
          <div className="col-12">
            {formBuilder.build()}
          </div>
        )
      }}
      </i18nContext.Consumer>
    </div>
  ),
  FORM_LOGIN
);

