import * as React from 'react';
import { Loader } from '../../utils/loader';
import { InputHandler, KEY_CODE_ESC } from '../../../services/input';

export interface PopupContainerProps {
    showPopup: boolean;
    id: string;
    inLoad?: boolean;

    closePopup?: () => void;
}

export class PopupContainer extends React.PureComponent<PopupContainerProps, {}> {
    constructor(props: PopupContainerProps) {
        super(props);
        this.escFunction = this.escFunction.bind(this);
    }
    escFunction() {
        if (this.props.showPopup) {
            this.props.closePopup();
            return true;
        }
        return false;
    }
    componentWillReceiveProps (newProps: PopupContainerProps) {
      if(newProps.showPopup && newProps.showPopup !== this.props.showPopup) {
        InputHandler.hookHandler(KEY_CODE_ESC, this.props.id, () => this.escFunction());
      }
    }
    render() {
        return (
            <div
                id={this.props.id}
                className={this.props.showPopup ? 'display' : 'hidden'}
            >
                <div id={`${this.props.id}-content`}>
                    <div className="position-related container d-flex align-items-center" >
                        {this.props.children}
                        {this.props.inLoad ? (
                            <Loader className="popup-loader" />
                        ) : null}
                    </div>
                </div>
            </div >
        );
    }
}