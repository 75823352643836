import * as React from 'react';
import { default as Dashboard } from './dashboard'
import { default as TabsBarContent } from './tabsbar-content'
import { default as TabsBar } from './tabsbar-bar'
import { default as ButtonsHeader } from './head/header-buttons'
import { default as UserHeader } from './head/user-header'

export class HomePage extends React.PureComponent {
    componentDidMount(): void {
        document.dispatchEvent(new Event('header-init-done'));
    }

    render() {
        return [
            <div key="head" className="header-wrapper" id="tool-header">
                <div className="d-flex flex-row">
                    <ButtonsHeader />
                    <UserHeader />
                </div>
                <Dashboard />
                <TabsBar key="content" />
            </div>,
            <TabsBarContent key="content" />
        ]
    }
}
