import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../store/types';
import { WidgetSlider } from '../utils/widget-slider';
import { PriceTable } from './tables/pricetable';
import { i18nContext, I18n } from '../../language';
import { getDashboard, getSelectedDashboardYear, getDashboardYears, getUserPermissions, getPrevMonthLocked } from '../../selectors';
import { UserPermissions } from '../../models/user-permissions';
import { Data } from '../../reducers/data';
import { Loader } from '../utils/loader';
import ViewingYearForm from './forms/viewing-year';
import PreviousMonthLockedMessage from './dashboard/previous-month-locked-message';

const Textes = {
    purchase: "Purchase",
    sale: "Sale",
    inventory: "Inventory",
    guv: "Profits and Losses",
    difference: "Difference",
    year: "Viewing Year",
}

interface DashboardProps {
    dashboard?: { [index: string]: { [index: string]: Data } | Data };
    selectedYear?: string;
    dashboard_years?: { [index: string]: string };
    userPermissions?: UserPermissions;
    prevMonthLocked?: boolean;
}

const SlideBarContent = (props: DashboardProps) => {
    const options: { value: string, text: string }[] = [];
    if (props.dashboard_years) {
        Object.keys(props.dashboard_years).sort((a, b) => Number(b) - Number(a)).map(year=> {
            options.push({ value: props.dashboard_years[year], text: props.dashboard_years[year] });
        });
    }

    return (
        <i18nContext.Consumer>
            {(i18n: I18n) =>
                <div className="d-flex flex-row w-100">
                    <div className="col-4 d-flex flex-row p-0">
                        <PriceTable items={props.dashboard && props.dashboard.purchase[props.selectedYear] as Data || {}} title={i18n.t(Textes.purchase)} color="orange" className="w-100 pr-1" />
                        <PriceTable items={props.dashboard && props.dashboard.sales[props.selectedYear] as Data || {}} title={i18n.t(Textes.sale)} color="orange" className="w-100" />
                    </div>
                    <div className="col-4 d-flex flex-row mx-3">
                        <PriceTable items={props.dashboard && props.dashboard.inventory[props.selectedYear] as Data || {}} title={i18n.t(Textes.inventory)} color="blue" className="w-100 pr-1" />
                        <PriceTable items={props.dashboard && props.dashboard.profits_and_losses[props.selectedYear] as Data || {}} title={i18n.t(Textes.guv)} color="blue" className="w-100" />
                    </div>
                    <div className="col-4 d-flex flex-row p-0">
                        <PriceTable items={props.dashboard && props.dashboard.difference[props.selectedYear] as Data || {}} title={i18n.t(Textes.difference)} color="green" className="w-100 pr-1" />
                        <div className="w-100 d-flex flex-column align-items-center">
                            <div className="text-center font-weight-bold">{i18n.t(Textes.year)}</div>
                            <ViewingYearForm className="w-100" options={options} />
                            {props.prevMonthLocked && <PreviousMonthLockedMessage />}
                        </div>
                    </div>
                </div>
            }
        </i18nContext.Consumer>
    )
}

class Dashboard extends React.Component<DashboardProps, {}> {
    state: { showContent: boolean, } = {
        showContent: false,
    };
    constructor(props: DashboardProps) {
        super(props);
        this.onSliderCklick = this.onSliderCklick.bind(this);
    }
    onSliderCklick() {
        this.setState({ ...this.state, showContent: !this.state.showContent });
    }
    render() {
        return (
            this.props.userPermissions.dashboard ? <div className={["card mt-3 widget-slider-container", this.props.selectedYear && ((new Date().getFullYear()) != Number(this.props.selectedYear)) ? "prev-years" : ""].join(' ')}>
                <WidgetSlider
                    sliderTitle=""
                    onClick={this.onSliderCklick}
                    showContent={this.state.showContent}
                >
                    <SlideBarContent
                        dashboard={this.props.dashboard}
                        dashboard_years={this.props.dashboard_years}
                        selectedYear={this.props.selectedYear}
                        prevMonthLocked={this.props.prevMonthLocked}
                    />
                    {(!this.props.dashboard || Object.keys(this.props.dashboard).length === 0) &&
                        <Loader className="d-flex w-100" />}
                </WidgetSlider>
            </div> : null
        )
    }
}

const mapStateToProps = (state: RootState, ownProps: DashboardProps) => {
    return {
        dashboard: getDashboard(state),
        dashboard_years: getDashboardYears(state),
        selectedYear: getSelectedDashboardYear(state),
        userPermissions: getUserPermissions(state),
        prevMonthLocked: getPrevMonthLocked(state),
    }
};

export const DashboardContainer = connect(
    mapStateToProps,
    null
)(Dashboard);

export default DashboardContainer;