import { FormElements } from '../../../utils/form/form';
import { positionsFieldNames } from '../../../../reducers/form';
import { PositionsFormProps } from './position-form';
import { i18n } from '../../../../language';


const PositionFields = (props: PositionsFormProps, index: string) => [
    {
        input: FormElements.select({
            name: `${positionsFieldNames.cost_center}-${index}`,
            className: 'state-select',
            options: props.options.cost_centers || [],
            disabled: props.readonly,
        }),
        required: true,
        tableCell: true,
    }, {
        input: FormElements.select({
            name: `${positionsFieldNames.division}-${index}`,
            className: 'state-select',
            options: props.options.divisions || [],
            disabled: props.readonly,
        }),
        required: true,
        tableCell: true,
    }, {
        input: FormElements.select({
            name: `${positionsFieldNames.material_code}-${index}`,
            className: 'state-select',
            options: props.options.materials || [],
            disabled: props.readonly,
        }),
        required: true,
        tableCell: true,
    }, {
        input: FormElements.number({
            name: `${positionsFieldNames.weight}-${index}`,
            className: 'widest-input',
            required: true,
            thousandSeparator: i18n.getCurrentThousandSeparator(),
            decimalSeparator: i18n.getCurrentDecimalSeparator(),
            step: 'any',
            disabled: props.readonly,
            maxLength: 20,
            // isAllowed: (value: {formattedValue: string, value: string, floatValue: number}) => value.floatValue !== 0
        }),
        tableCell: true,
        required: true,
        type: 'number',
        className: 'mt-3',
    }, {
        input: FormElements.select({
            name: `${positionsFieldNames.weight_unit}-${index}`,
            className: 'state-select',
            options: props.weightUnits[index] ? props.weightUnits[index].map((weightUnit) => {
                return { value: weightUnit };
            }) : [],
            disabled: props.readonly,
        }),
        required: true,
        tableCell: true,
    }, {
        input: FormElements.number({
            name: `${positionsFieldNames.unit_price}-${index}`,
            className: 'widest-input',
            required: true,
            step: 'any',
            min: 0,
            maxLength: 20,
            disabled: props.readonly,
            allowNegative: false,
            thousandSeparator: i18n.getCurrentThousandSeparator(),
            decimalSeparator: i18n.getCurrentDecimalSeparator(),
            postfix: props.priceUnits && props.priceUnits[index],
            postfixclassname: "pl-1",
        }),
        required: true,
        className: 'mt-3',
        type: 'number',
        tableCell: true,
    }, {
        input: FormElements.number({
            name: `${positionsFieldNames.position_price}-${index}`,
            className: 'widest-input',
            required: true,
            disabled: props.readonly,
            maxLength: 20,
            step: 'any',
            thousandSeparator: i18n.getCurrentThousandSeparator(),
            decimalSeparator: i18n.getCurrentDecimalSeparator(),
            // type: props.readonly ? 'text' : 'number',
        }),
        tableCell: true,
        required: true,
        type: 'number',
        className: 'mt-3',
    },
    ...(
        props.isPurchase || props.showAdditionalField ?
            [
                {
                    input: FormElements.select({
                        name: `${positionsFieldNames.storage_location_code}-${index}`,
                        className: 'state-select',
                        options: props.options.warehouses || [],
                        disabled: props.readonly,
                    }),
                    required: true,
                    tableCell: true,
                }
            ] : []
    ),
    ...(
        props.isPurchase ? [] :
            [
                {
                    input: FormElements.input({
                        name: `${positionsFieldNames.tax_class}-${index}`,
                        className: 'widest-input',
                        disabled: props.readonly,
                        maxLength: 1,
                    }),
                    className: 'mt-3',
                    tableCell: true,
                }
            ]
    ),
    {
        input: FormElements.input({
            name: `${positionsFieldNames.notes}-${index}`,
            className: 'widest-input',
            disabled: props.readonly,
            maxLength: 40,
        }),
        className: 'mt-3',
        tableCell: true,
    },
]

export default PositionFields;
