import * as search from '../actions/search';
import { ActionType } from 'typesafe-actions';
import { SEARCH_START, SEARCH_CLEAR, SEARCH_SET, SET_ACTIVE_SEARCH, defaultCustomerSearch } from '../data/constants';

export type SearchAction = ActionType<typeof search>;

export type Search = { [index: string]: {
    [index: string]: string | number | boolean | JSX.Element | {
        [index: string]: string | number | boolean | JSX.Element
    }[]
} }

export type SearchState = {
    searchedInOrder: Search,
    searchedCustomers: Search,
    searchedSales: Search,
    searchedPurchases: Search,
    search: { [index: string]: string },
    activeSearch: string;
};

export const defaultSearchState: SearchState = {
    searchedCustomers: null,
    searchedSales: null,
    searchedPurchases: null,
    searchedInOrder: null,
    search: null,
    activeSearch: defaultCustomerSearch,
};

export function buildDefaultState(Search: {
}): SearchState {
    return {
        ...defaultSearchState,
        ...Search,
    };
}

export const SearchMainReducer = (
    state: SearchState = defaultSearchState,
    action: SearchAction
) => {
    switch (action.type) {
        case SEARCH_SET:
            return { ...state, [action.payload.type]: action.payload.data };
        case SEARCH_START:
            return { ...state, search: { ...state.search, [action.payload.type]: action.payload.search } };
        case SEARCH_CLEAR:
            return { ...state, search: { ...state.search, [action.payload.type]: null } };
        case SET_ACTIVE_SEARCH:
            return { ...state, activeSearch: action.payload.type };
        default:
            return state;
    }
};

export const SearchReducer = {
    reducer: SearchMainReducer,
    defaultSate: defaultSearchState,
    stateBuilder: buildDefaultState,
};

export default SearchReducer;