import { Textes, salesOrderTypeOptions } from './data';
import { I18n } from '../../../../language';
import { FormElements } from '../../../utils/form/form';
import { orderFieldNames } from '../../../../reducers/form';

export const saleFields = (
    i18n: I18n,
    showAdditionalField?: boolean,
    searchPopup?: JSX.Element,
    options?: { [index: string]: Array<{ value: string; text: string; }> },
    inactiveFields?: string[]
) => {
    const showCustomerPopup = !!searchPopup;
    return [
        ...(showCustomerPopup ? [{
            label: i18n.t(Textes.order_type),
            input: FormElements.select({
                name: orderFieldNames.order_type,
                className: 'widest-input',
                options: salesOrderTypeOptions(i18n),
                disabled: inactiveFields.includes(orderFieldNames.order_type),
            }),
            className: 'col-6 pb-2 pr-0',
            wrapClass: 'd-flex flex-row justify-content-between',
            classNameLeft: 'd-flex align-items-center col-4 p-0',
            classNameRight: 'col-8 pr-0',
        }] : []),
        {
            label: i18n.t(Textes.relevant_for_sap),
            input: FormElements.toggle({
                name: orderFieldNames.relevant_for_sap,
                className: 'widest-input',
                disabled: inactiveFields.includes(orderFieldNames.relevant_for_sap),
                maxLength: 2,
            }),
            className: `${showCustomerPopup ? 'col-6' : 'col-5'} pb-2 pr-0`,
            wrapClass: 'd-flex flex-row justify-content-between',
            classNameLeft: 'd-flex align-items-center col-4 p-0',
            classNameRight: 'col-8 pr-0',
        },
        {
            label: i18n.t(Textes.currency),
            input: FormElements.select({
                name: orderFieldNames.currency_code,
                className: 'widest-input',
                options: options.currencies,
                disabled: inactiveFields.includes(orderFieldNames.currency_code),
            }),
            className: `${showCustomerPopup ? 'col-6' : 'col-5'} pb-2 pr-0`,
            wrapClass: 'd-flex flex-row justify-content-between',
            classNameLeft: 'd-flex align-items-center col-4 p-0',
            classNameRight: 'col-8 pr-0',
        },

        ...(showCustomerPopup ? [{
            label: i18n.t(showAdditionalField ? Textes.delivery_partner_sap_id : Textes.weight_account_partner_sap_id),
            input: searchPopup,
            className: 'col-12 pb-2',
            wrapClass: 'd-flex flex-row justify-content-between',
            classNameRight: 'col-10 pr-0',
            classNameLeft: 'd-flex align-items-center col-2 pl-0',
        }] : []),
        {
            separator: true,
            className: "border-line mx-0 mb-4",
        }, {
            label: i18n.t(Textes.primary_name),
            input: FormElements.input({
                name: orderFieldNames.primary_name,
                type: 'text',
                className: 'widest-input w-100',
                disabled: inactiveFields.includes(orderFieldNames.primary_name),
                maxLength: 255,
            }),
            className: 'col-12 pb-2',
            wrapClass: 'd-flex flex-row justify-content-between',
            classNameRight: 'col-10 pr-0',
            classNameLeft: 'd-flex align-items-center col-2 pl-0',
        }, {
            label: i18n.t(Textes.secondary_name),
            input: FormElements.input({
                name: orderFieldNames.secondary_name,
                type: 'text',
                className: 'widest-input w-100',
                disabled: inactiveFields.includes(orderFieldNames.secondary_name),
                maxLength: 255,
            }),
            className: 'col-12 pb-2',
            wrapClass: 'd-flex flex-row justify-content-between',
            classNameRight: 'col-10 pr-0',
            classNameLeft: 'd-flex align-items-center col-2 pl-0',
        }, {
            label: i18n.t(Textes.street),
            input: FormElements.input({
                name: orderFieldNames.street,
                className: 'widest-input',
                disabled: inactiveFields.includes(orderFieldNames.street),
                maxLength: 60,
            }),
            className: 'col-6 pb-2 pr-0',
            wrapClass: 'd-flex flex-row justify-content-between',
            classNameLeft: 'd-flex align-items-center col-4 p-0',
            classNameRight: 'col-8 pr-0',
        }, {
            label: i18n.t(Textes.house_number),
            input: FormElements.input({
                name: orderFieldNames.house_number,
                className: 'widest-input',
                disabled: inactiveFields.includes(orderFieldNames.house_number),
                maxLength: 10,
            }),
            className: 'col-6 pb-2',
            wrapClass: 'd-flex flex-row justify-content-between',
            classNameLeft: 'd-flex align-items-center col-4 p-0',
            classNameRight: 'col-8 pr-0',
        }, {
            label: i18n.t(Textes.post_code),
            input: FormElements.input({
                name: orderFieldNames.post_code,
                className: 'widest-input',
                disabled: inactiveFields.includes(orderFieldNames.post_code),
                maxLength: 10,
            }),
            className: 'col-6 pb-2 pr-0',
            wrapClass: 'd-flex flex-row justify-content-between',
            classNameLeft: 'd-flex align-items-center col-4 p-0',
            classNameRight: 'col-8 pr-0',
        }, {
            label: i18n.t(Textes.city),
            input: FormElements.input({
                name: orderFieldNames.city,
                className: 'widest-input',
                disabled: inactiveFields.includes(orderFieldNames.city),
                maxLength: 40,
            }),
            className: 'col-6 pb-2',
            wrapClass: 'd-flex flex-row justify-content-between',
            classNameLeft: 'd-flex align-items-center col-4 p-0',
            classNameRight: 'col-8 pr-0',
        },
        ...(
            showAdditionalField ? [
                {
                    label: i18n.t(Textes.incoterm),
                    input: FormElements.input({
                        name: orderFieldNames.incoterm,
                        className: 'widest-input',
                        disabled: inactiveFields.includes(orderFieldNames.incoterm),
                        maxLength: 3,
                    }),
                    className: 'col-6 pb-2 pr-0',
                    wrapClass: 'd-flex flex-row justify-content-between',
                    classNameLeft: 'd-flex align-items-center col-4 p-0',
                    classNameRight: 'col-8 pr-0',
                }, {
                    label: i18n.t(Textes.incoterm2),
                    input: FormElements.input({
                        name: orderFieldNames.incoterm2,
                        className: 'widest-input',
                        disabled: inactiveFields.includes(orderFieldNames.incoterm2),
                        maxLength: 28,
                    }),
                    className: 'col-6 pb-2',
                    wrapClass: 'd-flex flex-row justify-content-between',
                    classNameLeft: 'd-flex align-items-center col-4 p-0',
                    classNameRight: 'col-8 pr-0',
                },
            ] : []
        ), {
            label: i18n.t(Textes.country),
            input: FormElements.select({
                name: orderFieldNames.country,
                className: 'widest-input',
                disabled: inactiveFields.includes(orderFieldNames.country),
                maxLength: 30,
                options: options.countries || [],
            }),
            className: 'col-6 pb-2 pr-0',
            wrapClass: 'd-flex flex-row justify-content-between',
            classNameLeft: 'd-flex align-items-center col-4 p-0',
            classNameRight: 'col-8 pr-0',
        }, {
            label: i18n.t(Textes.contact),
            input: [
                FormElements.select({
                    name: orderFieldNames.representative_no,
                    className: 'widest-input',
                    disabled: inactiveFields.includes(orderFieldNames.representative_no),
                    maxLength: 3,
                    options: options.representatives || [],
                }),
                FormElements.input({
                    name: orderFieldNames.representative_name,
                    className: 'widest-input',
                    classInputWrapper: 'col-9 pr-0',
                    disabled: inactiveFields.includes(orderFieldNames.representative_name),
                    maxLength: 35,
                }),
            ],
            className: 'col-6 pb-2',
            wrapClass: 'd-flex flex-row justify-content-between',
            classNameLeft: 'd-flex align-items-center col-4 p-0',
            classNameRight: 'col-8 pr-0 d-flex',
        }, {
            label: i18n.t(Textes.notes),
            input: FormElements.input({
                name: orderFieldNames.notes,
                type: 'text',
                className: 'widest-input w-100',
                disabled: inactiveFields.includes(orderFieldNames.notes),
                maxLength: 255,
            }),
            className: 'col-12 pb-2',
            wrapClass: 'd-flex flex-row justify-content-between',
            classNameRight: 'col-10 pr-0',
            classNameLeft: 'd-flex align-items-center col-2 pl-0',
        }, {
            label: i18n.t(Textes.internal_notes),
            input: FormElements.input({
                name: orderFieldNames.internal_notes,
                type: 'text',
                className: 'widest-input w-100',
                disabled: inactiveFields.includes(orderFieldNames.internal_notes),
                maxLength: 255,
            }),
            className: 'col-12',
            wrapClass: 'd-flex flex-row justify-content-between',
            classNameRight: 'col-10 pr-0',
            classNameLeft: 'd-flex align-items-center col-2 pl-0',
        }, {
            separator: true,
            className: "border-line mx-0 mb-4",
        }, {
            label: i18n.t(Textes.order_no),
            input: FormElements.input({
                name: orderFieldNames.order_no,
                className: 'widest-input',
                disabled: inactiveFields.includes(orderFieldNames.order_no),
                maxLength: 35,
            }),
            className: 'col-6 pb-2 pr-0',
            wrapClass: 'd-flex flex-row justify-content-between',
            classNameLeft: 'd-flex align-items-center col-4 p-0',
            classNameRight: 'col-8 pr-0',
        }, {
            label: i18n.t(Textes.order_date),
            input: FormElements.date({
                name: orderFieldNames.order_date,
                className: 'w-100 widest-input form-control form-control-sm configurator__form-control configurator__form-control--fixed d-inline-block',
                disabled: inactiveFields.includes(orderFieldNames.order_date),
                locale: i18n.language,
            }),
            className: 'col-6 pb-2',
            wrapClass: 'd-flex flex-row justify-content-between',
            classNameLeft: 'd-flex align-items-center col-4 p-0',
            classNameRight: 'col-8 pr-0',
        }, {
            label: i18n.t(Textes.terms_of_payment),
            input: FormElements.select({
                name: orderFieldNames.terms_of_payment,
                className: 'widest-input',
                disabled: inactiveFields.includes(orderFieldNames.terms_of_payment),
                maxLength: 4,
                options: options.terms_of_payment || [],
            }),
            className: 'col-6 pb-2 pr-0',
            wrapClass: 'd-flex flex-row justify-content-between',
            classNameLeft: 'd-flex align-items-center col-4 p-0',
            classNameRight: 'col-8 pr-0',
        }, {
            label: i18n.t(Textes.value_date),
            input: FormElements.date({
                name: orderFieldNames.value_date,
                className: 'w-100 widest-input form-control form-control-sm configurator__form-control configurator__form-control--fixed d-inline-block',
                disabled: inactiveFields.includes(orderFieldNames.value_date),
                locale: i18n.language,
            }),
            className: `col-6 pb-2`,
            wrapClass: `d-flex flex-row justify-content-between`,
            classNameLeft: 'd-flex align-items-center col-4 p-0',
            classNameRight: 'col-8 pr-0',
        }, {
            label: i18n.t(Textes.billing_attachment_text),
            input: FormElements.input({
                name: orderFieldNames.billing_attachment_text,
                type: 'text',
                className: 'widest-input w-100',
                disabled: inactiveFields.includes(orderFieldNames.billing_attachment_text),
                maxLength: 255,
            }),
            className: 'col-12 pb-2',
            wrapClass: 'd-flex flex-row justify-content-between',
            classNameRight: 'col-10 pr-0',
            classNameLeft: 'd-flex align-items-center col-2 pl-0',
        }, ...(
            showAdditionalField ? [{
                label: i18n.t(Textes.shipping_terms),
                input: FormElements.select({
                    name: orderFieldNames.shipping_terms,
                    className: 'widest-input',
                    disabled: inactiveFields.includes(orderFieldNames.shipping_terms),
                    maxLength: 2,
                    options: options.shipping_terms || [],
                }),
                className: 'col-6 pb-2 pr-0',
                wrapClass: 'd-flex flex-row justify-content-between',
                classNameLeft: 'd-flex align-items-center col-4 p-0',
                classNameRight: 'col-8 pr-0',
            }, {
                label: i18n.t(Textes.delivery_note),
                input: FormElements.input({
                    name: orderFieldNames.delivery_note,
                    className: 'widest-input',
                    disabled: inactiveFields.includes(orderFieldNames.delivery_note),
                    maxLength: 25,
                }),
                className: 'col-6 pb-2',
                wrapClass: 'd-flex flex-row justify-content-between',
                classNameLeft: 'd-flex align-items-center col-4 p-0',
                classNameRight: 'col-8 pr-0',
            }] : []
        ), {
            label: i18n.t(Textes.invoice_id),
            input: FormElements.input({
                name: orderFieldNames.invoice_id,
                className: 'widest-input',
                disabled: inactiveFields.includes(orderFieldNames.invoice_id),
                maxLength: 40,
            }),
            className: 'col-6 pb-2 pr-0',
            wrapClass: 'd-flex flex-row justify-content-between',
            classNameLeft: 'd-flex align-items-center col-4 p-0',
            classNameRight: 'col-8 pr-0',
        }, {
            label: i18n.t(Textes.vat_transaction_indicator),
            input: FormElements.input({
                name: orderFieldNames.vat_transaction_indicator,
                className: 'widest-input',
                disabled: inactiveFields.includes(orderFieldNames.vat_transaction_indicator),
                maxLength: 2,
            }),
            className: 'col-6 pb-2',
            wrapClass: 'd-flex flex-row justify-content-between',
            classNameLeft: 'd-flex align-items-center col-4 p-0',
            classNameRight: 'col-8 pr-0',
        }, ...(
            showAdditionalField ? [{
                label: i18n.t(Textes.delivery_note_id),
                input: FormElements.input({
                    name: orderFieldNames.delivery_note_id,
                    className: 'widest-input',
                    disabled: inactiveFields.includes(orderFieldNames.delivery_note_id),
                    maxLength: 40,
                }),
                className: 'col-6 pb-2 pr-0',
                wrapClass: 'd-flex flex-row justify-content-between',
                classNameLeft: 'd-flex align-items-center col-4 p-0',
                classNameRight: 'col-8 pr-0',
            }] : []
        )
    ];
}
