

import { FormAction } from 'redux-form';
import { Epic, combineEpics } from 'redux-observable';
import {
    filter,
    map
} from 'rxjs/operators';
import { isOfType } from 'typesafe-actions';
import * as AppAction from '../actions/app';
import { RootAction } from '../store/types';
import { APP_GET_INIT, USER_LOGED_IN_SUCCESS } from '../data/constants';

const handleAppGetInit: Epic<FormAction, RootAction> = (
    action$,
    store
) =>
    action$.pipe(
        filter(action => (isOfType(APP_GET_INIT, action))
        ),
        map((action: FormAction) => {
            return AppAction.appSetInit();
        })
    );

const handleSuccessfulLogin: Epic<RootAction> = (
  action$,
  store
) =>
  action$.pipe(
    filter(action =>
      (isOfType(USER_LOGED_IN_SUCCESS, action))
    ),
    map(action => {
      return AppAction.updateAppLimitedVersion(action.payload.limited_version);
    })
  );

export default combineEpics(
    handleAppGetInit,
    handleSuccessfulLogin,
);
