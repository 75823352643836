import { action } from 'typesafe-actions';
import {
    SOCKET_DATA_UPDATE,
    CONNECT_SOCKET,
    DISCONNECT_SOCKET
} from '../data/constants';
import { Data } from '../reducers/data';

export const connectSocket = (credentials: { login: string }) =>
    action(CONNECT_SOCKET, { credentials });

export const handleSocketData = (data: { type: string, data: Data }) =>
    action(SOCKET_DATA_UPDATE, { data });


export const disconnectSocket = () =>
    action(DISCONNECT_SOCKET);