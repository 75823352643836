import { Epic, combineEpics } from 'redux-observable';
import {
  filter,
  map,
  mergeMap,
  delay,
} from 'rxjs/operators';
import { isOfType } from 'typesafe-actions';
import * as DataAction from '../actions/data';
import * as AppAction from '../actions/app';
import { RootAction } from '../store/types';
import { getDate } from '../helpers/helper';
import {
  USER_LOGED_IN_SUCCESS,
  DATA_LOAD_ALL,
  DATA_LOAD_PURCHASE,
  DATA_LOAD_SALES,
  DATA_LOAD_CUSTOMERS,
  DATA_LOAD_METADATA,
  DATA_LOAD_DASHBOARD,
  DATA_EXPORT,
  ExportFileNames,
  APP_GET_INIT,
  FORM_VIEWING_YEAR,
  DATA_UPDATE_DASHBOARD,
  DATA_UPDATE_PURCHASE_STATUS,
  DATA_UPDATE_SALES_STATUS,
  DATA_SET_MATERIAL_TO_FORM,
  FORM_MATERIAL,
  DATA_LOAD_LOGS,
  DATA_UPDATE_MATERIALS_STATUS,
  FORM_SETTINGS,
  DATA_UPDATE_SETTINGS,
  DATA_RESET_SETTINGS,
  DATA_CHANGE_SETTINGS,
  DATA_REFRESH_PURCHASE,
  DATA_REFRESH_SALES,
} from '../data/constants';
import { forkJoin } from 'rxjs';
import {
  prepareData,
  parseDashboard,
  getSelectedDashboardYear,
  getUserPermissions,
} from '../selectors';
import { DataServiceClass, DataResponseParams } from '../services/data';
import * as SessionAction from '../actions/session';
import * as TradingAction from '../actions/trading';
import { setErrorMessage } from '../actions/app';
import { Data } from '../reducers/data';
import { actionTypes as FormActions } from 'redux-form';
import { FormBuilder } from '../components/utils/form/form';
import { Links } from '../data/links';
import { materialFieldNames } from '../reducers/form';
import { getMaterials, prepareSettingsData, getHighlightTimeout, getSettingsData } from '../selectors/data';
import { i18n } from '../language';
import { DATA_LOAD_PURCHASE_ORDERS, DATA_LOAD_SALES_ORDERS, DATA_LOAD_SAP_ORDERS, DATA_LOAD_BILLING_SAP_ORDERS, DATA_LOAD_DELIVERING_SAP_ORDERS, DATA_LOAD_REPORTS } from '../data/constants';

const yearFormCotnrolls = FormBuilder.formActions(FORM_VIEWING_YEAR);
const materialFormCotnrolls = FormBuilder.formActions(FORM_MATERIAL);
const settingsFormCotnrolls = FormBuilder.formActions(FORM_SETTINGS);

const DataService = new DataServiceClass();

type Responce = Array<DataResponseParams>;

const checkResponce = (response: Responce, action: (data: Data) => RootAction): RootAction => {
  const res = response.pop();
  if (res.status !== "OK") {
    return setErrorMessage(res.message);
  }
  return action(prepareData(res.data));
}

const handleSuccessfulLogin: Epic<RootAction> = (
  action$,
  store
) =>
  action$.pipe(
    filter(action =>
      (isOfType(USER_LOGED_IN_SUCCESS, action))
    ),
    map(action => {
      return DataAction.loadAllData();
    })
  );

const handleLoadAll: Epic<RootAction> = (
  actions$,
  store
) =>
  actions$.pipe(
    filter(action =>
      (isOfType(DATA_LOAD_ALL, action))
    ),
    mergeMap(action => {
      const userPermissions = getUserPermissions(store.value);

      if (userPermissions.dashboard) {
        return [
          DataAction.loadDashboard(),
        ];
      }

      return [
        DataAction.loadPurchase(),
        DataAction.loadSales(),
      ];
    })
  );

const handleLoadPurchase: Epic<RootAction> = (
  action$,
  store
) =>
  action$.pipe(
    filter(action =>
      (isOfType(DATA_LOAD_PURCHASE, action))
    ),
    mergeMap(action => {
      const queue = DataService.getPurchase({});
      return forkJoin(queue).pipe(
        map((response: Responce) => checkResponce(response, DataAction.updatePurchase))
      );
    })
  );

const handleLoadSales: Epic<RootAction> = (
  action$,
  store
) =>
  action$.pipe(
    filter(action =>
      (isOfType(DATA_LOAD_SALES, action))
    ),
    mergeMap(action => {
      const queue = DataService.getSales({});
      return forkJoin(queue).pipe(
        map((response: Responce) => checkResponce(response, DataAction.updateSales))
      );
    })
  );

const handleRefreshPurchase: Epic<RootAction> = (
  action$,
  store
) =>
  action$.pipe(
    filter(action =>
      (isOfType(DATA_REFRESH_PURCHASE, action))
    ),
    mergeMap(action => {
      const queue = DataService.getPurchase(action.payload && { year: action.payload.year } || {});
      return forkJoin(queue).pipe(
        map((response: Responce) => checkResponce(response, DataAction.setPurchase))
      );
    })
  );

const handleRefreshSales: Epic<RootAction> = (
  action$,
  store
) =>
  action$.pipe(
    filter(action =>
      (isOfType(DATA_REFRESH_SALES, action))
    ),
    mergeMap(action => {
      const queue = DataService.getSales(action.payload && { year: action.payload.year } || {});
      return forkJoin(queue).pipe(
        map((response: Responce) => checkResponce(response, DataAction.setSales))
      );
    })
  );

const handleLoadPurchaseOrders: Epic<RootAction> = (
  action$,
  store
) =>
  action$.pipe(
    filter(action =>
    (
      // isOfType(DATA_LOAD_ALL, action) || 
      isOfType(DATA_LOAD_PURCHASE_ORDERS, action)
    )
    ),
    mergeMap(action => {
      const queue = DataService.getPurchaseOrders({});
      return forkJoin(queue).pipe(
        map((response: Responce) => checkResponce(response, DataAction.updatePurchaseOrders))
      );
    })
  );

const handleLoadSalesOrders: Epic<RootAction> = (
  action$,
  store
) =>
  action$.pipe(
    filter(action =>
    (
      // isOfType(DATA_LOAD_ALL, action) || 
      isOfType(DATA_LOAD_SALES_ORDERS, action)
    )
    ),
    mergeMap(action => {
      const queue = DataService.getSalesOrders({});
      return forkJoin(queue).pipe(
        map((response: Responce) => checkResponce(response, DataAction.updateSalesOrders))
      );
    })
  );

const handleLoadOrdersForSap: Epic<RootAction> = (
  action$,
  store
) =>
  action$.pipe(
    filter(action =>
      isOfType(DATA_LOAD_SAP_ORDERS, action)
    ),
    mergeMap(action => {
      const queue = DataService.getSapOrders({});
      return forkJoin(queue).pipe(
        mergeMap((response: any) => {
          const res = response.pop();
          if (res.status !== "OK") {
            return [setErrorMessage(res.message)];
          }
          return [
            DataAction.updateSalesOrders(res.data.sale_orders),
            DataAction.updatePurchaseOrders(res.data.purchase_orders),
            TradingAction.selectAllSapActive(),
          ];
        })
      );
    })
  );

const handleLoadBillingOrdersForSap: Epic<RootAction> = (
  action$,
  store
) =>
  action$.pipe(
    filter(action =>
      isOfType(DATA_LOAD_BILLING_SAP_ORDERS, action)
    ),
    mergeMap(action => {
      const queue = DataService.getBillingSapOrders({});
      return forkJoin(queue).pipe(
        mergeMap((response: any) => {
          const res = response.pop();
          if (res.status !== "OK") {
            return [setErrorMessage(res.message)];
          }
          return [
            DataAction.updateSalesOrders(res.data.sale_orders),
            DataAction.updatePurchaseOrders(res.data.purchase_orders),
          ];
        })
      );
    })
  );

const handleLoadDeliveringOrdersForSap: Epic<RootAction> = (
  action$,
  store
) =>
  action$.pipe(
    filter(action =>
      isOfType(DATA_LOAD_DELIVERING_SAP_ORDERS, action)
    ),
    mergeMap(action => {
      const queue = DataService.getDeliveringSapOrders({});
      return forkJoin(queue).pipe(
        mergeMap((response: any) => {
          const res = response.pop();
          if (res.status !== "OK") {
            return [setErrorMessage(res.message)];
          }
          return [
            DataAction.updateSalesOrders(res.data.sale_orders),
            DataAction.updatePurchaseOrders(res.data.purchase_orders),
          ];
        })
      );
    })
  );

const handleLoadDashboard: Epic<RootAction> = (
  action$,
  store
) =>
  action$.pipe(
    filter(action =>
      (isOfType(DATA_LOAD_DASHBOARD, action))
    ),
    mergeMap(action => {
      const queue = DataService.getDashboard(action.payload && { year: action.payload.year } || {});
      return forkJoin(queue).pipe(
        map((response: Responce) => {
          const res = response.pop();
          if (res.status !== "OK") {
            return setErrorMessage(res.message);
          }
          const dashboardData = parseDashboard((res.data as any));
          return DataAction.updateDashboard(dashboardData);
        })
      );
    })
  );

const handleAppInit: Epic<RootAction> = (
  action$,
  store
) =>
  action$.pipe(
    filter(action =>
      (isOfType(APP_GET_INIT, action))
    ),
    map(action => {
      return DataAction.loadMetaData();
    })
  );

const handleLoadMetaData: Epic<RootAction> = (
  action$,
  store
) =>
  action$.pipe(
    filter(action =>
      (isOfType(DATA_LOAD_METADATA, action))
    ),
    mergeMap(action => {
      const queue = DataService.getMetagata();
      return forkJoin(queue).pipe(
        mergeMap((response: any) => {
          const res = response.pop();
          if (res.status !== "OK") {
            return [setErrorMessage(res.message)];
          }
          const { user, ...metadata } = res.data;
          return [
            user && user.user ? SessionAction.userLogedInSuccess(user) : SessionAction.userLogedInErrorr(null),
            DataAction.updateMaterials(prepareData(metadata.materials)),
            DataAction.updateDivisions(metadata.divisions),
            DataAction.updateCostCenters(metadata.cost_centers),
            DataAction.updateSettings(prepareSettingsData(metadata.settings)),
            DataAction.updateWarehouses(metadata.warehouses),
            DataAction.updateShippingTerms(metadata.shipping_terms),
            DataAction.updateTermsOfPayment(metadata.terms_of_payment),
            DataAction.updateAdditionalCosts(metadata.additional_costs),
            DataAction.updateCountries(metadata.countries),
            DataAction.updateRepresentatives(metadata.representatives),
            DataAction.updateFeaturesAvailability(metadata.features_availability),
            DataAction.updatePrevMonthLocked(metadata.prev_month_locked),
            DataAction.updateCurrencies(metadata.currencies),
            AppAction.updateAppLimitedVersion(metadata.limited_version),
          ];
        })
      );
    })
  );

const handleExportSubmit: Epic<RootAction> = (
  action$,
  store
) =>
  action$.pipe(
    filter(action =>
      (isOfType(DATA_EXPORT, action))
    ),
    mergeMap(action => {
      const year = getSelectedDashboardYear(store.value);
      const filename = `${i18n.translateTo(ExportFileNames[action.payload.type], action.payload.lng)}_${year}_(${getDate()}).xlsx`;
      const queue = DataService.exportData(
        Links[action.payload.type],
        filename,
        { language: action.payload.lng, year },
      );
      return forkJoin(queue).pipe(
        mergeMap((response: Responce) => [])
      );
    })
  );

const handleViewingYearCHange: Epic<RootAction> = (
  action$,
  store
) =>
  action$.pipe(
    filter(action =>
      (action.type === FormActions.CHANGE &&
        action.meta &&
        action.meta.form === FORM_VIEWING_YEAR) && action.payload
    ),
    mergeMap(action => {
      const year = getSelectedDashboardYear(store.value) || null;
      return [
        DataAction.refreshPurchase(year),
        DataAction.refreshSales(year),
        DataAction.loadDashboard(year)
      ];
    })
  );


const handleDefaultViewingYearSelect: Epic<RootAction> = (
  action$,
  store
) =>
  action$.pipe(
    filter(action =>
      (isOfType(DATA_UPDATE_DASHBOARD, action) && !getSelectedDashboardYear(store.value))
    ),
    map(action => {
      const years = action.payload.data && action.payload.data.years;
      const currentYear = new Date().getFullYear();
      return yearFormCotnrolls.setValue("selected_year", years[currentYear] || years[Object.keys(years).pop()]);
    })
  );

const handlePurchaseStatusUpdate: Epic<RootAction> = (
  action$,
  store
) =>
  action$.pipe(
    filter(action =>
      (isOfType(DATA_UPDATE_PURCHASE_STATUS, action))
    ),
    delay(getHighlightTimeout()),
    map(action => {
      const newPurchaseData = prepareData(action.payload.data);
      return DataAction.updatePurchase(newPurchaseData);
    })
  );

const handleSalesStatusUpdate: Epic<RootAction> = (
  action$,
  store
) =>
  action$.pipe(
    filter(action =>
      (isOfType(DATA_UPDATE_SALES_STATUS, action))
    ),
    delay(getHighlightTimeout()),
    map(action => {
      const updSalesData = prepareData(action.payload.data);
      return DataAction.updateSales(updSalesData);
    })
  );

const handleMaterialStatusUpdate: Epic<RootAction> = (
  action$,
  store
) =>
  action$.pipe(
    filter(action =>
      (isOfType(DATA_UPDATE_MATERIALS_STATUS, action))
    ),
    delay(getHighlightTimeout()),
    map(action => {
      const updMaterialData = prepareData(action.payload.data);
      return DataAction.updateMaterials(updMaterialData);
    })
  );

const handleSetMaterialDataToForm: Epic<RootAction> = (
  action$,
  store
) =>
  action$.pipe(
    filter(action =>
      (isOfType(DATA_SET_MATERIAL_TO_FORM, action))
    ),
    mergeMap(action => {
      const materials = getMaterials(store.value);
      const material = materials && materials[action.payload.material_id];
      return Object.keys(materialFieldNames).map(field => materialFormCotnrolls.setValue(materialFieldNames[field], material[field] && String(material[field])));
    })
  );

const handleSetSettingsDataToForm: Epic<RootAction> = (
  action$,
  store
) =>
  action$.pipe(
    filter(action =>
      (isOfType(DATA_UPDATE_SETTINGS, action) || isOfType(DATA_RESET_SETTINGS, action))
    ),
    mergeMap(action => {
      const data = action.payload.data;
      return data ? Object.keys(data).map(key => {
        if (data[key].value_type === 'boolean') {
          return settingsFormCotnrolls.setValue(data[key].key, data[key].value === 'true');
        }
        return settingsFormCotnrolls.setValue(data[key].key, data[key].value);
      }) : [];
    })
  );

const handleChangeSettings: Epic<RootAction> = (
  action$,
  store
) =>
  action$.pipe(
    filter(action =>
      (isOfType(DATA_CHANGE_SETTINGS, action))
    ),
    mergeMap(action => {
      const data = getSettingsData(store.value);
      if (!data) {
        return [];
      }
      const id = String(data[action.payload.id].id);
      const key = String(data[action.payload.id].key);
      const queue = DataService.updateSettings({ id, value: String(action.payload.value), key });
      return forkJoin(queue).pipe(
        mergeMap((response: Responce) => {
          const res = response.pop();
          if (res.status !== "OK") {
            return [DataAction.resetSettignsToDefaut(), setErrorMessage(res.message)];
          }
          return [];
        })
      );
    })
  );

const handleChangeSettingsForm: Epic<RootAction> = (
  action$,
  store
) =>
  action$.pipe(
    filter(action =>
    (action.type === FormActions.CHANGE &&
      action.meta &&
      action.meta.form === FORM_SETTINGS &&
      action.meta.field &&
      action.meta.touch !== undefined)
    ),
    map(action => {
      return DataAction.changeSettigns(action.meta.field, action.payload);
    })
  );

const handleLoadLogs: Epic<RootAction> = (
  action$,
  store
) =>
  action$.pipe(
    filter(action =>
      (isOfType(DATA_LOAD_LOGS, action))
    ),
    mergeMap(action => {
      const queue = DataService.getLogs({});
      return forkJoin(queue).pipe(
        map((response: Responce) => {
          const res = response.pop();
          if (res.status !== "OK") {
            return setErrorMessage(res.message);
          }
          const data = prepareData(res.data);
          return DataAction.setLogs(data);
        })
      );
    })
  );

const handleLoadReports: Epic<RootAction> = (
  action$,
  store
) =>
  action$.pipe(
    filter(action =>
      (isOfType(DATA_LOAD_REPORTS, action))
    ),
    mergeMap(action => {
      const queue = DataService.getReports({});
      return forkJoin(queue).pipe(
        map((response: Responce) => {
          const res = response.pop();
          if (res.status !== "OK") {
            return setErrorMessage(res.message);
          }
          const data = prepareData(res.data);
          return DataAction.setReports(data);
        })
      );
    })
  );

export default combineEpics(
  handleSuccessfulLogin,
  handleLoadAll,
  handleLoadPurchase,
  handleLoadSales,
  handleLoadDashboard,
  handleAppInit,
  handleLoadMetaData,
  handleExportSubmit,
  handleViewingYearCHange,
  handleDefaultViewingYearSelect,
  handlePurchaseStatusUpdate,
  handleMaterialStatusUpdate,
  handleSalesStatusUpdate,
  handleSetMaterialDataToForm,
  handleLoadLogs,
  handleSetSettingsDataToForm,
  handleChangeSettings,
  handleChangeSettingsForm,
  handleLoadPurchaseOrders,
  handleLoadSalesOrders,
  handleLoadOrdersForSap,
  handleLoadBillingOrdersForSap,
  handleLoadDeliveringOrdersForSap,
  handleLoadReports,
  handleRefreshPurchase,
  handleRefreshSales,
);
