import { combineEpics } from "redux-observable";

import session from '../epics/session'
import app from '../epics/app'
import data from '../epics/data'
import order from '../epics/order'
import socket from '../epics/socket'
import trading from '../epics/trading'
import search from '../epics/search'

const rootEpic = combineEpics(socket, data, order, trading, search, app, session);

export default rootEpic;
