import { RootState } from '../store/types.d'
import { createSelector } from 'reselect';
import { CustomerPartner, Data } from '../reducers/data';
import {
    FORM_POSITIONS_AND_ADDITIONAL_COSTS,
    FORM_CUSTOMER_SEARCH,
    FORM_CUSTOMER_SELECT,
    FORM_ORDER_DATA,
    FORM_SHIPPING_ADDRESS,
    valuesToDisplayInv,
    valuesToDisplayDelivering,
    defaultCustomerSearch
} from '../data/constants';
import * as prop from 'ramda/src/prop';
import { positionsFieldNames, additionalCostsFieldNames, orderFieldNames, shippingAddressFieldNames } from '../reducers/form';
import { getMaterials, getPurchaseOrdersRawData, getSalesOrdersRawData, getFeaturesAvailability, getSearchedCustomers, getCurrencies } from './data';
import { salesOrderTypeWithAdditionalFields, purchaseOrderTypeWithAdditionalFields, salesOrderTypeWithShippingAddress } from '../components/homepage/popup/order/data';
import {
    createOptionForShippingPartners,
    createOptionForDetailsShippingPartners,
    createOptionForAdditionalSaleCustomers,
} from '../helpers/helper';

export const getPositionsAndAdditionalCostsFormValues = (state: RootState) =>
    prop('values', state.form[FORM_POSITIONS_AND_ADDITIONAL_COSTS]) || {};

export const getCustomerSearchFormValues = (state: RootState) => {
    const values = prop('values', state.form[FORM_CUSTOMER_SEARCH]);
    const activeSearch = getActiveSearchType(state);
    if (activeSearch === defaultCustomerSearch) {
        return values && values.customer_search;
    } else {
        return values && values[`customer_search-${activeSearch}`];
    }
}

export const getActiveSearchType = (state: RootState) =>
    state.search.activeSearch;

export const getCustomerSelectFormValues = (state: RootState) => {
    const values = prop('values', state.form[FORM_CUSTOMER_SELECT]);
    return values && values.customer_select;
}

export const getSelectedCustomerSelectFormValues = (state: RootState) => {
    const values = prop('values', state.form[FORM_ORDER_DATA]);
    return values && values[orderFieldNames.customer_id];
}

export const getOrderRelevantForSap = (state: RootState) => {
    const values = prop('values', state.form[FORM_ORDER_DATA]);
    return values && values[orderFieldNames.relevant_for_sap];
}

export const getOrderDate = (state: RootState) => {
    const values = prop('values', state.form[FORM_ORDER_DATA]);
    return values && values[orderFieldNames.order_date];
}

export const getOrderRepresentativeNumber = (state: RootState) => {
    const values = prop('values', state.form[FORM_ORDER_DATA]);
    return values && values[orderFieldNames.representative_no];
}

export const getSelectedCurrencyCode = (state: RootState) => {
    const values = prop('values', state.form[FORM_ORDER_DATA]);
    return values && values[orderFieldNames.currency_code];
}

export const getAdditionalSaleCustomerSelectFormValues = (state: RootState) => {
    const showAdditionalField = getShowAdditionalSalesFields(state);
    const values = prop('values', state.form[FORM_ORDER_DATA]);
    const field = showAdditionalField ? orderFieldNames.delivery_partner_sap_id : orderFieldNames.weight_account_partner_sap_id;
    return values && values[field];
}

export const getAdditionalSaleCustomersToSelect = (state: RootState): ((placeholder: string) => { value: string; text: string; }[]) => {
    const showAdditionalField = getShowAdditionalSalesFields(state);
    const customers = getSearchedCustomers(state);

    if (!customers) {
        return (placeholder: string) => [];
    }

    const customer_id = getSelectedCustomerSelectFormValues(state);
    const customer = customers[customer_id];

    const getPartnersToSelect = (): CustomerPartner[] => {
        if (!customer) {
            return [];
        }

        if (showAdditionalField) {
            return customer.shipping_partners as CustomerPartner[];
        }

        return customer.metal_account_partners as CustomerPartner[];
    };

    const partners = getPartnersToSelect();

    if (partners.length < 2) {
        return (placeholder: string) => [];
    }

    return createOptionForAdditionalSaleCustomers(partners);
}

export const getOrderShippingAddressPartner = (state: RootState) => {
    const values = prop('values', state.form[FORM_ORDER_DATA]);
    return values && values[orderFieldNames.delivery_partner_sap_id];
}

export const getOrderFormValues = (state: RootState) => {
    return prop('values', state.form[FORM_ORDER_DATA]);
}

export const getShippingAddressFormValues = (state: RootState) =>
    prop('values', state.form[FORM_SHIPPING_ADDRESS]);

export const getSelectedCustomerShippingPartnerId = createSelector(
    [getShippingAddressFormValues],
    (values) => values && values.partner ? values.partner : ''
);

export const getOrderDetailsData = (state: RootState) =>
    state.data.orderDetails;

export const getActiveOrder = (state: RootState) =>
    state.data.activeOrder;

export const getSelectedOrderType = (state: RootState) => {
    const values = getOrderFormValues(state);
    return values && values.order_type;
}

export const getOrderRegisteredInSAP = createSelector(
    [getOrderDetailsData],
    (orderDetails) => {
        const invoiceCreated = Boolean(orderDetails && (orderDetails.order_details.sap_order_id || orderDetails.order_details.invoice_id || orderDetails.order_details.delivery_note_id));
        return invoiceCreated;
    }
)

export const getOrderInvoiceCreated = createSelector(
    [getOrderDetailsData],
    (orderDetails) => {
        return Boolean(orderDetails && orderDetails.order_details.invoice_id);
    }
)

export const getOrderDeliveryCreated = createSelector(
    [getOrderDetailsData],
    (orderDetails) => {
        return Boolean(orderDetails && orderDetails.order_details.delivery_note_id);
    }
)

export const getIsLimitedUpdate = (state: RootState) => {
    return state.app.limitedVersion && state.data.orderIsEdited;
}

export const getOrderPrevMonthLock = createSelector(
    [getOrderDetailsData],
    (orderDetails) => {
        return Boolean(orderDetails && orderDetails.prev_month_lock);
    }
);

export const getShowAdditionalSalesFields = createSelector(
    [getSelectedOrderType],
    (orderType) => orderType && [salesOrderTypeWithAdditionalFields, purchaseOrderTypeWithAdditionalFields].includes(orderType)
)

export const getCreateOrderData = (state: RootState) => {
    const values = getOrderFormValues(state);
    if (!values) {
        return null;
    }
    const orderValues: { [index: string]: string } = {};
    Object.keys(orderFieldNames).map(
        key => orderValues[key] = values[orderFieldNames[key]] !== undefined ? values[orderFieldNames[key]] : null
    );
    return orderValues;
}

export const getShippingAddressData = createSelector(
    [getShippingAddressFormValues],
    (values) => {
        if (!values) {
            return null;
        }
        const shippingAddressValues: { [index: string]: string } = {};
        Object.keys(shippingAddressFieldNames).map(key => shippingAddressValues[key] = values[shippingAddressFieldNames[key]] || null);
        return shippingAddressValues;
    }
);

export const getOrderPosiotionItems = (state: RootState) =>
    state.data.orderPositionItems;

export const getOrderPositionData = createSelector(
    [getOrderPosiotionItems, getPositionsAndAdditionalCostsFormValues],
    (items, values) => {
        if (!items || !values) {
            return [];
        }
        const positionItems: { [index: string]: string }[] = [];
        items.map(id => {
            const position: { [index: string]: string } = {};
            position['id'] = id;
            Object.keys(positionsFieldNames).map(field => position[positionsFieldNames[field]] = values[`${positionsFieldNames[field]}-${id}`] || null);
            positionItems.push(position);
        })
        return positionItems;
    }
)

export const getOrderPositionItemsWeightUnits = createSelector(
    [getOrderPosiotionItems, getPositionsAndAdditionalCostsFormValues, getMaterials],
    (items, values, materials) => {
        if (!items || !values || !materials) {
            return {};
        }

        const weightUnits: { [positionIndex: string]: string[] } = {};
        items.forEach(id => {
            const materialCode = values[`${positionsFieldNames.material_code}-${id}`];

            if (!materialCode) {
                return null;
            }

            const material = Object.values(materials).find(
                material => material.material_code === materialCode
            );

            if (!material) {
                return null;
            }

            weightUnits[id] = material.weight_units as string[];
        });

        return weightUnits;
    }
)

export const getOrderAdditionalCostItems = (state: RootState) =>
    state.data.orderAdditionalCostItems;

export const getOrderAdditionalCostData = createSelector(
    [getOrderAdditionalCostItems, getPositionsAndAdditionalCostsFormValues],
    (items, values) => {
        if (!items || !values) {
            return [];
        }
        const additionalCostItems: { [index: string]: string }[] = [];
        items.map(id => {
            const additionalCost: { [index: string]: string } = {};
            additionalCost['id'] = id;
            Object.keys(additionalCostsFieldNames).map(field => additionalCost[additionalCostsFieldNames[field]] = values[`${additionalCostsFieldNames[field]}-${id}`] || null);
            additionalCostItems.push(additionalCost);
        });
        return additionalCostItems;
    }
)

export const getOrderCustomerShippingPartners = (state: RootState) =>
    state.data.orderCustomerShippingPartners;

export const getOrderCustomerShippingPartnersAsOptions = createSelector(
    [getOrderCustomerShippingPartners],
    (shippingPartners) => createOptionForShippingPartners(shippingPartners)
);

export const getOrderDetailsCustomerShippingPartnersAsOptions = createSelector(
    [getOrderCustomerShippingPartners],
    (shippingPartners) => createOptionForDetailsShippingPartners(shippingPartners)
);

export const getSelectedCustomerShippingPartnerData = createSelector(
    [getOrderCustomerShippingPartners, getSelectedCustomerShippingPartnerId],
    (shippingPartners, shippingPartnerId) => shippingPartnerId ? shippingPartners[Number(shippingPartnerId)] : {}
);

export const getShowSalesShippingAddressFields = createSelector(
    [getSelectedOrderType],
    (orderType) => orderType && salesOrderTypeWithShippingAddress === orderType
);

export const getShippingAddressFormIsDisplayed = (state: RootState) =>
    state.data.shippingAddressFormIsDisplayed;

export const getSelectedCustomerIsBlocked = (state: RootState): boolean =>
    state.data.selectedCustomerIsBlocked;

export const getSelectedCustomerShippingPartnerIsBlocked = (state: RootState): boolean =>
    state.data.selectedCustomerShippingPartnerIsBlocked;

export const getCustomerOrPartnersAreBlocked = createSelector(
    [getSelectedCustomerIsBlocked, getSelectedCustomerShippingPartnerIsBlocked],
    (customerIsBlocked, customerShippingPartnerIsBlocked) =>
        customerIsBlocked || customerShippingPartnerIsBlocked
);

export const getInvoiceableData = (rawValues: Data) => {
    if (!rawValues) {
        return rawValues;
    }
    const values: { [index: string]: Data } = {};

    Object.keys(rawValues).map(key => {
        if (!rawValues[key].canceled && rawValues[key].relevant_for_sap && !rawValues[key].sap_order_id) {
            const id = String(rawValues[key].id);
            values[id] = {};
            valuesToDisplayInv.map((p: string) => {
                values[id][p] = { [p]: rawValues[key][p] };
            })
        }
    });
    return values;
};

export const getBillingData = (rawValues: Data) => {
    if (!rawValues) {
        return rawValues;
    }
    const values: { [index: string]: Data } = {};

    Object.keys(rawValues).map(key => {
        if (!rawValues[key].canceled && rawValues[key].sap_order_id && !rawValues[key].invoice_id) {
            const id = String(rawValues[key].id);
            values[id] = {};
            valuesToDisplayInv.map((p: string) => {
                values[id][p] = { [p]: rawValues[key][p] };
            })
            values[id].sap_id = { sap_id: rawValues[key].sap_order_id };
        }
    });
    return values;
};

export const getDeliveringData = (rawValues: Data) => {
    if (!rawValues) {
        return rawValues;
    }
    const values: { [index: string]: Data } = {};

    Object.keys(rawValues).map(key => {
        if (
            !rawValues[key].canceled &&
            rawValues[key].sap_order_id &&
            !rawValues[key].delivery_note_id
        ) {
            const id = String(rawValues[key].id);
            values[id] = {};
            valuesToDisplayDelivering.map((p: string) => {
                values[id][p] = { [p]: rawValues[key][p] };
            })
            values[id].sap_id = { sap_id: rawValues[key].sap_order_id };
        }
    });
    return values;
};

export const getInvoiceableItems = (rawValues: Data) => {
    if (!rawValues) {
        return [];
    }
    const values: string[] = [];

    Object.keys(rawValues).map(key => {
        if (!rawValues[key].canceled && rawValues[key].relevant_for_sap && !rawValues[key].sap_order_id) {
            const id = String(rawValues[key].id);
            values.push(id);
        }
    });
    return values;
};


export const getInvoiceablePurchaseData = createSelector(
    [getPurchaseOrdersRawData],
    (data) => getInvoiceableData(data)
)

export const getInvoiceablePurchaseItems = createSelector(
    [getPurchaseOrdersRawData],
    (data) => getInvoiceableItems(data)
)

export const getInvoiceableSalesData = createSelector(
    [getSalesOrdersRawData],
    (data) => getInvoiceableData(data)
)

export const getInvoiceableSalesItems = createSelector(
    [getSalesOrdersRawData],
    (data) => getInvoiceableItems(data)
)

export const getBillingSalesData = createSelector(
    [getSalesOrdersRawData],
    (data) => getBillingData(data)
)

export const getBillingPurchaseData = createSelector(
    [getPurchaseOrdersRawData],
    (data) => getBillingData(data)
)

export const getDeliveringPurchaseData = createSelector(
    [getPurchaseOrdersRawData],
    (data) => getDeliveringData(data)
)

export const getDeliveringSalesData = createSelector(
    [getSalesOrdersRawData],
    (data) => getDeliveringData(data)
)

export const getPriceUnits = createSelector(
    [getOrderPosiotionItems, getOrderAdditionalCostItems, getPositionsAndAdditionalCostsFormValues, getMaterials, getSelectedCurrencyCode, getCurrencies],
    (orderItems, orderAdditionalCostItems, values, materials, currencyCode, currencies) => {
        const units: { [index: string]: string } = {};

        if (!values || !orderItems || orderItems.length === 0 || !materials) {
            return units;
        }

        orderItems.forEach(id => {
            const materialCode = values[`${positionsFieldNames.material_code}-${id}`];
            const selectedWeightUnit = values[`${positionsFieldNames.weight_unit}-${id}`] as string;

            if (!materialCode || !selectedWeightUnit) {
                return null;
            }

            const material = Object.values(materials).find(
                material => material.material_code === materialCode
            );

            const currency = Object.values(currencies).find(currency => currency.currency_code === currencyCode);

            if (!material || !currency) {
                return null;
            }

            units[id] = currency.currency_sign + '/' + (material.price_units as Data)[selectedWeightUnit].price_weight_unit;
        });

        orderAdditionalCostItems.forEach(id => {
            const currency = Object.values(currencies).find(currency => currency.currency_code === currencyCode);

            if (!currency) {
                return null;
            }

            units[id] = String(currency.currency_sign);
        });

        return units;
    }
)

export const getPriceMultiplier = createSelector(
    [getOrderPosiotionItems, getPositionsAndAdditionalCostsFormValues, getMaterials],
    (orderItems, values, materials) => {
        const multiplier: { [index: string]: number } = {};

        if (!values || !orderItems || orderItems.length === 0 || !materials) {
            return multiplier;
        }

        orderItems.forEach(id => {
            const materialCode = values[`${positionsFieldNames.material_code}-${id}`];
            const selectedWeightUnit = values[`${positionsFieldNames.weight_unit}-${id}`] as string;

            if (!materialCode || !selectedWeightUnit) {
                return null;
            }

            const material = Object.values(materials).find(
                material => material.material_code === materialCode
            );

            if (!material) {
                return null;
            }

            multiplier[id] = (material.price_units as Data)[selectedWeightUnit].multiplier as number;
        });

        return multiplier;
    }
)

export const getOrderTabHiddenColumns = createSelector(
    [getFeaturesAvailability],
    (featuresAvailability) => {
        return featuresAvailability.delivery_note ?
            [] : ['delivery_note_id'];
    }
);
