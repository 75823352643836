//To change displaying of label add new record below
//Example: 'old label': 'new label'

export default {
  'id': 'ID',
  'created_at': 'Created At',
  'order_date': 'Order Date',
  'invoice_id': 'Invoice SAP ID',
  'canceled': 'Canceled',
  'cost_center': 'Cost Center',
  'division': 'Division',
  'locked': 'Locked',
  'locked_by': 'Locked by',
  'material_code': 'Material',
  'name': 'Name',
  'notes': 'Notes',
  'sap_order_id': 'Order SAP ID',
  'delivery_note_id': 'Delivery Note ID',
  'price': 'Price',
  'relevant_for_sap': 'Relevant For SAP',
  'unmatched_weight': 'Unmatched Weight',
  'unmatched weight': 'Unmatched Weight',
  'weight': 'Weight',
  'sap_id': 'SAP ID',
  'age': 'Age',
  'phone': 'Phone',
  'color': 'Color',
  'address': 'Address',
  'internal': 'Internal',
  'unit_price': 'Unit Price',
  'external_ref_number': 'External',
  'position_price': 'Position Price',
  'customer_sap_id': 'Customer SAP ID',
  'created_by': 'Created By',
  'affected_object': 'Affected Object',
  'action': 'Action',
  'order_type': 'Order Type',
  'order_no': 'Order no.',
  'terms_of_payment': 'Terms of Payment',
  'billing_attachment_text': 'Billing Attachment Text',
  'vat_transaction_indicator': 'Vat Transaction Indicator',
  'keep_an_eye_on_it': 'needs attention',
  'vat_transaction_indicator_short': 'Vat Ind',
  'value_date': 'Value Date',
  'primary_name': 'Name',
  'secondary_name': 'Name_2',
  'street': 'Street',
  'house_number': 'House Number',
  'post_code': 'Post Code',
  'city': 'City',
  'contact': 'Contact',
  'incoterm': 'Incoterm',
  'incoterm2': 'Incoterm2',
  'positions': 'Positions',
  'additional_costs': 'Additional Costs',

  'purchases': 'Purchase',
  'sales': 'Sales',
  'sale_orders': 'Sale Orders',
  'purchase_orders': 'Purchase Order',
  'multiplier': 'Multiplier',
  'price_unit': 'Price Unit',
  'weight_unit': 'Weight Unit',
  'material_number': 'Material Number',
  'profits_and_losses': 'Profits and Losses',
  'inventory': 'Inventory',
  'matching operation canceled': 'Matching operation canceled',

  'login': 'Login',
  'call_type': 'Call Type',
  'severity': 'Severity',
  'code': 'Code',
  'function': 'Function',
  'request': 'Request',
  'response': 'Response',

  'log': 'Log',
  'history': 'History',
  'features_availability': 'Features availability',
  'trading_process': 'Trading process',
  'user_settings': 'User settings',

  'download': 'Download',
  'filename': 'Report Name',
};
