import * as React from 'react';
import { i18nContext } from '../../../../language';
import { HandledFormProps, FormBuilder, FormElements, FormGroupProps } from '../../../utils/form/form';
import { RootState } from '../../../../store/types';
import { connect } from 'react-redux';
import { Data } from '../../../../reducers/data';
import { getSettingsData } from '../../../../selectors';
import { FORM_SETTINGS } from '../../../../data/constants';

export interface SettingsTabProps extends HandledFormProps {
    title?: string;
    settingsItems?: Data;

    onToggleChange?: (name: string, values: string) => void;
}

const formBuilder = new FormBuilder()

const SettingsForm = FormBuilder.form<SettingsTabProps>(
    (props: SettingsTabProps) => (
        <i18nContext.Consumer>
            {i18n => {
                const items: { [index: string]: FormGroupProps[] } = {};
                props.settingsItems && Object.keys(props.settingsItems).map(key => {
                    const name = i18n.t(String(props.settingsItems[key].friendly_name));
                    if (!items[String(props.settingsItems[key].type)]) {
                        items[String(props.settingsItems[key].type)] = [];
                    }
                    if (props.settingsItems[key].value_type === "boolean") {
                        items[String(props.settingsItems[key].type)].push({
                            label: name,
                            wrapClass: 'd-flex flex-row justify-content-between py-1',
                            classNameRight: 'd-flex align-items-center',
                            classNameLeft: 'd-flex align-items-center',
                            input: FormElements.toggle({
                                name: key,
                                // onChange: () => props.onToggleChange(key, String(!(props.formValues[key] === "true"))),
                            })
                        });
                    } else {
                        items[String(props.settingsItems[key].type)].push({
                            label: name,
                            wrapClass: 'd-flex flex-row justify-content-between py-1',
                            classNameRight: 'd-flex align-items-center',
                            classNameLeft: 'd-flex align-items-center',
                            input: FormElements.input({
                                name: key,
                            })
                        });
                    }
                })
                const form_items: FormGroupProps[] = [];
                Object.keys(items).map(item_type => {
                    form_items.push(
                        {
                            groupLabel: (<h3 className="mt-2">{i18n.t(item_type)}</h3>),
                        },
                        ...items[item_type]);
                });
                formBuilder.setElements(form_items);
                return (
                    <div className="settigns-form col-6 mt-3 mx-auto">
                        <h2 className="mb-3">{i18n.t(props.title)}</h2>
                        {formBuilder.build()}
                    </div>
                )
            }}
        </i18nContext.Consumer>
    ),
    FORM_SETTINGS
);

const mapStateToProps = (
    state: RootState,
    ownProps: SettingsTabProps
) => {
    return {
        settingsItems: getSettingsData(state),
    }
};
export const SettingsFormContainer = connect(
    mapStateToProps,
    null
)(SettingsForm);

export default SettingsFormContainer;