

import { FormAction } from 'redux-form';
import { Epic, combineEpics } from 'redux-observable';
import {
    filter,
    map,
    mergeMap
} from 'rxjs/operators';
import { isOfType } from 'typesafe-actions';
import * as SessionAction from '../actions/session';
import { RootAction } from '../store/types';
import { USER_SUBMIT_LOGIN_FORM, USER_SUBMIT_LOGOUT } from '../data/constants';
import { SessionServiceClass } from '../services/login'
import { forkJoin } from 'rxjs';
import * as DataAction from '../actions/data';

const SessionService = new SessionServiceClass();

const handleSubmitLoginForm: Epic<FormAction, RootAction> = (
    action$,
    store
) =>
    action$.pipe(
        filter(
            action =>
                (isOfType(USER_SUBMIT_LOGIN_FORM, action))
        ),
        mergeMap((action: FormAction) => {
            const login = SessionService.loginReques({});
            return forkJoin(login).pipe(
                mergeMap((response: any) => {
                    const { redirect_link } = response.pop();

                    if (redirect_link) {
                        window.location.replace(redirect_link);
                        return [
                            DataAction.createRequestStatusUpdate("OK"),
                        ];
                    }

                    return [
                        DataAction.createRequestStatusUpdate("ERROR"),
                    ];
                })
            );
        })
    );

const handleSubmitLogout: Epic<FormAction, RootAction> = (
    action$,
    store
) =>
    action$.pipe(
        filter(
            action =>
                (isOfType(USER_SUBMIT_LOGOUT, action))
        ),
        mergeMap((action: FormAction) => {
            const login = SessionService.logoutReques({})
            SessionService.storeSessionDataInSessionStorage({
                authorizationToken: '',
                user: '',
                role: '',
            });
            return forkJoin(login).pipe(
                map((response: any) => {
                    return SessionAction.userLogoedOut();
                })
            );
        })
    );

export default combineEpics(
    handleSubmitLoginForm,
    handleSubmitLogout,
);
