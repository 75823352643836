import * as React from 'react';
import { default as AdditionalCosts } from '../additional-costs/additional-costs';
import { default as Positions } from '../positions/positions';
import { HandledFormProps, FormBuilder } from '../../../utils/form/form';
import { FORM_POSITIONS_AND_ADDITIONAL_COSTS} from '../../../../data/constants';
import { i18nContext } from '../../../../language';
import { salesOrderTypeWithAdditionalFields } from '../order/data';

const Textes = {
    addPosition: "Add Position",
    addAdditionalCost: "Add Additional Cost",
    addShippingAddress: "Add Shipping Adress",
    save: "Create",
    cancel: "Close without saving",
};

export interface CreateOrderTableFormsContainerComponentProps extends HandledFormProps {
    protected?: boolean;
    positionItems?: string[];
    additionalCostItems?: string[];
    readonly?: boolean;
    isPurchase?: boolean;
    customerOrPartnersAreBlocked?: boolean;
    showSalesShippingAddressFields?: boolean;
    additionalCostsAreEnabled?: boolean;

    shippingAddressFormIsDisplayed?: boolean;

    addPositionItem?: () => void;
    addAdditionalCostItem?: () => void;

    onCansel?: () => void;
    onSubmit?: () => void;
}

const PositionItemForm = FormBuilder.form<CreateOrderTableFormsContainerComponentProps>(
    (props: CreateOrderTableFormsContainerComponentProps) => {
        return (
            <i18nContext.Consumer>
                {i18n => (
                    <div className="table-container w-100">
                        <Positions
                            className="p-3"
                            protected={props.protected}
                            isPurchase={props.isPurchase}
                        />
                        <AdditionalCosts
                            className="p-3"
                            protected={props.protected}
                        />
                        <div className="d-flex justify-content-between create-order-table-forms-buttons-countainer mt-3">
                            <div className="d-flex">
                                <div className={[
                                    "d-flex",
                                    "mr-3",
                                    "p-0",
                                    "protectable",
                                    props.protected || props.positionItems && props.positionItems.length >= 10 ? "protectable--protected" : ""
                                ].join(' ')}>
                                    <button className="w-100 btn btn-secondary"
                                        onClick={e => {
                                            e.preventDefault();
                                            props.addPositionItem();
                                        }}>
                                        {i18n.t(Textes.addPosition)}
                                    </button>
                                </div>
                                {props.additionalCostsAreEnabled ? <div className={[
                                    "d-flex",
                                    "mr-3",
                                    "p-0",
                                    "protectable",
                                    props.protected || props.additionalCostItems && props.additionalCostItems.length >= 10 ? "protectable--protected" : ""
                                ].join(' ')}>
                                    <button className="w-100 btn btn-secondary"
                                        onClick={e => {
                                            e.preventDefault();
                                            props.addAdditionalCostItem();
                                        }}>
                                        {i18n.t(Textes.addAdditionalCost)}
                                    </button>
                                </div> : null}
                            </div>
                            {!props.readonly && (<div className="d-flex">
                                <div className={[
                                    "d-flex",
                                    "mr-3",
                                    "p-0",
                                    "protectable",
                                    (props.protected || props.customerOrPartnersAreBlocked) ? "protectable--protected" : ""
                                ].join(' ')}>
                                    <button
                                        className="w-100 btn btn-secondary text-center" type="submit">
                                        {i18n.t(Textes.save)}
                                    </button>
                                </div>
                                <div className="d-flex">
                                    <button className="btn btn-secondary text-center" onClick={e => {
                                        e.preventDefault();
                                        props.onCansel();
                                    }}>
                                        {i18n.t(Textes.cancel)}
                                    </button>
                                </div>
                            </div>)}
                        </div>
                    </div>
                )}
            </i18nContext.Consumer>
        )
    },
    FORM_POSITIONS_AND_ADDITIONAL_COSTS
);

export default PositionItemForm;