import * as React from 'react';
import { connect } from 'react-redux';
import { RootState, RootAction } from '../../../../store/types';
import { getCompPurchaseData, getSelectedPurchase, isCompPurchaseMain } from '../../../../selectors';
import { getMaterialsColor } from '../../../../selectors/index';
import { selectPurchase } from '../../../../actions/trading';
import { SelectebleOrderTab, SelectebleOrderTabProps } from '../selected-order';

const mapStateToProps = (
    state: RootState,
    ownProps: SelectebleOrderTabProps
) => {
    const items = getCompPurchaseData(state);
    return {
        items,
        isLoaded: !!items,
        showId: true,
        materialColors: getMaterialsColor(state),
        selectedMatch: getSelectedPurchase(state),
        isMain: isCompPurchaseMain(state),
    };
};

const mapDispatchToProps = (
    dispatch: (action: RootAction) => void,
    ownProps: SelectebleOrderTabProps
) => {
    return {
        onRowSelect(id: string) {
            dispatch(selectPurchase(id));
        },
    };
};

export const CompPurchaseBarContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(SelectebleOrderTab);

export default CompPurchaseBarContainer;