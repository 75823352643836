interface StorageHandler {
  setItem: (name: string, value: string) => void;
  getItem: (name: string) => string;
  removeItem: (name: string) => void;
}
class StorageService {
  private _storage: StorageHandler;
  private _prefix: string;
  constructor(storage: StorageHandler, prefix: string = '') {
    this._storage = storage;
    this._prefix = prefix;
  }
  set(name: string, value: any, prefix: string = this._prefix) {
    this._storage.setItem(`${prefix}${name}`, JSON.stringify(value));
    return this;
  }
  get(name: string, prefix: string = this._prefix) {
    return JSON.parse(this._storage.getItem(`${prefix}${name}`));
  }
  setPrefix(prefix: string) {
    this._prefix = prefix;
  }
  clear(prefix: string) {
    Object.keys(this._storage).forEach(key => {
      if (key.startsWith(prefix)) {
        this._storage.removeItem(key);
      }
    });
  }
}
const mock = {
  setItem: (name: string, value: string): void => {
    return null;
  },
  getItem: (name: string): string => {
    return null;
  },
  removeItem: (name: string): string => {
    return null;
  },
};
export const StorageLocal = new StorageService(window.localStorage || mock);
export const StorageSession = new StorageService(window.sessionStorage || mock);
export default StorageLocal;
