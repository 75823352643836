import * as React from 'react';
import { connect } from 'react-redux';
import { i18nContext, I18n } from '../../../../language';
import { RootAction } from '../../../../store/types';
import { confirmMatch } from '../../../../actions/trading';
import { HandledFormProps, FormBuilder, FormElements } from '../../../utils/form/form';
import { FORM_TRADING_MODE, Modes } from '../../../../data/constants';

interface TradingModeProps extends HandledFormProps {
    onSubmit?: () => void;
}
const modes: { [index: string]: string } = {
    BUY_SELL: "Buy / Sell",
    INVENORY: "Inventory",
    PROFITS_LOSSES: "Profits and Losses"
}

const TradingModeForm = FormBuilder.form<TradingModeProps>(
    (props: TradingModeProps) => {
        return (
            <i18nContext.Consumer>
                {(i18n: I18n) =>
                    <div className="d-flex flex-column mt-5">
                        <h3 className="text-center">{i18n.t("Matching mode")}</h3>
                        <div className="d-flex flex-column card pt-3 px-3 pb-2">
                            {Object.values(Modes).map(key => (
                                <label
                                    htmlFor={`mode-select-${key}`}
                                    className="d-flex align-items-center pointer"
                                >
                                    {FormElements.input({
                                        id: `mode-select-${key}`,
                                        name: "mode_select",
                                        type: "radio",
                                        value: key,
                                        required: true,
                                        classInputWrapper: "radio-input",
                                    })}
                                    <div className="w-100 pl-2">
                                        {i18n.t(modes[key])}
                                    </div>
                                </label>
                            ))}
                        </div>
                        <button
                            className="w-100 mt-3 btn btn-secondary text-center"
                        >
                            {i18n.t("Confirm match")}
                        </button>
                    </div>
                }
            </i18nContext.Consumer>
        )
    },
    FORM_TRADING_MODE
);

const mapDispatchToProps = (
    dispatch: (action: RootAction) => void,
    ownProps: TradingModeProps
) => {
    return {
        onSubmit() {
            dispatch(confirmMatch());
        },
    };
};
export const CompensationModeContainer = connect(
    null,
    mapDispatchToProps
)(TradingModeForm);

export default CompensationModeContainer;