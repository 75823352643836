import * as React from 'react';
import { GenericFieldHTMLAttributes, WrappedFieldProps, BaseFieldProps } from 'redux-form';
import { PopoverError } from './form';


export class FocusInput extends React.Component<GenericFieldHTMLAttributes | WrappedFieldProps | BaseFieldProps | any> {
    inputRef: React.RefObject<HTMLInputElement>;

    constructor(props: any) {
        super(props);
        this.inputRef = React.createRef();
        this.focus = this.focus.bind(this);
    }
    focus() {
        this.inputRef.current.focus();
    }

    render() {
            const props = this.props;
            const {
                input,
                label,
                className,
                classInputWrapper,
                type,
                meta: { touched, error, warning },
            } = props;
            return (
                <div
                    className={[
                        classInputWrapper || 'w-100',
                        'input-wrapper',
                        error ? 'input-has-errors' : '',
                        warning ? 'input-has-warnings' : '',
                    ]
                        .join(' ')
                        .trim()}
                >
                    <input
                        key="input"
                        {...props}
                        {...input}
                        className={[className].join(' ').trim()}
                        placeholder={props.placeholder || label || ''}
                        type={type}
                        ref={this.inputRef}
                    />
                    {touched &&
                        ((error && (
                            <PopoverError key="error" className="validation-error">
                                {error}
                            </PopoverError>
                        )) ||
                            (warning && (
                                <PopoverError key="warning" className="validation-warning">
                                    {warning}
                                </PopoverError>
                            )))}
                </div>
            );
        }
    }