import { I18n } from '../../../../language';

export const Textes: { [index: string]: string } = {
    notes: 'SAP-Comment',
    internal_notes: 'Internal Notes',
    searchPlaceholder: 'Customer number / Company name',
    startSearch: 'Start Search',
    order_no: 'Order no.',
    order_date: 'Order Date',
    terms_of_payment: 'Terms of payment',
    vat_transaction_indicator: 'VAT-indicator',
    address: 'Address(s)',
    contact: 'Representative(s)',
    billing_attachment_text: 'Billing Attachment Text',
    primary_name: 'Name',
    secondary_name: 'Name_2',
    value_date: 'Value Date',
    order_type: 'Order Type',
    delivery_note: 'Delivery Note',
    storage_location_code: 'Storage Location Code',
    incoterm: 'Incoterm',
    incoterm2: 'Incoterm2',
    shipping_terms: 'Shipping Terms',
    street: 'Street',
    house_number: 'House Number',
    relevant_for_sap: 'relevant_for_sap',
    post_code: 'Post Code',
    country: 'Country',
    city: 'City',
    delivery_partner_sap_id: 'Delivery Partner ID',
    weight_account_partner_sap_id: 'Weight Account Partner ID',
    customer_id: 'Customer ID',
    invoice_id: 'Invoice SAP ID',
    delivery_note_id: 'Delivery Note SAP ID',
    partner: 'Partner',
    currency: 'Currency',
};

export const purchaseOrderTypeOptions = (i18n: I18n): Array<{ value: string, text: string }> => [
    { value: 'ZGAK', text: i18n.t('ZGAK') },
    { value: 'ZAKM', text: i18n.t('ZAKM') },
];

export const salesOrderTypeOptions = (i18n: I18n): Array<{ value: string, text: string }> => [
    { value: 'ZGVK', text: i18n.t('ZGVK') },
    { value: 'ZVKM', text: i18n.t('ZVKM') },
];

export const purchaseOrderTypeDefaultOption = 'ZGAK';
export const salesOrderTypeDefaultOption = 'ZGVK';

export const purchaseOrderTypeWithAdditionalFields = 'ZAKM';
export const salesOrderTypeWithAdditionalFields = 'ZVKM';

export const salesOrderTypeWithShippingAddress = 'ZVKM';

export const defaultMaterialCode = 'AU';

export const defaultWeightUnit = 'g';

export const defaultCurrency = 'EUR';