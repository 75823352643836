import * as React from 'react';
import { default as LanguageSwitcher } from '../../language-switcher';
import { i18nContext } from '../../../language';
import { RootState, RootAction } from '../../../store/types';
import { connect } from 'react-redux';
import { getUserData } from '../../../selectors';
import { userLogoutSubmit } from '../../../actions/session';

const Textes = {
    user_header: "You are logged in as",
    logout: "Logout",
};

interface UserHeaderProps {
    userData?: {
        user: string;
        role: string;
    },

    onLogoutSubmit?: () => void,
}

class UserHeader extends React.Component<UserHeaderProps, {}> {
    render() {
        return (
            <i18nContext.Consumer>
                {i18n =>
                    <div key="user" className="col-5 mt-3 d-flex">
                        <div className="d-flex flex-row col-10 align-items-center justify-content-end">
                            <span>
                                {i18n.t(Textes.user_header)}
                            </span>
                            <span className="pl-1 text-primary">
                                {`${this.props.userData.user} (${this.props.userData.role})`}
                            </span>
                            <button className="mx-1 btn btn-secondary ml-3 text-center" onClick={this.props.onLogoutSubmit}>
                                {i18n.t(Textes.logout)}
                            </button>
                        </div>
                        <LanguageSwitcher />
                    </div>
                }
            </i18nContext.Consumer>
        )
    }
}

const mapStateToProps = (
    state: RootState,
    ownProps: UserHeaderProps
) => {
    return {
        userData: getUserData(state),
    }
};
const mapDispatchToProps = (
    dispatch: (action: RootAction) => void,
    ownProps: UserHeaderProps
) => {
    return {
        onLogoutSubmit() {
            dispatch(userLogoutSubmit());
        }
    };
};

const UserHeaderContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(UserHeader);

export default UserHeaderContainer;
