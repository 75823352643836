import * as React from 'react';
import { FormBuilder } from '../../../utils/form/form';
import { i18nContext } from '../../../../language';
import { PositionsFormProps } from '../positions/position-form';
import { Navs } from '../../../utils/navs';
import { FORM_POSITIONS_AND_ADDITIONAL_COSTS } from '../../../../data/constants';
import DetaillPositionItemForm from './position-form';
import DetailAdditionalCostItemForm from './additional-cost-form';
import HistoryBarContainer from '../../tabs/history';

const Textes = {
    title: "Order Details",
    positions: "Positions",
    additionalCosts: "Additional Costs",
    history: "History",
    close: "Close",
    delete: "Delete Order",
    edit: "Edit",
    save: "Save Changes",
    discard: "Discard Changes",
    closeWS: "Close without saving",
    cancel: "Cancel",
    removeText: "Do you really want to delete this order?",
    delete_delivery: "Delete Delivery",
    delete_invoice: "Delete Invoice",
}

const detailsTabsTextes = {
    positions: "positions",
    additional_costs: "additional_costs",
    history: "history",
}

export interface DetailTabsProps extends PositionsFormProps {
    isEditable?: boolean;
    isRegisteredInSAP?: boolean;
    isInvoiceCreated: boolean;
    isDeliveryCreated: boolean;
    minWeight?: { [index: string]: number };
    additionalCostsIds?: string[];
    orderDetails?: any;
    activeTab?: string;
    isPurchase?: boolean;
    prevMonthLock?: boolean;
    additionalCostsAreEnabled?: boolean;
    isLimitedUpdate?: boolean;

    onTabLoad?: (eventKey: string, e: React.SyntheticEvent<{}>) => void;
    onSubmit?: () => void;
    handleEditable?: () => void;
    discardChanges?: () => void;
    handleDelete?: () => void;
    handleDeleteDelivery: () => void;
    handleDeleteInvoice: () => void;
    handeClosePopup?: () => void;
}

const DetailsTabs = FormBuilder.form<DetailTabsProps>(
    (props: DetailTabsProps) => {
        return (
            <i18nContext.Consumer>
            {i18n => {
                const getDetailsTabs = () => {
                    return [
                        {
                            key: detailsTabsTextes.positions,
                            name: i18n.t(Textes.positions),
                            content: (
                                <DetaillPositionItemForm
                                    readonly={!props.isEditable || props.isRegisteredInSAP || props.isLimitedUpdate}
                                    items={props.minWeight && Object.keys(props.minWeight) || []}
                                    {...props}
                                />)
                        }, props.additionalCostsAreEnabled && {
                            key: detailsTabsTextes.additional_costs,
                            name: i18n.t(Textes.additionalCosts),
                            content: (
                                <DetailAdditionalCostItemForm
                                    readonly={!props.isEditable || props.isRegisteredInSAP || props.isLimitedUpdate}
                                    items={props.additionalCostsIds || []}
                                    {...props}
                                />)
                        }, {
                            key: detailsTabsTextes.history,
                            name: i18n.t(Textes.history),
                            content: (<HistoryBarContainer hideUsers outerClassName="history-conteiner" />)
                        }
                    ].filter(element => Boolean(element));
                };

                return [
                <Navs contents={getDetailsTabs()}
                    activeKey={props.activeTab}
                    onSelect={props.onTabLoad}
                />,
                <div className="d-flex p-3 justify-content-end detail-footer">
                    <div
                        className={[
                            "mr-3",
                            "p-0",
                            "protectable",
                            !props.orderDetails || props.prevMonthLock ? "protectable--protected" : ""
                        ].join(' ')}
                    >
                        <button
                            className="w-100 btn btn-danger text-center"
                            onClick={e => {
                                e.preventDefault();
                                props.handleDelete();
                            }}>
                            {i18n.t(Textes.delete)}
                        </button>
                    </div>
                    <div
                        className={[
                            "mr-3",
                            "p-0",
                            "protectable",
                            !props.orderDetails || props.prevMonthLock || !props.isDeliveryCreated || props.isInvoiceCreated ? "protectable--protected" : ""
                        ].join(' ')}
                    >
                        <button
                            className="w-100 btn btn-danger text-center"
                            onClick={e => {
                                e.preventDefault();
                                props.handleDeleteDelivery();
                            }}>
                            {i18n.t(Textes.delete_delivery)}
                        </button>
                    </div>
                    <div
                        className={[
                            "mr-auto",
                            "p-0",
                            "protectable",
                            !props.orderDetails || props.prevMonthLock || !props.isInvoiceCreated ? "protectable--protected" : ""
                        ].join(' ')}
                    >
                        <button
                            className="w-100 btn btn-danger text-center"
                            onClick={e => {
                                e.preventDefault();
                                props.handleDeleteInvoice();
                            }}>
                            {i18n.t(Textes.delete_invoice)}
                        </button>
                    </div>
                    <div
                        className={[
                            "d-flex mr-3",
                            "protectable",
                            !props.orderDetails ? "protectable--protected" : ""
                        ].join(' ')}
                    >
                        {props.isEditable ? [
                            <button key="save" className="btn btn-secondary text-center mr-3" type="submit">
                                {i18n.t(Textes.save)}
                            </button>,
                            <button key="discard" className="btn btn-secondary text-center" onClick={e => {
                                e.preventDefault();
                                props.discardChanges();
                            }}>
                                {i18n.t(Textes.discard)}
                            </button>
                        ] : (
                                <button className="btn btn-secondary text-center" onClick={e => {
                                    e.preventDefault();
                                    props.handleEditable();
                                }}>
                                    {i18n.t(Textes.edit)}
                                </button>
                            )}
                    </div>
                    <button className="btn btn-secondary text-center" onClick={e => {
                        e.preventDefault();
                        props.handeClosePopup();
                    }}>
                        {props.isEditable ? i18n.t(Textes.closeWS) : i18n.t(Textes.close)}
                    </button>
                </div>
                ]
            }}
        </i18nContext.Consumer>
        );
    },
    FORM_POSITIONS_AND_ADDITIONAL_COSTS
);


export default DetailsTabs;
