import * as React from 'react';
import { FormBuilder, HandledFormProps, FormElements } from '../../../utils/form/form';
import { i18nContext, I18n } from '../../../../language';
import { FORM_ORDER_DATA } from '../../../../data/constants';
import { purchaseFields, saleFields } from './fields';
import { RootState, RootAction } from '../../../../store/types';
import { getOptions } from '../../../../selectors/data';
import { connect } from 'react-redux';
import { getShowAdditionalSalesFields, getAdditionalSaleCustomerSelectFormValues, getOrderRelevantForSap, getOrderInvoiceCreated, getIsLimitedUpdate, getOrderPrevMonthLock, getOrderRegisteredInSAP } from '../../../../selectors/order';
import { orderFieldNames } from '../../../../reducers/form';
import { componentWillAppendToBody } from 'react-append-to-body';
import { default as Popup } from '../search-popup';
import { getInactiveOrderFormFields } from '../../../../helpers/helper';

interface OrderDataFormProps extends HandledFormProps {
    isPurchase?: boolean;
    readonly?: boolean;
    showAdditionalField?: boolean;
    selectedCustomer?: {
        [index: string]: string;
    };
    options?: { [index: string]: Array<{ value: string; text: string; }> }
    showDetails?: boolean;
    isRelevantOrderForSap?: boolean;
    isInvoiceCreated?: boolean;
    isLimitedUpdate?: boolean;
    prevMonthLock?: boolean;
    isRegisteredInSap?: boolean;

    clearSelectedCustomer?: (field: string) => void;
    searchPopupToggle?: () => void;
    searchCustomers?: () => void;
    applySelect?: (field: string, value: string, preview: string) => void;
}
const formBuilder = new FormBuilder();

const AppendedPopup = componentWillAppendToBody(Popup);

const SearchPopupField = (i18n: I18n, props: OrderDataFormProps, field: string) => (
    <div className="d-flex flex-row">
        {FormElements.input({
            name: field,
            type: 'hidden',
            classInputWrapper: 'hidden',
            className: 'widest-input',
            disabled: true,
        })}
        {FormElements.input({
            name: `customer_preview-${field}`,
            type: 'text',
            className: 'widest-input w-100',
            disabled: true,
        })}
        {
            !!props.selectedCustomer &&
            <div
                className={[
                    "col-3 pr-0",
                    "protectable",
                    props.readonly ? "protectable--protected" : ""
                ].join(' ')}
            >
                <button
                    key={`search-popup-button`}
                    onClick={e => {
                        e.preventDefault();
                        props.clearSelectedCustomer(field);
                    }}
                    className="btn-small btn btn-secondary w-100"
                >
                    {i18n.t("Clear")}
                </button>
            </div>
        }
        <div
            className={[
                "col-3 pr-0",
                "protectable",
                props.readonly ? "protectable--protected" : ""
            ].join(' ')}
        >
            <button
                key={`search-popup-button`}
                onClick={e => {
                    e.preventDefault();
                    props.searchPopupToggle();
                }}
                className="btn-small btn btn-secondary w-100"
            >
                {i18n.t("Search")}
            </button>
        </div>
    </div>
);

const OrderDataForm = FormBuilder.form<OrderDataFormProps>(
    (props: OrderDataFormProps) => {
        const inactiveFields = getInactiveOrderFormFields(
            props.readonly,
            props.isInvoiceCreated,
            props.isRelevantOrderForSap,
            props.isLimitedUpdate,
            props.prevMonthLock,
            props.isRegisteredInSap
        );

        return (
            <i18nContext.Consumer>
                {i18n => {
                    if (props.isPurchase) {
                        formBuilder.setElements(purchaseFields(
                            i18n,
                            props.showAdditionalField,
                            props.options,
                            inactiveFields
                        ));
                    } else {
                        const field = props.showAdditionalField ? orderFieldNames.delivery_partner_sap_id : orderFieldNames.weight_account_partner_sap_id;
                        formBuilder.setElements(saleFields(
                            i18n,
                            props.showAdditionalField,
                            props.showDetails ? SearchPopupField(i18n, props, field) : null,
                            props.options,
                            inactiveFields
                        ));
                    }
                    return (
                        <div className="p-3 d-flex flex-wrap order-form">
                            {formBuilder.build()}
                        </div>
                    )
                }}
            </i18nContext.Consumer>
        )
    },
    FORM_ORDER_DATA
);

class OrderData extends React.Component<OrderDataFormProps, {}> {
    state = {
        showSearchPopup: false,
    };
    constructor(props: OrderDataFormProps) {
        super(props);
        this.onSearchPopupToggle = this.onSearchPopupToggle.bind(this);
        this.clearSelectedCustomer = this.clearSelectedCustomer.bind(this);
    }

    onSearchPopupToggle() {
        this.setState({
            showSearchPopup: !this.state.showSearchPopup,
        });
    }
    clearSelectedCustomer(field: string) {
        this.props.applySelect(field, '', '');
    }
    render() {
        return [
            <OrderDataForm {...this.props} searchPopupToggle={this.onSearchPopupToggle} clearSelectedCustomer={this.clearSelectedCustomer} />,
            <AppendedPopup
                key="search-popup"
                showPopup={this.state.showSearchPopup}
                closePopup={this.onSearchPopupToggle}
                showAdditionalField={this.props.showAdditionalField}
            />
        ]
    }
}

const orderFormCotnrolls = FormBuilder.formActions(FORM_ORDER_DATA);
const mapStateToProps = (
    state: RootState,
    ownProps: OrderDataFormProps
) => {
    return {
        options: getOptions(state),
        showAdditionalField: getShowAdditionalSalesFields(state),
        selectedCustomer: getAdditionalSaleCustomerSelectFormValues(state),
        isRelevantOrderForSap: getOrderRelevantForSap(state),
        isInvoiceCreated: getOrderInvoiceCreated(state),
        isLimitedUpdate: getIsLimitedUpdate(state),
        prevMonthLock: getOrderPrevMonthLock(state),
        isRegisteredInSap: getOrderRegisteredInSAP(state),
    }
};
const mapDispatchToProps = (
    dispatch: (action: RootAction) => void,
    ownProps: OrderDataFormProps
) => {
    return {
        applySelect(field: string, value: string, preview: string) {
            dispatch(orderFormCotnrolls.setValue(orderFieldNames[field], value));
            dispatch(orderFormCotnrolls.setValue(`customer_preview-${field}`, preview));
        },
    };
};

export const OrderDataFormContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(OrderData);

export default OrderDataFormContainer;