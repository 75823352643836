import * as React from 'react';
import { Data } from '../../reducers/data';
import { i18nContext } from '../../language';

interface SimpleTableProps {
    items: Data;
    headers: string[];
    className?: string;
    formatNumbers?: boolean;
}

export const SimpleTable = (props: SimpleTableProps) => (
    <i18nContext.Consumer>
        {i18n =>
            (<table className={["simple-table w-100", props.className].join(' ')}>
                <thead className="simple-table-header">
                    <tr>
                        {props.headers && props.headers.map((value, i) =>
                            <th className="simple-table-cell" key={i}>{i18n.t(value)}</th>
                        )}
                    </tr>
                </thead>
                <tbody className="simple-table-body">
                    {props.items && (Object.keys(props.items).length > 0 && Object.keys(props.items).sort((a, b) =>
                        String(props.items[a]['material']).localeCompare(String(props.items[b]['material']))).map((data, i) =>
                            <tr className="simple-table-row" key={i}>
                                {Object.keys(props.items[data]).map((key, j) =>
                                    <td className={["simple-table-cell", `cell-${key}`].join(' ')} key={j}>
                                        {props.formatNumbers && key === 'price' ? i18n.formatPrice(Number(props.items[data][key])) :
                                            (key === 'weight' ? i18n.formatNumber(Number(props.items[data][key])) : props.items[data][key])}
                                    </td>
                                )}
                            </tr>
                        ) || (
                            <tr>
                                <td className="p-1 text-center" colSpan={props.headers.length}>{i18n.t("Sorry, no matching records found")}</td>
                            </tr>
                        ))}
                </tbody>
            </table>)
        }
    </i18nContext.Consumer>
)