import * as React from 'react';

const image = require('../../assets/images/uhm_logo.png');

export class Header extends React.PureComponent {
    render() {
        return (
            <div className="col-6">
                <img src={image} />
            </div>)
    }
}