import * as session from '../actions/session';
import { ActionType } from 'typesafe-actions';
import { USER_SUBMIT_LOGIN_FORM, ITERATE_HEARTBEAT, RESET_HEARTBEAT, SESSION_SET_AUTHORIZATION_TOKEN } from '../data/constants';
import {
  USER_LOGED_IN_SUCCESS,
  USER_LOGED_IN_ERROR,
  USER_LOGED_OUT
} from '../data/constants';
import { UserPermissions } from '../models/user-permissions';

export type SessionAction = ActionType<typeof session>;

export type SessionState = {
  user: string;
  role: string;
  permissions: UserPermissions;
  token: string;
  errorMessage: string;
  heartbeat: number;
  authorizationToken: string;
};

export const defaultSessionState: SessionState = {
  user: null,
  role: null,
  permissions: {},
  token: null,
  errorMessage: null,
  heartbeat: 0,
  authorizationToken: '',
};

export function buildDefaultState(data: {
  user?: any;
  role?: string;
  permissions?: UserPermissions;
  token?: string;
  authorizationToken?: string;
}): SessionState {
  return {
    ...defaultSessionState,
    ...data,
  };
}

export const SessionMainReducer = (
  state: SessionState = defaultSessionState,
  action: SessionAction
) => {
  switch (action.type) {
    case USER_LOGED_IN_SUCCESS:
      return { ...state, ...action.payload, errorMessage: null }
    case USER_LOGED_IN_ERROR:
      return { ...state, errorMessage: action.payload.message }
    case USER_LOGED_OUT:
      return { ...state, user: null, role: null, permissions: {}, token: null, authorizationToken: '' };
    case USER_SUBMIT_LOGIN_FORM:
      return { ...state, errorMessage: null }
    case ITERATE_HEARTBEAT:
      return { ...state, heartbeat: state.heartbeat + 1 };
    case RESET_HEARTBEAT:
      return { ...state, heartbeat: 0 };
    case SESSION_SET_AUTHORIZATION_TOKEN:
      return { ...state, authorizationToken: action.payload.authorizationToken }
    default:
      return state;
  }
};

export const SessionReducer = {
  reducer: SessionMainReducer,
  defaultSate: defaultSessionState,
  stateBuilder: buildDefaultState,
};

export default SessionReducer;