import * as React from 'react';
import { Navs } from '../utils/navs';
import { i18nContext } from '../../language';
import { default as PurchaseBar } from './tabs/purchase';
import { default as SalesBar } from './tabs/sales';
import { default as TradingBar } from './tabs/trading';
import { default as HistoryBar } from './tabs/history';
import { RootAction, RootState } from '../../store/types';
import { connect } from 'react-redux';
import { changeTab } from '../../actions/app';
import { loadUserHistory, resetHistory } from '../../actions/data';
import SystemActivities from './tabs/system-activities';

const Textes = {
    customer: "Customer",
    purchase: "Purchase",
    sale: "Sale",
    compensation: "Compensation",
    invoiceable_orders: "Invoiceable orders",
    sys_act: "System Activities",
    history: "History",
};

interface TabsBarProps {
    selected?: string;
}

class TabsBar extends React.PureComponent<TabsBarProps, {}> {
    render() {
        return (
            <i18nContext.Consumer>
                {i18n =>
                    <div className="tabs-content-wraper">
                        <Navs contents={[{
                            key: 'purchase',
                            name: i18n.t(Textes.purchase),
                            content: (<PurchaseBar
                                title={Textes.purchase}
                            />)
                        }, {
                            key: 'sale',
                            name: i18n.t(Textes.sale),
                            content: (<SalesBar
                                title={Textes.sale}
                            />)
                        }, {
                            key: 'compensation',
                            name: i18n.t(Textes.compensation),
                            content: (<TradingBar />)
                        }, {
                            key: 'history',
                            name: i18n.t(Textes.history),
                            content: (<HistoryBar
                            // title={Textes.history} 
                            />)
                        }, {
                            key: 'sys_act',
                            name: i18n.t(Textes.sys_act),
                            content: (<SystemActivities />)
                        }]}
                            activeKey={this.props.selected ? this.props.selected : 'purchase'}
                            defaultActiveKey='purchase'
                            mountOnEnter={true}
                            unmountOnExit={true}
                        />
                    </div>
                }
            </i18nContext.Consumer>
        )
    }
}

const mapStateToProps = (state: RootState, ownProps: TabsBarProps) => {
    return {
        selected: state.app.selectedTab,
    }
  };
export const TabsBarContainer = connect(
    mapStateToProps,
    null
)(TabsBar);
export default TabsBarContainer;