export const KEY_CODE_ESC = '27';
export const KEY_CODE_ENTER = '13';
export const KEY_CODE_A = '65';
export const KEY_CODE_B = '66';
export const KEY_CODE_D = '68';
export const KEY_CODE_V = '86';
export const KEY_CODE_I = '73';

interface InputHandlerElement {
    id: string;
    callback: (event: KeyboardEvent) => boolean;
}

export class InputHandlerService {
  inputStack: { [keyCode: string]: Array<InputHandlerElement> } = {};
  inputSubscrivers: { [keyCode: string]: Array<InputHandlerElement> } = {};
  constructor() {
    this.handleInput = this.handleInput.bind(this);
    this.handleStack = this.handleStack.bind(this);
    this.handleSubscribers = this.handleSubscribers.bind(this);
    document.addEventListener("keydown", this.handleInput, false);
  }
  handleInput(event: KeyboardEvent) {
    this.handleStack(event);
    this.handleSubscribers(event);
  }
  handleStack(event: KeyboardEvent){
    Object.keys(this.inputStack).map(keyCode => {
      if (String(event.keyCode) === keyCode && this.inputStack[keyCode]) {     
        let success: boolean;
        do {
          success = true;
          const length = this.inputStack[keyCode].length;
          if(length > 0){
            const handler = this.inputStack[keyCode].pop();
            success = handler.callback(event);
          } 
        } while (!success);
      }
    });
  }
  handleSubscribers(event: KeyboardEvent){
    Object.keys(this.inputSubscrivers).map(keyCode => {
      if (String(event.keyCode) === keyCode && this.inputSubscrivers[keyCode] && this.inputSubscrivers[keyCode].length > 0) {     
        const length = this.inputSubscrivers[keyCode].length;
        this.inputSubscrivers[keyCode][length - 1].callback(event);
      }
    });
  }

  addHandler(handlers: { [keyCode: string]: Array<InputHandlerElement> }, keyCode: string, id: string, callback: (event: KeyboardEvent) => boolean) {
    if (!handlers[keyCode]) {
      handlers[keyCode] = [];
    } else {
      handlers[keyCode] = handlers[keyCode].filter(item => {
        return item.id !== id
      });
    }
    handlers[keyCode].push({id, callback});
    return handlers;
  }
  removeHandler(handlers: { [keyCode: string]: Array<InputHandlerElement> }, keyCode: string, id: string) {
    if (handlers[keyCode]) {
      handlers[keyCode] = handlers[keyCode].filter(item => {
        return item.id !== id
      });
    }
    return handlers;
  }

  hookHandler(keyCode: string, id: string, callback: (event: KeyboardEvent) => boolean) {
    this.inputStack = this.addHandler(this.inputStack, keyCode, id, callback);
  }
  subscribeHandler(keyCode: string, id: string, callback: (event: KeyboardEvent) => boolean) {
    this.inputSubscrivers = this.addHandler(this.inputSubscrivers, keyCode, id, callback);
  }

  unhookHandler(keyCode: string, id: string) {
    this.inputStack = this.removeHandler(this.inputStack, keyCode, id);
  }
  unsubscribeHandler(keyCode: string, id: string) {
    this.inputSubscrivers = this.removeHandler(this.inputSubscrivers, keyCode, id);
  }

}

export const InputHandler = new InputHandlerService();