import * as React from 'react';
import { TabTable, TabTableProps } from '../tables/tabtable';
import { customSort, customRowRenderWithSelect } from '../tables/tabtable-components';


export interface SelectebleOrderTabProps extends TabTableProps {
    materialColors?: { [index: string]: string };
    contextMenuId?: string;
    isPurchase?: boolean;
    selectedMatch?: string[];
    isMain?: boolean;
    showId?: boolean;

    onRowSelect?: (id: string) => void;
}

export interface SelectebleOrderTabState {
    sortFieldName: string | null;
    sortDirection: string | null;
}

export class SelectebleOrderTab extends React.PureComponent<SelectebleOrderTabProps, SelectebleOrderTabState> {
    constructor(props: any) {
        super(props);
        this.state = {
            sortFieldName: null,
            sortDirection: null,
        };
    }

    render() {
        return <TabTable
            {...this.props}
            options={{
                search: false,
                rowsPerPage: 50,
                rowsPerPageOptions: [50, 100, 300],
                pagination: this.props.items && Object.keys(this.props.items).length > 50 ? true : false,
                selectableRows: true,
                customSort: customSort,
                rowsSelected: this.props.selectedMatch,
                onColumnSortChange: (changedColumn: string, direction: string) => {
                    this.setState({
                        sortFieldName: changedColumn,
                        sortDirection: direction
                    });
                },
                customRowRender: customRowRenderWithSelect(
                    this.props.materialColors,
                    this.props.onRowSelect,
                    (index: string) =>
                        this.props.selectedMatch && this.props.selectedMatch.includes(index),
                    () => !!this.props.isMain && this.props.selectedMatch && this.props.selectedMatch.length > 0,
                    this.props.contextMenuId,
                    this.props.showId
                ),
                sortOrder: {
                    name: this.state.sortFieldName || 'id',
                    direction: this.state.sortDirection || 'desc',
                },
            }}
        />
    }
}
