import * as React from 'react';
import { connect } from 'react-redux';
import { RootState, RootAction } from '../../../store/types';
import { PopupContainer } from './popup-container';
import { i18nContext, I18n } from '../../../language';
import DetailsOrderForm from './details/details-form';
import { Data } from '../../../reducers/data';
import {
	getOrderDetailsData,
	getPriceUnits,
	getSelectedOrderType,
	getRequestStatus,
	getOptions,
	getOrderEdited,
	getAdditionalCostsFeatureAvailability,
	getIsLimitedUpdate,
} from '../../../selectors';
import { resetOrderDetails, createRequestStatusReset, resetHistory, setOrderEditing } from '../../../actions/data';
import DetailsTabs from './details/details-tabs';
import { Loader } from '../../utils/loader';
import ModalPopup from './modal-popup';
import { getShowAdditionalSalesFields, getShowSalesShippingAddressFields, getOrderPrevMonthLock, getOrderPositionItemsWeightUnits, getOrderDeliveryCreated, getOrderRegisteredInSAP, getOrderInvoiceCreated } from '../../../selectors/order';
import { InputHandler, KEY_CODE_ENTER } from '../../../services/input';

const Textes = {
	title: "Order Details",
	title_purchase: "Purchase Order Details",
	title_sale: "Sale Order Details",
	cancel: "Cancel",
	delete: "Delete Order",
	removeText: "Do you really want to delete this order?",
	deleteDelivery: "Delete Delivery Note",
	removeDeliveryText: "Do you really want to delete this delivery note?",
	deleteInvoice: "Delete Invoice",
	removeInvoiceText: "Do you really want to delete this invoice?",
}

interface DetailsOrderPopupProps {
	showPopup?: boolean;
	orderDetails?: { [index: string]: Data };
	requestStatus?: string;
	priceUnits?: { [index: string]: string };
	isPurchase?: boolean;
	options?: { [index: string]: Array<{ value: string, text: string, priceUnits?: string }> };
	isOrderEdited?: boolean;
	isRegisteredInSAP?: boolean;
	isInvoiceCreated?: boolean;
	isDeliveryCreated?: boolean;
	showAdditionalField?: boolean;
	showSalesShippingAddressFields?: boolean;
	prevMonthLock?: boolean;
	additionalCostsAreEnabled?: boolean;
	isLimitedUpdate?: boolean;
	weightUnits?: {
        [positionIndex: string]: string[];
    };

	changeOrderEditing?: (orderIsEdited: boolean) => void;
	clearStatus?: () => void;
	closePopup?: () => void;
	resetData?: () => void;
	resetHistory?: () => void;
	loadHistory?: (ids: string[], orderId?: string) => void;
	deleteOrder?: (id: string) => void;
	deleteDelivery?: (id: string) => void;
	deleteInvoice?: (id: string) => void;
	getDetails?: (id: string) => void;
	saveOrderChanges?: (id: string) => void;
	lockOrderPositions?: (ids: string[]) => void;
	unlockOrderPositions?: (ids: string[]) => void;
}

const detailsTabs = {
	positions: "positions",
	additional_costs: "additional_costs",
	history: "history",
}
const POPUP_ID = 'details-popup';

class DetailsOrderPopup extends React.Component<DetailsOrderPopupProps, {}> {
	state = {
		showRemoveConfirm: false,
		showDeliveryRemoveConfirm: false,
		showInvoiceRemoveConfirm: false,
		requestSent: false,
		activeTab: detailsTabs.positions,
	}
	constructor(props: DetailsOrderPopupProps) {
		super(props);
		this.handeClosePopup = this.handeClosePopup.bind(this);
		this.deleteOrder = this.deleteOrder.bind(this);
		this.deleteDelivery = this.deleteDelivery.bind(this);
		this.deleteInvoice = this.deleteInvoice.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
        this.handleDeleteDelivery = this.handleDeleteDelivery.bind(this);
        this.handleDeleteInvoice = this.handleDeleteInvoice.bind(this);		
		this.discardChanges = this.discardChanges.bind(this);
		this.handleEditable = this.handleEditable.bind(this);
		this.saveChanges = this.saveChanges.bind(this);
		this.onTabLoad = this.onTabLoad.bind(this);
	}

	handeClosePopup() {
		if (this.props.isOrderEdited) {
			this.handleEditable();
		}
		this.props.resetData();
		this.props.clearStatus();
		this.props.closePopup();
		this.props.resetHistory();
		this.setState({ activeTab: detailsTabs.positions });
	}
	handleEditable() {
		const ids = Object.keys(this.props.orderDetails.positions).map(key =>
			String(this.props.orderDetails.positions[key].id)
		);
		if (this.props.isOrderEdited) {
			this.props.unlockOrderPositions(ids);
			this.props.changeOrderEditing(false);
		} else {
			this.props.lockOrderPositions(ids);
			this.setState({ requestSent: true });
		}
	}

	saveChanges() {
		this.setState({ requestSent: true });
		const id = String(this.props.orderDetails.order_details.id);
		this.props.saveOrderChanges(id);
	}
	discardChanges() {
		const ids = Object.keys(this.props.orderDetails.positions).map(key =>
			String(this.props.orderDetails.positions[key].id)
		);
		this.props.clearStatus();
		this.props.unlockOrderPositions(ids);
		this.props.changeOrderEditing(false);
		this.setState({ historyShowed: false });
		this.props.resetData();
		const id = [...ids].pop();
		this.props.getDetails(id);
	}

	handleDelete() {
		this.setState({
			showRemoveConfirm: !this.state.showRemoveConfirm
		});
	}
	handleDeleteDelivery() {
		this.setState({
			showDeliveryRemoveConfirm: !this.state.showDeliveryRemoveConfirm
		});
	}
	handleDeleteInvoice() {
		this.setState({
			showInvoiceRemoveConfirm: !this.state.showInvoiceRemoveConfirm
		});
	}
	deleteOrder() {
		this.handleDelete();
		this.props.deleteOrder(String(this.props.orderDetails.order_details.id));
		this.handeClosePopup();
	}
	deleteDelivery() {
		this.handleDeleteDelivery();
		this.props.deleteDelivery(String(this.props.orderDetails.order_details.id));
		this.handeClosePopup();
	}
	deleteInvoice() {
		this.handleDeleteInvoice();
		this.props.deleteInvoice(String(this.props.orderDetails.order_details.id));
		this.handeClosePopup();
	}
	onTabLoad(eventKey: string, e: React.SyntheticEvent<{}>) {
		switch (eventKey) {
			case detailsTabs.history:
				this.props.resetHistory();
				const id = String(this.props.orderDetails.order_details.id);
				const position_ids = Object.keys(this.props.orderDetails.positions).map(key =>
					String(this.props.orderDetails.positions[key].id)
				);
				this.props.loadHistory(position_ids, id);
			default:
				this.setState({ activeTab: eventKey });
		}
	}
	componentWillUnmount() {
		InputHandler.unsubscribeHandler(KEY_CODE_ENTER, POPUP_ID);
	}

	componentWillReceiveProps(newProps: DetailsOrderPopupProps) {
		if (newProps.showPopup && newProps.showPopup !== this.props.showPopup) {
			InputHandler.subscribeHandler(KEY_CODE_ENTER, POPUP_ID, (event: KeyboardEvent) => {
				if (this.props.showPopup && this.props.isOrderEdited) {
					this.saveChanges();
					event.preventDefault();
					return true;
				}
				return false;
			});
		}
	}
	componentDidUpdate() {
		if (this.state.requestSent && this.props.requestStatus) {
			this.setState({ requestSent: false });
			if (this.props.requestStatus === "OK") {
				this.props.clearStatus();
				this.props.changeOrderEditing(false);
			}
			if (this.props.requestStatus === "ERROR") {
				this.props.clearStatus();
			}
			if (this.props.requestStatus === "EDITABLE") {
				this.props.changeOrderEditing(true);
				this.props.clearStatus();
			}
		}
	}
	render() {
		const minWeight: { [index: string]: number } = {};
		const additionalCostIds: string[] = [];
		this.props.orderDetails &&
			Object.keys(this.props.orderDetails.positions).map(key =>
				minWeight[String(this.props.orderDetails.positions[key].id)] = Number(this.props.orderDetails.positions[key].weight) - Number(this.props.orderDetails.positions[key].unmatched_weight))

		this.props.orderDetails && this.props.orderDetails.additional_costs &&
			Object.keys(this.props.orderDetails.additional_costs).map(key =>
				additionalCostIds.push(String(this.props.orderDetails.additional_costs[key].id))
		);

		return (
			<PopupContainer id={POPUP_ID}
				showPopup={this.props.showPopup}
				closePopup={this.handeClosePopup}
				inLoad={this.state.requestSent}
			>
				{this.props.showPopup &&
					<i18nContext.Consumer>
						{(i18n: I18n) =>
							<div className={[
								"card bg-light w-100 d-flex flex-column",
								"protectable",
								this.state.requestSent ? "protectable--protected" : ""
							].join(' ')}>
								<div className="card-header">
									<h2 className="pl-3 mt-2" >{i18n.t(this.props.isPurchase ? Textes.title_purchase : Textes.title_sale)}</h2>
								</div>
								{this.props.orderDetails &&
									<DetailsOrderForm
										data={{ ...this.props.orderDetails.order_details }}
										editable={this.props.isOrderEdited}
										isPurchase={this.props.isPurchase}
										showSalesShippingAddressFields={this.props.showSalesShippingAddressFields}
										showDetails
									/>
									|| (<Loader className="d-flex w-100 mt-5" />)}
								<DetailsTabs
									minWeight={minWeight}
									additionalCostsIds={additionalCostIds}
									isEditable={this.props.isOrderEdited}
									isRegisteredInSAP={this.props.isRegisteredInSAP}
									isInvoiceCreated={this.props.isInvoiceCreated}
									isDeliveryCreated={this.props.isDeliveryCreated}
									activeTab={this.state.activeTab}
									orderDetails={this.props.orderDetails}
									onTabLoad={this.onTabLoad}
									handleEditable={this.handleEditable}
									discardChanges={this.discardChanges}
									handleDelete={this.handleDelete}
									handleDeleteDelivery={this.handleDeleteDelivery}
									handleDeleteInvoice={this.handleDeleteInvoice}
									handeClosePopup={this.handeClosePopup}
									onSubmit={this.saveChanges}
									isPurchase={this.props.isPurchase}
									priceUnits={this.props.priceUnits}
									options={this.props.options}
									showAdditionalField={this.props.showAdditionalField}
									prevMonthLock={this.props.prevMonthLock}
									additionalCostsAreEnabled={this.props.additionalCostsAreEnabled}
									isLimitedUpdate={this.props.isLimitedUpdate}
									weightUnits={this.props.weightUnits}
								/>
								<ModalPopup
									showModal={this.state.showRemoveConfirm}
									submitRemove={this.deleteOrder}
									closePopup={this.handleDelete}
									textes={{
										title: i18n.t(Textes.delete),
										confirm: i18n.t(Textes.delete),
										cancel: i18n.t(Textes.cancel),
										text: i18n.t(Textes.removeText),
									}}
								/>
								<ModalPopup
									showModal={this.state.showDeliveryRemoveConfirm}
									submitRemove={this.deleteDelivery}
									closePopup={this.handleDeleteDelivery}
									textes={{
										title: i18n.t(Textes.deleteDelivery),
										confirm: i18n.t(Textes.deleteDelivery),
										cancel: i18n.t(Textes.cancel),
										text: i18n.t(Textes.removeDeliveryText),
									}}
								/>
								<ModalPopup
									showModal={this.state.showInvoiceRemoveConfirm}
									submitRemove={this.deleteInvoice}
									closePopup={this.handleDeleteInvoice}
									textes={{
										title: i18n.t(Textes.deleteInvoice),
										confirm: i18n.t(Textes.deleteInvoice),
										cancel: i18n.t(Textes.cancel),
										text: i18n.t(Textes.removeInvoiceText),
									}}
								/>
							</div>
						}
					</i18nContext.Consumer>}
			</PopupContainer>
		)
	}
}

const mapStateToProps = (
	state: RootState,
	ownProps: DetailsOrderPopupProps
) => {
	return {
		isOrderEdited: getOrderEdited(state),
		isRegisteredInSAP: getOrderRegisteredInSAP(state),
		isInvoiceCreated: getOrderInvoiceCreated(state),
		isDeliveryCreated: getOrderDeliveryCreated(state),
		orderDetails: getOrderDetailsData(state),
		requestStatus: getRequestStatus(state),
		priceUnits: getPriceUnits(state),
		options: getOptions(state),
		showAdditionalField: getShowAdditionalSalesFields(state),
		showSalesShippingAddressFields: getShowSalesShippingAddressFields(state),
		prevMonthLock: getOrderPrevMonthLock(state),
		additionalCostsAreEnabled: getAdditionalCostsFeatureAvailability(state),
		isLimitedUpdate: getIsLimitedUpdate(state),
		weightUnits: getOrderPositionItemsWeightUnits(state),
	}
};
const mapDispatchToProps = (
	dispatch: (action: RootAction) => void,
	ownProps: DetailsOrderPopupProps
) => {
	return {
		resetData() {
			dispatch(resetOrderDetails());
		},
		clearStatus() {
			dispatch(createRequestStatusReset());
		},
		resetHistory() {
			dispatch(resetHistory());
		},
		changeOrderEditing(orderIsEdited: boolean) {
			dispatch(setOrderEditing(orderIsEdited));
		}
	};
};

export const DetailsOrderPopupContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(DetailsOrderPopup);

export default DetailsOrderPopupContainer;