import * as React from 'react';
import { Navs } from '../utils/navs';
import { i18nContext } from '../../language';
import { RootState, RootAction } from '../../store/types';
import { connect } from 'react-redux';
import { changeTab } from '../../actions/app';
import { loadUserHistory, resetHistory } from '../../actions/data';
import { getUserPermissions } from '../../selectors';
import { UserPermissions } from '../../models/user-permissions';

const Textes = {
    customer: "Customer",
    purchase: "Purchase",
    sale: "Sale",
    compensation: "Compensation",
    invoiceable_orders: "Invoiceable orders",
    sys_act: "System Activities",
    history: "History",
};

interface TabsBarProps {
    userPermissions?: UserPermissions;
    resetSelects?: (eventKey: string, e: React.SyntheticEvent<{}>) => void;
}

class TabsBar extends React.PureComponent<TabsBarProps, {}> {
    render() {
        return (
            <i18nContext.Consumer>
                {i18n => {
                    const navElements: Array<{
                        name: string;
                        key: string;
                        content: JSX.Element;
                    }> = [{
                        key: 'purchase',
                        name: i18n.t(Textes.purchase),
                        content: null
                    }, {
                        key: 'sale',
                        name: i18n.t(Textes.sale),
                        content: null
                    }, this.props.userPermissions.register_match && {
                        key: 'compensation',
                        name: i18n.t(Textes.compensation),
                        content: null
                    }, this.props.userPermissions.search_history && {
                        key: 'history',
                        name: i18n.t(Textes.history),
                        content: null
                    }, this.props.userPermissions.system_activities && {
                        key: 'sys_act',
                        name: i18n.t(Textes.sys_act),
                        content: null
                    }].filter(element => Boolean(element));

                    return <div className="mt-2">
                        <Navs contents={navElements}
                            onSelect={this.props.resetSelects}
                            defaultActiveKey='purchase'
                        />
                    </div>;
                }}
            </i18nContext.Consumer>
        )
    }
}

const mapStateToProps = (
    state: RootState,
    ownProps: TabsBarProps
) => {
    return {
        userPermissions: getUserPermissions(state),
    }
};

const mapDispatchToProps = (
    dispatch: (action: RootAction) => void,
    ownProps: TabsBarProps
) => {
    return {
        resetSelects(eventKey: string, e: React.SyntheticEvent<{}>) {
            dispatch(changeTab(eventKey));
            switch (eventKey) {
                case 'history':
                    dispatch(resetHistory());
                    dispatch(loadUserHistory());
            }
        },
    };
};

export const TabsBarContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(TabsBar);
export default TabsBarContainer;