import * as React from 'react';
import { default as CreateOrderTableContainer } from './create-order-table-container/create-order-table-container';
import { clearOrderData, createRequestStatusReset } from '../../../actions/data';
import { connect } from 'react-redux';
import { RootState, RootAction } from '../../../store/types';
import {
    getRequestStatus,
    getSearchedCustomers
} from '../../../selectors';
import { PopupContainer } from './popup-container';
import { clearSearch, inOrderSearchSubmit, setActiveSearch } from '../../../actions/search';
import { Data } from '../../../reducers/data';
import OrderDataForm from './order/order-data';
import ShippingAddressForm from './addresses/shipping-address-form';
import CustomerSearchForm from './search/search-form';
import CustomerSaleSearchForm from './search/search-form-additional';
import CustomerSelectForm from './search/customer-select';
import {
    getSelectedCustomerSelectFormValues,
    getShowAdditionalSalesFields,
    getActiveSearchType,
    getAdditionalSaleCustomerSelectFormValues,
    getAdditionalSaleCustomersToSelect,
    getShowSalesShippingAddressFields
} from '../../../selectors/order';
import { orderFieldNames } from '../../../reducers/form';
import { defaultCustomerSearch } from '../../../data/constants';
import { i18nContext } from '../../../language';

const SEARCH_TYPE = "IN_ORDER";

const Textes = {
    customerSaleSearchPlaceholder: "Select the partner or use the search...",
};

interface CreateOrderPopupProps {
    title?: string;
    requestStatus?: string;
    showPopup?: boolean;
    isPurchase?: boolean;
    searchedCustomers?: Data;
    showAdditionalField?: boolean;
    protected?: boolean;
    activeCustomerSearch?: string;
    selectedCustomer?: string;
    saleCustomersToSelect?: (placeholder: string) => [{ value: string, text: string }];
    selectedSaleCustomer?: string;
    showSalesShippingAddressFields?: boolean;

    clearData?: () => void;
    clearStatus?: () => void;
    closePopup?: () => void;
    submitRequest?: () => void;
    searchCustomers?: () => void;
    setActiveSearch?: (type: string) => void;
}

class CreateOrderPopup extends React.Component<CreateOrderPopupProps, {}> {
    searchField: HTMLInputElement;
    state = {
        requestSent: false,
        isFocused: false
    };

    constructor(props: CreateOrderPopupProps) {
        super(props);
        this.focusSearchField = this.focusSearchField.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handeClosePopup = this.handeClosePopup.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.setSearchRef = this.setSearchRef.bind(this);
        this.handleCustomerRepresentativeReceiving = this.handleCustomerRepresentativeReceiving.bind(this);
        this.handleCustomerSearch = this.handleCustomerSearch.bind(this);
        this.handleCustomerSaleSearch = this.handleCustomerSaleSearch.bind(this);
    }

    handleSubmit() {
        if (this.props && this.props.submitRequest) {
            this.setState({ requestSent: true });
            this.props.submitRequest();
        }
    }
    handleSearch() {
        this.props.clearStatus();
        this.setState({ requestSent: true });
        this.props.searchCustomers();
    }
    handleCustomerRepresentativeReceiving() {
        this.props.clearStatus();
        this.setState({ requestSent: true });
    }
    handleCustomerSearch() {
        this.props.setActiveSearch(defaultCustomerSearch);
        this.handleSearch();
    }
    handleCustomerSaleSearch() {
        const field = this.props.showAdditionalField ? orderFieldNames.delivery_partner_sap_id : orderFieldNames.weight_account_partner_sap_id;
        this.props.setActiveSearch(field);
        this.handleSearch();
    }
    handeClosePopup() {
        this.props.closePopup();
        this.props.clearData();
        this.props.clearStatus();
    }
    componentWillReceiveProps(newProps: CreateOrderPopupProps) {
        if (newProps.showPopup && newProps.showPopup !== this.props.showPopup) {
            this.props.clearData();
        }
    }
    componentDidUpdate() {
        if (this.state.requestSent && this.props.requestStatus) {
            if (this.props.requestStatus === "OK") {
                this.handeClosePopup();
            }
            if (this.props.requestStatus === "ERROR" || this.props.requestStatus === "SEARCHED") {
                this.props.clearStatus();
            }
            this.setState({ requestSent: false });
        }
        if (this.searchField && this.props.showPopup && !this.state.isFocused) {
            this.setState({ isFocused: true });
            setTimeout(
                this.focusSearchField,
                300
            )
        }
        if (!this.props.showPopup && this.state.isFocused) {
            this.setState({ isFocused: false });
        }

    }

    focusSearchField() {
        if (this.searchField) {
            (this.searchField as any).getRenderedComponent().focus();
        }
    }
    setSearchRef(ref: HTMLInputElement) {
        this.searchField = ref;
    }

    render() {
        return (
            <i18nContext.Consumer>
                {i18n =>
                    <PopupContainer
                        id="order-popup"
                        showPopup={this.props.showPopup}
                        closePopup={this.handeClosePopup}
                        inLoad={this.state.requestSent}
                    >
                        {this.props.showPopup &&
                            <div className={[
                                "card bg-light w-100 d-flex flex-column",
                                "protectable",
                                this.state.requestSent ? "protectable--protected" : ""
                            ].join(' ')}>
                                <div className="card-header">
                                    <h2 className="pl-3 mt-2" >{this.props.title}</h2>
                                </div>
                                <div className="d-flex flex-column">
                                    <div className="d-flex flex-row p-3">
                                        <div className="col-4">
                                            <CustomerSearchForm
                                                onSubmit={this.handleCustomerSearch}
                                                className="d-flex flex-column"
                                                selectedCustomer={this.props.selectedCustomer}
                                                setRefToFocus={this.setSearchRef}
                                            />
                                            {this.props.isPurchase ? null : (
                                                <CustomerSaleSearchForm
                                                    onSubmit={this.handleCustomerSaleSearch}
                                                    showAdditionalField={this.props.showAdditionalField}
                                                    className="d-flex flex-column"
                                                    readonly={this.props.protected}
                                                    selectedCustomer={this.props.selectedSaleCustomer}
                                                    customersToSelect={this.props.saleCustomersToSelect(i18n.t(Textes.customerSaleSearchPlaceholder))}
                                                />
                                            )}
                                        </div>
                                        <CustomerSelectForm onSelectAnotherCustomer={this.handleCustomerRepresentativeReceiving} searchedCustomers={this.props.searchedCustomers} activeSearch={this.props.activeCustomerSearch} className="col-8" />
                                    </div>
                                    <OrderDataForm
                                        readonly={this.props.protected}
                                        isPurchase={this.props.isPurchase}
                                    />
                                    {this.props.showSalesShippingAddressFields && <ShippingAddressForm
                                        readonly={this.props.protected}
                                    />}
                                </div>
                                <div className="border-line" />
                                <CreateOrderTableContainer
                                    className="p-3"
                                    protected={this.props.protected}
                                    isPurchase={this.props.isPurchase}
                                    onCansel={this.handeClosePopup}
                                    onSubmit={this.handleSubmit}
                                />
                            </div>
                        }
                    </PopupContainer>
                }
            </i18nContext.Consumer>
        )
    }
}

const mapStateToProps = (
    state: RootState,
    ownProps: CreateOrderPopupProps
) => {
    return {
        requestStatus: getRequestStatus(state),
        protected: !getSelectedCustomerSelectFormValues(state),
        searchedCustomers: getSearchedCustomers(state),
        showAdditionalField: getShowAdditionalSalesFields(state),
        activeCustomerSearch: getActiveSearchType(state),
        selectedCustomer: getSelectedCustomerSelectFormValues(state),
        selectedSaleCustomer: getAdditionalSaleCustomerSelectFormValues(state),
        saleCustomersToSelect: getAdditionalSaleCustomersToSelect(state),
        showSalesShippingAddressFields: getShowSalesShippingAddressFields(state),
    }
};
const mapDispatchToProps = (
    dispatch: (action: RootAction) => void,
    ownProps: CreateOrderPopupProps
) => {
    return {
        clearStatus() {
            dispatch(createRequestStatusReset());
        },
        clearData() {
            dispatch(clearOrderData());
            dispatch(clearSearch(SEARCH_TYPE));
        },
        searchCustomers() {
            dispatch(inOrderSearchSubmit());
        },
        setActiveSearch(type: string) {
            dispatch(setActiveSearch(type));
        }
    };
};

export const CreateOrderPopupContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateOrderPopup);

export default CreateOrderPopupContainer;