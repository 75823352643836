import * as React from 'react';
import { i18nContext } from '../../../language';
import MaterialTab from './system-activities/materials';
import { Navs } from '../../utils/navs';
import SettingsTab from './system-activities/settings';
import LogsTab from './system-activities/logs';
import ReportsTab from './system-activities/reports';
import { RootAction } from '../../../store/types';
import { loadLogs, resetLogs, loadReports } from '../../../actions/data';
import { connect } from 'react-redux';

const Textes = {
    materials: 'Materials',
    settings: 'Settings',
    reports: 'Reports',
    logs: 'Logs',
};

const TabKeys = {
    materials: 'materials',
    settings: 'settings',
    reports: 'reports',
    logs: 'logs',
}

interface SystemActivitiesProps {
    tabChange?: (eventKey: string, e: React.SyntheticEvent<{}>) => void;
}

class SystemActivities extends React.PureComponent<SystemActivitiesProps, {}> {
    render() {
        return (
            <i18nContext.Consumer>
                {i18n =>
                    <div className="mt-2 system-tabs">
                        <Navs contents={[{
                            key: TabKeys.materials,
                            name: i18n.t(Textes.materials),
                            content: (<MaterialTab />)
                        }, {
                            key: TabKeys.reports,
                            name: i18n.t(Textes.reports),
                            content: (<ReportsTab />)
                        }, {
                            key: TabKeys.settings,
                            name: i18n.t(Textes.settings),
                            content: (<SettingsTab
                                title={Textes.settings}
                            />)
                        }, {
                            key: TabKeys.logs,
                            name: i18n.t(Textes.logs),
                            content: (<LogsTab />)
                        }]}
                            onSelect={this.props.tabChange}
                        />
                    </div>
                }
            </i18nContext.Consumer>
        )
    }
}

const mapDispatchToProps = (
    dispatch: (action: RootAction) => void,
    ownProps: SystemActivitiesProps
) => {
    return {
        tabChange(eventKey: string, e: React.SyntheticEvent<{}>) {
            dispatch(resetLogs());
            switch (eventKey) {
                case TabKeys.logs:
                    dispatch(loadLogs());
                    break;
                case TabKeys.reports:
                    dispatch(loadReports());
            }
        },
    };
};
export const SystemActivitiesContainer = connect(
    null,
    mapDispatchToProps
)(SystemActivities);

export default SystemActivitiesContainer;