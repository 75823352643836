import { connect } from 'react-redux';
import { RootState, RootAction } from '../../../store/types';
import { getPurchaseData, getMaterialsColor, getPurchaseSearchData, getSearchedValue, getOrderTabHiddenColumns } from '../../../selectors';
import { clearSearch, startSerach } from '../../../actions/search';
import { PURCHASE_POSITION_CONTEXT_MENU_ID } from '../../../data/constants';
import { setOrderDetailsTrigger } from '../../../actions/data';
import { OrderTabProps, OrderTab } from './order';
import { getDataIsLoaded } from '../../../selectors/data';

const SEARCH_TYPE = "PURCHASE";

const mapStateToProps = (
    state: RootState,
    ownProps: OrderTabProps
) => {
    const isLoaded = getDataIsLoaded(state);
    return {
        isLoaded: isLoaded && isLoaded.purchase,
        items: getPurchaseData(state),
        searchItems: getPurchaseSearchData(state),
        searchValue: getSearchedValue(state, SEARCH_TYPE),
        materialColors: getMaterialsColor(state),
        hiddenColumns: getOrderTabHiddenColumns(state),
        contextMenuId: PURCHASE_POSITION_CONTEXT_MENU_ID,
    }
};

const mapDispatchToProps = (
    dispatch: (action: RootAction) => void,
    ownProps: OrderTabProps
) => {
    return {
        onSearch(search: string) {
            dispatch(startSerach(search, SEARCH_TYPE));
        },
        onSearchClose() {
            dispatch(clearSearch(SEARCH_TYPE));
        },
        onPositionOrderDetailsTrigger(id: string) {
            dispatch(setOrderDetailsTrigger(id, SEARCH_TYPE));
        },
    };
};

export const PurchaseTable = connect(
    mapStateToProps,
    mapDispatchToProps
)(OrderTab);

export default PurchaseTable;