import * as React from 'react';
import { LoginForm } from './loginform';
import { connect } from 'react-redux';
import { RootAction, RootState } from '../../store/types';
import { getErrorMessage } from '../../selectors';
import { submitLoginForm } from '../../actions/session';
import { i18nContext } from '../../language';
import { Loader } from '../utils/loader';
import { createRequestStatusReset } from '../../actions/data';
import { getRequestStatus } from '../../selectors/data';

const Textes = {
  login: "Login",
};

interface LoginProps {
  errorMessage?: string;
  requestStatus?: string;
  onLoginFormSubmit?: () => void;
  clearStatus?: () => void;
}

class Login extends React.Component<LoginProps, {}> {
  state = {
    requestSent: false,
  };

  constructor(props: LoginProps) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleSubmit() {
    this.setState({ requestSent: true });
    this.props.onLoginFormSubmit();
  }
  componentDidUpdate() {
    if (this.state.requestSent && this.props.requestStatus) {
      this.props.clearStatus();
      this.setState({ requestSent: false });
    }
  }
  render() {
    return (
      <div className="col-6 col-xl-4 py-3">
        <div className={[
          "card bg-light",
          "protectable",
          this.state.requestSent ? "protectable--protected" : ""
        ].join(' ')}
        >
          {this.props.errorMessage &&
            (<span className="mx-3 mt-3 mb-0 alert alert-danger">{this.props.errorMessage}</span>)}
          <LoginForm
            className="py-3"
            onSubmit={this.handleSubmit}
          />
          {this.state.requestSent &&
            <Loader className="popup-loader" />
          }
        </div>
      </div>)
  }
}

const mapStateToProps = (
  state: RootState,
  ownProps: LoginProps
) => {
  return {
    errorMessage: getErrorMessage(state),
    requestStatus: getRequestStatus(state),
  }
};
const mapDispatchToProps = (
  dispatch: (action: RootAction) => void,
  ownProps: LoginProps
) => {
  return {
    onLoginFormSubmit: () => {
      dispatch(submitLoginForm());
    },
    clearStatus() {
      dispatch(createRequestStatusReset());
    },
  };
};

export const LoginContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);

export default LoginContainer;