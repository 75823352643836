import * as React from 'react';
import { FormBuilder, FormElements } from '../../../utils/form/form';
import { i18nContext, I18n } from '../../../../language';
import { orderFieldNames } from '../../../../reducers/form';
import { Textes as FormTextes, salesOrderTypeOptions } from '../order/data';
import { FORM_ORDER_DATA } from '../../../../data/constants';
import CustomerSearchForm, { SearchFormProps } from './search-form';

const fields = (i18n: I18n, readonly?: boolean) => [
    {
        label: i18n.t(FormTextes.order_type),
        input: FormElements.select({
            name: orderFieldNames.order_type,
            className: 'widest-input',
            options: salesOrderTypeOptions(i18n),
            disabled: readonly,
        }),
        className: 'pb-2 pr-0',
        wrapClass: 'd-flex flex-row justify-content-between',
        classNameLeft: 'd-flex align-items-center col-4 p-0',
        classNameRight: 'col-8 pr-0',
    },
];

interface CustomerSaleSearchFormProps extends SearchFormProps {
    showAdditionalField?: boolean;
    customersToSelect?: [{ value: string, text: string }];
}

const formBuilder = new FormBuilder();
const OrderTypeForm = FormBuilder.form<SearchFormProps>(
    (props: SearchFormProps) => {
        return (
            <i18nContext.Consumer>
                {i18n => {
                    formBuilder.setElements(fields(i18n, props.readonly));
                    return formBuilder.build();
                }}
            </i18nContext.Consumer>
        )
    },
    FORM_ORDER_DATA
);

const CustomerSaleSearchForm = (props: CustomerSaleSearchFormProps) => {
    const field = props.showAdditionalField ? orderFieldNames.delivery_partner_sap_id : orderFieldNames.weight_account_partner_sap_id;
    return (
    <div className="pt-3">
        <OrderTypeForm {...props} />
        <CustomerSearchForm {...props} className="pt-3" title={FormTextes[field]} field={field} />
    </div>
)}

export default CustomerSaleSearchForm;