import * as React from 'react';
import { TableSortLabel } from '@material-ui/core';
import { TableHeadCell, TableSelectCell } from 'mui-datatables';
import { i18nContext, I18n } from '../../../language';
import { purchaseAndSalesMap, customHeader as customHeaders, numericValues, priceValues } from './tableformat';
import { ContextMenuTrigger } from "react-contextmenu"
import { valuesToDisplay, valuesToDisplayInv, valuesToDisplayComp, valuesToDisplayBilling, Statuses, valuesToDisplayHistory, valuesToDisplayMaterials, valuesToDisplayLogs, valuesToDisplayReports } from '../../../data/constants';
import { customSortByFields } from '../../../helpers/helper';
import { HistoryTemplate } from './history-template';

export const textLabels = (i18n: I18n) => ({
    body: {
        noMatch: i18n.t("Sorry, no matching records found"),
        toolTip: i18n.t("Sort"),
    },
    pagination: {
        next: i18n.t("Next Page"),
        previous: i18n.t("Previous Page"),
        rowsPerPage: i18n.t("Rows per page:"),
        displayRows: i18n.t("of"),
    },
    toolbar: {
        search: i18n.t("Search"),
        downloadCsv: i18n.t("Download CSV"),
        print: i18n.t("Print"),
        viewColumns: i18n.t("View Columns"),
        filterTable: i18n.t("Filter Table"),
    },
    filter: {
        all: i18n.t("All"),
        title: i18n.t("FILTERS"),
        reset: i18n.t("RESET"),
    },
    viewColumns: {
        title: i18n.t("Show Columns"),
        titleAria: i18n.t("Show/Hide Table Columns"),
    },
    selectedRows: {
        text: i18n.t("row(s) selected"),
        delete: i18n.t("Delete"),
        deleteAria: i18n.t("Delete Selected Rows"),
    },
});

export const defaultOptions = {
    rowsPerPageOptions: [10, 50, 100, 300],
    selectableRows: 'none',
    filter: false,
    print: false,
    download: false,
    viewColumns: false,
    rowHover: false,
    customSearch: () => true,
}

export const customHeadRender = (
    columnMeta: any,
    handleToggleColumn: (params: any) => any,
    sortOrder: { name: string, direction: "desc" | "asc" }
) => {
    const isActive = columnMeta.sort && sortOrder.name === columnMeta.name;
    return (
        <i18nContext.Consumer>
            {(i18n: I18n) =>
                <th
                    onClick={columnMeta.sort && (() => handleToggleColumn(columnMeta.index))}
                    className={[
                        columnMeta.sort ? 'sortable' : '',
                        isActive ? 'active' : '',
                    ].join(' ')}
                >
                    <div className='d-flex justify-content-center'>
                        {i18n.t(columnMeta.label)}
                        <TableSortLabel
                            active={isActive}
                            direction={sortOrder.direction || "asc"}
                            hideSortIcon={true}
                        />
                    </div>
                </th>
            }
        </i18nContext.Consumer>
    )

}

export const customSort = (data: Array<{ data: Array<{ [index: string]: string | number | boolean }> }>, colIndex: number, order: string) => {
    return data.sort((a, b) => {
        return a.data[colIndex] && b.data[colIndex] && customSortByFields(
            Object.values(a.data[colIndex]).pop(),
            Object.values(b.data[colIndex]).pop(),
            Object.keys(a.data[colIndex]).pop()
        ) * (order === 'desc' ? -1 : 1);
    });
}

const TableSelectCellx = (TableSelectCell as any);

export const customRowRender = (
    colors?: { [index: string]: string },
    contextMenuId?: string,
    orderDetailsTrigger?: (id: string) => void,
    hiddenColumns?: string[],
) => (
    data: Array<{ [index: string]: string | number | boolean }>,
    dataIndex: number,
    rowIndex: number
) => {
        const parsedData: { [index: string]: string | number | boolean } = {};
        data.map(d => d && Object.keys(d).map(key => parsedData[key] = d[key]));
        const color = colors && colors[String(parsedData.material_code)];
        // const styles = color && { backgroundColor: `#${color}` } || {};
        const styles = color && (!parsedData.canceled ?
            { backgroundColor: `#${color}` } : {
                background: `repeating-linear-gradient(
                                    45deg,
                                    #${color},
                                    #${color} 10px,
                                    #adb5bd 10px,
                                    #adb5bd 20px
                                )`
            }) || {};
        const props = {
            style: styles,
            className: String(parsedData.status) === Statuses.new ? 'status-new' : 'react-contextmenu-wrapper',
            onDoubleClick: () => orderDetailsTrigger(String(parsedData.id)),
        };
        return (
            <i18nContext.Consumer>
                {(i18n: I18n) =>
                    <ContextMenuTrigger
                        renderTag='tr'
                        id={contextMenuId || "context-menu"}
                        key={rowIndex}
                        collect={() => ({
                            positionId: String(parsedData.id),
                            locked: !!parsedData.locked,
                            lockedPreviousMonth: !!parsedData.prev_month_lock,
                        })}
                        attributes={props}
                    >
                        {Object.keys(parsedData).map(key => {
                            if (key === "status" || key === "canceled" || key === "prev_month_lock" || hiddenColumns.includes(key)) {
                                return null;
                            }

                            if (Object.keys(purchaseAndSalesMap).includes(key)) {
                                return purchaseAndSalesMap[key](parsedData[key], i18n.t(key));
                            }

                            if (numericValues.includes(key)) {
                                return (<td key={key} className={key}>{i18n.formatNumber(Number(parsedData[key]))}</td>);
                            }

                            if (priceValues.includes(key)) {
                                return (<td key={key} className={key}>{i18n.formatPrice(Number(parsedData[key]))}</td>);
                            }

                            return (<td key={key} className={key}>{parsedData[key]}</td>);
                        })}
                    </ContextMenuTrigger>
                }
            </i18nContext.Consumer>
        );
    }

export const customRowRenderHistory = () => (
    data: Array<{ [index: string]: string | number | boolean }>,
    dataIndex: number,
    rowIndex: number
) => {
    const parsedData: { [index: string]: string | number | boolean } = {};
    data.map(d => d && Object.keys(d).map(key => parsedData[key] = d[key]));
    return (
        <i18nContext.Consumer>
            {(i18n: I18n) => HistoryTemplate(parsedData, i18n)}
        </i18nContext.Consumer>
    );
}

export const customRowRenderWithButtons = (onClick?: (key: string, id: string) => void, buttonFields?: { [index: string]: string }) => (
    data: Array<{ [index: string]: string | number | boolean }>,
    dataIndex: number,
    rowIndex: number
) => {
    const parsedData: { [index: string]: string | number | boolean } = {};
    data.map(d => d && Object.keys(d).map(key => parsedData[key] = d[key]));
    return (
        <i18nContext.Consumer>
            {(i18n: I18n) => (
                <tr key={rowIndex}>
                    {Object.keys(parsedData).map(key => {
                        if (key === "id") {
                            return null;
                        }
                        if (buttonFields && Object.keys(buttonFields).includes(key)) {
                            return (
                                <td key={key} className="py-1 text-center">
                                    <button className="btn btn-secondary text-center" onClick={e => onClick(key, String(parsedData.id))}>
                                        {i18n.t(buttonFields[key])}
                                    </button>
                                </td>
                            );
                        }
                        return (<td key={key} className={key}>{parsedData[key]}</td>);
                    })}
                </tr>
            )}
        </i18nContext.Consumer>
    );
}

export const customRowRenderMaterials = (contextMenuId?: string) => (
    data: Array<{ [index: string]: string | number | boolean }>,
    dataIndex: number,
    rowIndex: number
) => {
    const parsedData: { [index: string]: string | number | boolean } = {};
    data.map(d => d && Object.keys(d).map(key => parsedData[key] = d[key]));
    const styles = { backgroundColor: `#${parsedData.color}` };
    const props = {
        style: styles,
        className: String(parsedData.status) === Statuses.new ? 'status-new' : 'react-contextmenu-wrapper',
    };
    return (
        <i18nContext.Consumer>
            {(i18n: I18n) =>
                <ContextMenuTrigger
                    renderTag='tr'
                    id={contextMenuId || "context-menu"}
                    key={rowIndex}
                    collect={() => ({ material_id: String(parsedData.id) })}
                    attributes={props}
                >
                    {Object.keys(parsedData).map(key => {
                        if (key === 'id' || key === 'status') {
                            return null;
                        }
                        if (key === "color") {
                            return (<td key={key}>{`#${parsedData[key]}`}</td>);
                        }
                        return (<td key={key} className={key}>{parsedData[key]}</td>);
                    })}
                </ContextMenuTrigger>
            }
        </i18nContext.Consumer>
    );
}

export const customRowRenderWithSelect = (
    colors: { [index: string]: string },
    onSelect?: (id: string) => void,
    isRowSelected?: (id: string) => boolean,
    notSelectable?: () => boolean,
    contextMenuId?: string,
    showId?: boolean,
) => (
    data: Array<{ [index: string]: string | number | boolean }>,
    dataIndex: number,
    rowIndex: number
) => {
        const parsedData: { [index: string]: string | number | boolean } = {};
        data.map(d => d && Object.keys(d).map(key => parsedData[key] = d[key]));
        const color = colors && colors[String(parsedData.material_code)];
        const checked = !!isRowSelected(String(parsedData.id));
        const isRowSelectable = !notSelectable() || checked;
        const styles = color && ((!parsedData.locked && !notSelectable()) || checked ?
            { backgroundColor: `#${color}` } : {
                background: `repeating-linear-gradient(
                                    45deg,
                                    #${color},
                                    #${color} 10px,
                                    #adb5bd 10px,
                                    #adb5bd 20px
                                )`
            }) || {};
        const props = {
            style: styles,
            onClick: isRowSelectable ? () => onSelect(String(parsedData.id)) : null,
            className: [
                isRowSelectable ? "pointer" : "",
                String(parsedData.status) === Statuses.new ? 'status-new' : 'react-contextmenu-wrapper'].join(' '),
        };
        return (
            <i18nContext.Consumer>
                {(i18n: I18n) =>
                    <ContextMenuTrigger
                        renderTag='tr'
                        id={contextMenuId || "context-menu"}
                        key={rowIndex}
                        collect={() => ({ positionId: String(parsedData.id) })}
                        attributes={props}
                    >
                        <TableSelectCellx
                            checked={checked}
                            selectableOn
                            isRowSelectable={isRowSelectable}
                            id={'MUIDataTableSelectCell-' + dataIndex}
                        />

                        {Object.keys(parsedData).map(key => {
                            if (key === "locked" || (key === "id" && !showId) || key === "status") {
                                return null;
                            }
                            if (Object.keys(purchaseAndSalesMap).includes(key)) {
                                return purchaseAndSalesMap[key](parsedData[key], i18n.t(key));
                            }
                            if (priceValues.includes(key)) {
                                return (<td key={key} className={key}>{i18n.formatPrice(Number(parsedData[key]))}</td>);
                            }
                            if (numericValues.includes(key)) {
                                return (<td key={key} className={key}>{i18n.formatNumber(Number(parsedData[key]))}</td>);
                            }
                            return (<td key={key} className={key}>{parsedData[key]}</td>);
                        })}
                    </ContextMenuTrigger>
                }
            </i18nContext.Consumer>
        );
    }

export const getTableHeader = (headers: string[], hide: string[], sortBy: string, notSorted?: string[], customHeader?: { [index: string]: string }) =>
    headers.map(h => {
        const options: { [index: string]: any } = {
            filter: false,
            customHeadRender: customHeadRender,
        }
        if (hide.includes(h)) {
            options.display = false;
        }
        if (notSorted && notSorted.includes(h)) {
            options.sort = false;
        }
        return {
            label: customHeader && Object.keys(customHeader).includes(h) ? customHeader[h] : h,
            name: h,
            options: options,
        };
    });

export const getHistoryTableHeaders = () => getTableHeader(valuesToDisplayHistory, ['id', 'affected_field', 'affected_object', 'new_value', 'old_value', 'object_id', 'matched_to'], 'id')
export const getCompTableHeaders = () => getTableHeader(valuesToDisplayComp, ['locked', 'status'], 'position_price')
export const getInvTableHeaders = () => getTableHeader(valuesToDisplayInv, ['status'], 'order_date')
export const getBillingTableHeaders = () => getTableHeader(valuesToDisplayBilling, ['locked', 'status'], 'order_date')
export const getMaterialTableHeaders = () => getTableHeader(valuesToDisplayMaterials, ['id', 'status'], 'material_code');
export const getLogsTableHeaders = () => getTableHeader(valuesToDisplayLogs, ['id'], 'created_at', ['request', 'response']);
export const getReportsTableHeaders = () => getTableHeader(valuesToDisplayReports, ['id'], 'created_at', ['request', 'download']);
export const getOrdersTableHeaders = (options: {
    hiddenColumns: string[],
}) => getTableHeader(valuesToDisplay, ['status', 'canceled', 'prev_month_lock'].concat(options.hiddenColumns), 'id', [], customHeaders);
