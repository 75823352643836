import * as React from 'react';
import { Data } from '../../../reducers/data';
import MUIDataTable from 'mui-datatables';
import { i18nContext, I18n } from '../../../language';
import {
    customHeadRender,
    textLabels,
    defaultOptions
} from './tabtable-components';
import { Loader } from '../../utils/loader';

export interface TabTableProps {
    items?: Data;
    searchValue?: string;
    searchItems?: Data;
    title?: string;
    className?: string;
    options?: { [index: string]: any };
    defaultSort?: string;
    customeHeaders?: Array<{
        label: string;
        name: string;
        options: {
            [index: string]: any;
        };
    }>;
    isLoaded?: boolean;
}

export class TabTable extends React.PureComponent<TabTableProps, {}> {
    render() {
        if ((!this.props.items || Object.keys(this.props.items).length === 0) &&
            (!this.props.searchItems || Object.keys(this.props.searchItems).length === 0) && !this.props.isLoaded) {
            return (<Loader className="table-loader" />)
        }
        
        const headers = this.props.customeHeaders || this.props.items &&
            (this.props.items[Object.keys(this.props.items).pop()]) &&
            Object.keys(this.props.items[Object.keys(this.props.items).pop()]).map(h => {
                const options: { [index: string]: any } = {
                    filter: false,
                    customHeadRender: customHeadRender,
                }
                if (h === "status") {
                    options.display = false;
                }
                return {
                    label:h,
                    name: h,
                    options: options
                }
            });

        return (
            <div className={["tab-table", this.props.className].join(' ')}>
                <i18nContext.Consumer>
                    {(i18n: I18n) => {
                        const options: { [index: string]: any } = {
                            ...defaultOptions,
                            textLabels: textLabels(i18n),
                            ...this.props.options
                        }
                        return (<MUIDataTable
                            title={i18n.t(this.props.title)}
                            options={options}
                            columns={headers}
                            data={!this.props.searchValue ? this.props.items && Object.values(this.props.items || []) : Object.values(this.props.searchItems || this.props.items)}
                        />)
                    }}
                </i18nContext.Consumer>
            </div>)
    }
}