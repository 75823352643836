import { action } from 'typesafe-actions';
import { Data } from '../reducers/data';
import { 
    SET_ACTIVE_SEARCH,
    SEARCH_SET,
    SEARCH_CLEAR,
    SEARCH_START,
    SEARCH_IN_ORDER_SUBMIT,
} from '../data/constants';

export const startSerach = (search: string, type: string) =>
    action(SEARCH_START, { search, type });

export const clearSearch = (type: string) => action(SEARCH_CLEAR, { type });

export const setSerachedCustomers = (data: Data) =>
    action(SEARCH_SET, { data, type: 'searchedCustomers' });

export const setSerachedSale = (data: Data) =>
    action(SEARCH_SET, { data, type: 'searchedSales'});

export const setSerachedPurchase = (data: Data) =>
    action(SEARCH_SET, { data, type: 'searchedPurchases' });

export const setSerachedInOrder = (data: Data) =>
    action(SEARCH_SET, { data, type: 'searchedInOrder' });

export const inOrderSearchSubmit = () => action(SEARCH_IN_ORDER_SUBMIT);

export const setActiveSearch = (type: string) =>
    action(SET_ACTIVE_SEARCH, { type });