import * as React from 'react';
import { Modal } from 'reactstrap';
import { i18nContext } from '../../../language';
import { Data } from '../../../reducers/data';
import CustomerSelectForm from './search/customer-select';
import { connect } from 'react-redux';
import { RootAction, RootState } from '../../../store/types';
import { clearSearch, inOrderSearchSubmit, setActiveSearch } from '../../../actions/search';
import { orderFieldNames } from '../../../reducers/form';
import { getRequestStatus, getSearchedCustomers } from '../../../selectors/data';
import { createRequestStatusReset } from '../../../actions/data';
import { Loader } from '../../utils/loader';
import { getAdditionalSaleCustomerSelectFormValues } from '../../../selectors/order';
import CustomerSearchForm from './search/search-form';
import { Textes as FormTextes } from './order/data';
import { InputHandler, KEY_CODE_ESC, KEY_CODE_ENTER } from '../../../services/input';

const SEARCH_TYPE = "IN_ORDER";
const POPUP_ID = 'search-popup';

const Textes = {
    close: "Close",
    confirm: "Confirm",
};

interface SearchPopupProps {
    showPopup?: boolean;
    searchedCustomers?: Data;
    isSelected?: boolean;
    selectedCustomer?: string;
    showAdditionalField?: boolean;
    requestStatus?: string;

    clearStatus?: () => void;
    closePopup?: () => void;
    clearData?: () => void;
    searchCustomers?: () => void;
    applySelect?: (field: string, value: string, preview: string) => void;
    setActiveSearch?: (type: string) => void;
}

class SearchPopup extends React.Component<SearchPopupProps, {}> {
    state = {
        requestSent: false,
    };

    constructor(props: SearchPopupProps) {
        super(props);
        this.closePopup = this.closePopup.bind(this);
        this.escFunction = this.escFunction.bind(this);
        this.handleCustomerSearch = this.handleCustomerSearch.bind(this);
    }

    escFunction() {
        if (this.props.showPopup) {
            this.props.closePopup();
            return true;
        }
        return false;
    }
    handleCustomerSearch(field: string) {
        this.props.setActiveSearch(field);
        this.props.clearStatus();
        this.setState({ requestSent: true });
        this.props.searchCustomers();
    }
    componentWillReceiveProps (newProps: SearchPopupProps) {
        if(newProps.showPopup && newProps.showPopup !== this.props.showPopup) {
          InputHandler.hookHandler(KEY_CODE_ESC, POPUP_ID, () => this.escFunction());
          InputHandler.subscribeHandler(KEY_CODE_ENTER, POPUP_ID, (event: KeyboardEvent) => {
            if (this.props.showPopup) {
              return true;
            }
            return false;
          });
        } else if(!newProps.showPopup && newProps.showPopup !== this.props.showPopup) {
          InputHandler.unsubscribeHandler(KEY_CODE_ENTER, POPUP_ID);
        }
    }
    closePopup() {
        this.props.clearData();
        this.props.closePopup();
        this.props.clearStatus();
    }
    componentDidUpdate() {
        if (this.state.requestSent && this.props.requestStatus) {
            if (this.props.requestStatus === "ERROR" || this.props.requestStatus === "SEARCHED") {
                this.props.clearStatus();
            }
            this.setState({ requestSent: false });
        }
    }

    render() {
      const field = this.props.showAdditionalField ? orderFieldNames.delivery_partner_sap_id : orderFieldNames.weight_account_partner_sap_id;

        return (
            <Modal
                centered
                key="modal"
                size="xl"
                contentClassName={[
                    "protectable",
                    this.state.requestSent ? "protectable--protected" : ""
                ].join(' ')}
                isOpen={this.props.showPopup}
                toggle={this.props.closePopup}>
                {this.props.showPopup &&
                    <i18nContext.Consumer>
                        {i18n =>
                            [
                                <div className="d-flex flex-row p-3">
                                    <CustomerSearchForm
                                        onSubmit={e => this.handleCustomerSearch(field)}
                                        className="col-4 p-3 d-flex flex-column"
                                        field={field}
                                        title={FormTextes[field]}
                                        selectedCustomer={this.props.selectedCustomer}
                                        columnView
                                    />
                                    <CustomerSelectForm searchedCustomers={this.props.searchedCustomers} className="col-8" />
                                </div>,
                                <div className="d-flex p-3 justify-content-end detail-footer">
                                    <button className="btn btn-secondary text-center" onClick={this.closePopup}>
                                        {i18n.t(Textes.close)}
                                    </button>
                                </div>,
                                this.state.requestSent ? (
                                    <Loader className="popup-loader" />
                                ) : null
                            ]
                        }
                    </i18nContext.Consumer>
                }
            </Modal>
        );
    };
}

const mapStateToProps = (
    state: RootState,
    ownProps: SearchPopupProps
) => {
    return {
        requestStatus: getRequestStatus(state),
        searchedCustomers: getSearchedCustomers(state),
        selectedCustomer: getAdditionalSaleCustomerSelectFormValues(state),
    }
};
const mapDispatchToProps = (
    dispatch: (action: RootAction) => void,
    ownProps: SearchPopupProps
) => {
    return {
        searchCustomers() {
            dispatch(inOrderSearchSubmit());
        },
        clearData() {
            dispatch(clearSearch(SEARCH_TYPE));
        },
        clearStatus() {
            dispatch(createRequestStatusReset());
        },
        setActiveSearch(type: string) {
            dispatch(setActiveSearch(type));
        }
    };
};

export const SearchPopupContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchPopup);

export default SearchPopupContainer;