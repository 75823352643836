import * as React from 'react';
import { HandledFormProps, FormBuilder, FormElements } from '../../utils/form/form';
import { FORM_HISTORY_USER } from '../../../data/constants';
import { i18nContext } from '../../../language';

const Textes = {
    select_user: "Select user history",
    refresh: "Refresh History",
}

const formBuilder = new FormBuilder();

interface HistoryUserSelectFormProps extends HandledFormProps {
    options?: Array<{
        value: string;
        text: string;
    }>
}

const HistoryUserSelectForm = FormBuilder.form<HistoryUserSelectFormProps>(
    (props: HistoryUserSelectFormProps) => {

        return (
            <i18nContext.Consumer>
                {i18n => {
                    formBuilder.setElements([{
                        input: FormElements.select({
                            name: 'history_user',
                            className: 'state-select',
                            options: props.options
                        }),
                        className: "col-5 mx-3",
                    }, {
                        input: FormElements.button({
                            value: i18n.t(Textes.refresh),
                            className: 'btn btn-secondary w-100',
                            type: 'submit',
                        }),
                        className: "col-4",
                    },]);
                    return (
                        <div className="col-6 p-3 mx-auto d-flex flex-row  align-items-center">
                            <div className="col-3">{i18n.t(Textes.select_user)}</div>
                            {formBuilder.build()}
                        </div>
                    )
                }}
            </i18nContext.Consumer>
        )
    },
    FORM_HISTORY_USER
);

export default HistoryUserSelectForm;