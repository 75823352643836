import { Data } from '../../reducers/data';

const io = require('socket.io-client')

const SERVER_URL = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port;
const EVENTS = {
    CONNECT: 'connect',
    DISCONNECT: 'disconnect',
    MESSAGE: 'message',
    DATA: 'data'
};

export default class Socket {
    private onDataHandle: (data: { type: string, data: Data }) => void;
    private socket: any;

    constructor(onDataHandle: (data: { type: string, data: Data }) => void) {
        this.onDataHandle = onDataHandle;
        this.socket = '';
    }

    public connect = (login: string) => {
        this.socket = io.connect(SERVER_URL, {
            path: '/notifications/subscribe',
        });

        this.socket.on(EVENTS.DATA, this.onDataHandle);

        this.socket.on(EVENTS.CONNECT, () => {
            this.socket.emit('check_credentials', {login: login});
            // console.log("Credentials checking emitted");
        });
    };

    public disconnect = () => this.socket.close();
}