import * as React from 'react';
import { connect } from 'react-redux';
import { RootState, RootAction } from '../store/types';
import { changeLanguage } from '../actions/app';
import { Languages } from '../data/languages';
import { i18nContext } from '../language';

interface LanguageSwitcherProps {
  onLanguageChange?: (language: string) => void;
}

class LanguageSwitcher extends React.Component<LanguageSwitcherProps, {}> {
  render() {
    return (
      <div className="ml-auto language-switcher d-flex align-items-center mr-5">
        <i18nContext.Consumer>
          {i18n =>
            Object.keys(Languages).map(key =>
              <a href='#'
                key={key}
                className={[
                  "language",
                  key === i18n.language ? "selected" : ""
                ].join(' ')}
                onClick={e => this.props.onLanguageChange(key)}>
                {Languages[key]}
              </a>
            )
          }
        </i18nContext.Consumer>
      </div>
    )
  }
}

const mapStateToProps = (state: RootState, ownProps: LanguageSwitcherProps) => {
  return {
  }
};
const mapDispatchToProps = (
  dispatch: (action: RootAction) => void,
  ownProps: LanguageSwitcherProps
) => {
  return {
    onLanguageChange: (language: string) => {
      dispatch(changeLanguage(language));
    },
  };
};

const LanguageSwitcherContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LanguageSwitcher);

export default LanguageSwitcherContainer;