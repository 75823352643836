import Axios, { AxiosStatic } from 'axios';
import { Links } from '../data/links';
import { Data } from '../reducers/data';
import { ErrorLogger } from './error-handling';
import { DataParams } from './data';

interface TradingParams {
    id?: string;
    user?: string;
    language?: string;
    sale_order_id?: string;
    purchase_order_id?: string;
    sale_positions?: string[] | { [index: string]: number };
    purchase_positions?: string[] | { [index: string]: number };
    invoice_date?: string;
}

export interface TradingResponseParams {
    status: string;
    message?: string;
    data?: Data | { [index: string]: Data };
}

export class TradingServiceClass {
    constructor(public transport: AxiosStatic = Axios) { }

    getTrading(params: TradingParams, endpoint: string): Promise<TradingResponseParams> {
        return new Promise((resolve, reject) => {
            this.transport
                .post(endpoint,
                    JSON.stringify(params), {
                    maxRedirects: 0,
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                .then(res => {
                    const data: TradingResponseParams = res.data;
                    return resolve(data);
                })
                .catch(res => {
                    ErrorLogger.logErrorRequest(endpoint, params as DataParams, res.response.data, res.response.status);
                    return resolve(res);
                });
        });
    }

    lockPurchase(params: TradingParams = {}): Promise<TradingResponseParams> {
        return this.getTrading(params, Links.tradingPurchaseLock);
    }
    unlockPurchase(params: TradingParams = {}): Promise<TradingResponseParams> {
        return this.getTrading(params, Links.tradingPurchaseUnlock);
    }
    cancelPurchase(params: TradingParams = {}): Promise<TradingResponseParams> {
        return this.getTrading(params, Links.tradingPurchaseCancel);
    }

    lockSale(params: TradingParams = {}): Promise<TradingResponseParams> {
        return this.getTrading(params, Links.tradingSaleLock);
    }
    unlockSale(params: TradingParams = {}): Promise<TradingResponseParams> {
        return this.getTrading(params, Links.tradingSaleUnLock);
    }
    cancelSale(params: TradingParams = {}): Promise<TradingResponseParams> {
        return this.getTrading(params, Links.tradingSaleCancel);
    }

    unlockAll(params: TradingParams = {}): Promise<TradingResponseParams> {
        return this.getTrading(params, Links.tradingUnlockAll);
    }
    lockAll(params: TradingParams = {}): Promise<TradingResponseParams> {
        return this.getTrading(params, Links.tradingLockAll);
    }

    tradingMatch(params: TradingParams = {}): Promise<TradingResponseParams> {
        return this.getTrading(params, Links.tradingMatch);
    }
    tradingInventory(params: TradingParams = {}): Promise<TradingResponseParams> {
        return this.getTrading(params, Links.tradingInventory);
    }
    tradingPnL(params: TradingParams = {}): Promise<TradingResponseParams> {
        return this.getTrading(params, Links.tradingPnL);
    }

    sentToSap(params: TradingParams = {}): Promise<TradingResponseParams> {
        return this.getTrading(params, Links.sapCreateOrder);
    }
    sapBilling(params: TradingParams = {}): Promise<TradingResponseParams> {
        return this.getTrading(params, Links.sapBillingOrder);
    }
    sapDelivering(params: TradingParams = {}): Promise<TradingResponseParams> {
        return this.getTrading(params, Links.sapDeliveringOrder);
    }

    getHisory(params: TradingParams): Promise<TradingResponseParams> {
        return this.getTrading(params, Links.history);
    }
}
