import * as React from 'react';
import { ContextMenu, MenuItem } from "react-contextmenu"
import { componentWillAppendToBody } from 'react-append-to-body';
import { I18n, i18nContext } from '../../../../language';
import Popup from '../../popup/material-popup';
import { RootAction } from '../../../../store/types';
import { editMaterial, setMaterialDataToForm, addMaterial } from '../../../../actions/data';
import { connect } from 'react-redux';

const Textes = {
    edit: "Edit",
    add: "Add new",
}

interface MaterialContextMenuProps {
    id: string;
    trigger: { material_id: string };

    editMaterial?: (id: string) => void;
    addMaterial?: () => void;
    setMaterialData?: (id: string) => void;
}

const AppendedPopup = componentWillAppendToBody(Popup);

class MaterialContextMenu extends React.Component<MaterialContextMenuProps, {}> {
    material_id: string;
    state = {
        showPopup: false,
        mode: ""
    }
    constructor(props: MaterialContextMenuProps) {
        super(props);
        this.onDataSet = this.onDataSet.bind(this);
        this.onPopupToggle = this.onPopupToggle.bind(this);
        this.onPopupSubmit = this.onPopupSubmit.bind(this);
    }

    componentDidUpdate() {
        if (this.props.trigger && this.props.trigger.material_id) {
            this.material_id = this.props.trigger.material_id;
        }
    }

    onPopupSubmit() {
        switch (this.state.mode) {
            case "EDIT":
                this.props.editMaterial(this.material_id);
                break;
            case "ADD_NEW":
                this.props.addMaterial();
        }
    }

    onDataSet() {
        if (this.props.setMaterialData && this.material_id) {
            this.props.setMaterialData(this.material_id);
        }
    }

    onPopupToggle(mode?: string) {
        this.setState({
            showPopup: !this.state.showPopup,
            mode
        },
            () => {
                const parentElement = document.body;
                if (!parentElement) {
                    return;
                }

                if (this.state.showPopup) {
                    if (mode === 'EDIT') {
                        this.onDataSet();
                    }
                    parentElement.classList.add('popup-showed');
                } else {
                    parentElement.classList.remove('popup-showed');
                }
            }
        );
    }

    render() {
        return (
            <i18nContext.Consumer>
                {(i18n: I18n) => [
                    <ContextMenu key="menu" id={this.props.id} className="card py-1">
                        {this.props.trigger && [
                            <MenuItem
                                key="edit"
                                attributes={{ className: "pointer px-3 py-1 contextmenu-item" }}
                                onClick={e => this.onPopupToggle("EDIT")}
                            >
                                {i18n.t(Textes.edit)}
                            </MenuItem>,
                            <MenuItem
                                key="add_new"
                                attributes={{ className: "pointer px-3 py-1 contextmenu-item" }}
                                onClick={e => this.onPopupToggle("ADD_NEW")}
                            >
                                {i18n.t(Textes.add)}
                            </MenuItem>
                        ]}
                    </ContextMenu>,
                    <AppendedPopup
                        key="popup"
                        title={this.state.mode === 'EDIT' ? i18n.t(Textes.edit) : i18n.t(Textes.add)}
                        showPopup={this.state.showPopup}
                        submitRequest={this.onPopupSubmit}
                        closePopup={this.onPopupToggle}
                        isEditing={this.state.mode === 'EDIT'}
                        updateData={this.onDataSet}
                    />
                ]}
            </i18nContext.Consumer>
        );
    };
}

const mapDispatchToProps = (
    dispatch: (action: RootAction) => void,
    ownProps: MaterialContextMenuProps
) => {
    return {
        setMaterialData(material_id: string) {
            dispatch(setMaterialDataToForm(material_id));
        },
        addMaterial() {
            dispatch(addMaterial());
        },
        editMaterial(material_id: string) {
            dispatch(editMaterial(material_id));
        },
    };
};
export const MaterialContextMenuContainer = connect(
    null,
    mapDispatchToProps
)(MaterialContextMenu);

export default MaterialContextMenuContainer;